import axios from "axios";
import React, {
  Component,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

import { Modal, Button } from "react-bootstrap";
import "../css/ListComponent.css";
import { MarketContext } from "../Context";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import WorkInProgress from "./WorkInProgress";
import NewFilterCompo from "./NewFilterCompo";
import PrefCompo from "./PrefCompo";
import { ToastContainer, toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { useFilePicker } from "use-file-picker";
import ContextMenu from "./ContextMenu";
import {
  formChangeFunction,
  validationFunction,
  /*   callbtnFunction, */
  updateRecord,
  setcolumn,
} from "../HelperClasses/MultiUpdateService.js";
import FormInnerCompo from "./FormInnerCompo.jsx";
// import Modal from "react-modal";

const ListComponent = ({ dash_tableName, dash_parentApplication, list_id }) => {
  const {
    token,
    loca,
    userDetails,
    contextMenu,
    showAll,
    user,
    setMainError,
    listAccess,
    callType,
    formHeight,
    pushInListOfFilters,
    getFilterByTableName,
    imageloca,
    appname,
    leftNavData
  } = useContext(MarketContext);

  const { openFilePicker, filesContent, loadingg, clear } = useFilePicker({
    accept: [".txt", ".csv", ".json"],
  });
  const navigation = useNavigate();

  const location = useLocation().state;

  // const [all, setAll] = useState(showAll);
  const [searchParam, setSearchParam] = useSearchParams();
  const [record, setRecord] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [name, setName] = useState("");
  const [col_mn, setCol_mn] = useState([]);
  const [list, setlist] = useState([]);
  const [refList, setRefList] = useState([]);
  const [listRcd, setListRcd] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [showupdownbttn, setShowUpDownbtn] = useState(false);
  const [ref_filter, setref_filter] = useState([]);
  const [record_rq, setrecord_rq] = useState();
  const [mainFilt, setMainFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
    dc: { id: "", value: "" },
    ch: [],
  });
  // const [int_fields, setInt_Fields] = useState([]);
  // const [email_fields, setEmail_Fields] = useState([]);
  // const [date_fields, setDate_Fields] = useState([]);
  // const [dtm_fields, setDtm_Fields] = useState([]);
  // const [choice_fields, setChoice_Fields] = useState([]);
  // const [boln_fields, setBoln_Fields] = useState([]);
  // const [str_fields, setStr_Fields] = useState([]);
  const [ref_fields, setRef_Fields] = useState([]);
  // const [booleanfld, setBooleanFld] = useState([]);
  const [srch_column, setSrch_column] = useState("name");
  const [button, setButton] = useState([]);
  const [btnNamee, setbtnNamee] = useState("");
  const [objLabel, setObjLabel] = useState();
  const [url, setUrl] = useState("");
  const [mainrecord, setMainRecord] = useState({});
  const [sortColumn, setSortColumn] = useState({
    name: "",
    sort: true,
    init: false,
  });
  const [prefCall, setPrefCall] = useState([]);
  const [srch, setSrch] = useState("");
  const [filtarray, setFiltArray] = useState([]);

  const [filString, setfilString] = useState("");
  const [filter, setFilter] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [tableLabel, setTableLabel] = useState("");

  let isSub = useRef(false);
  let menuX = useRef(0);
  let menuY = useRef(0);
  let filt1 = useRef();
  let mySelect = useRef();
  let listRef = useRef([]);
  let dataLoadForUpdate = useRef(false);
  // console.log(location.filter);
  let checkMenu = useRef(false);
  const [filt, setFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
  });
  const [overlayH, setOverlayH] = useState(0);
  const [page_error, setPage_Error] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [error, setError] = useState("");
  const [timeline, setTimeline] = useState("");
  const [ClearButtonLoading, setClearButtonLoading] = useState(false);
  const [RunButtonLoading, setRunButtonLoading] = useState(false);
  const [pageClicked, setPageClicked] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageRecords, setPageRecords] = useState("");
  const [recordCount, setRecordCount] = useState(0);
  const [columnList, setColumnList] = useState([]);
  const [showPref, setShowPref] = useState(false);
  const [showFilePicker, setShowFilePicker] = useState(false);
  const [modal, setModal] = useState(false);
  const [showComponentRecord, setShowComponentRecord] = useState(false);
  const [componentId, setComponentId] = useState("");
  const [isClick, setIsClick] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartCell, setDragStartCell] = useState(null);

  const [showContext, setShowContext] = useState(false);
  const [column_depend, setColumn_depend] = useState([]);
  const [tableName, setTableName] = useState("");
  const [tabId, setTabId] = useState("");
  const [tablabel, setTabLabel] = useState("");
  const [showListContext, setShowListContext] = useState(false);
  const [all_ref_cols, setAll_ref_cols] = useState([]);
  // const [changedRowAndCols, setChangedRowAndCols] = useState(new Map());
  const [submitLoading, setSubmitLoading] = useState(false);
  const [subLoadAck, setSubLoadAck] = useState("");
  const [showModelList, setshowModelList] = useState();
  const [objRefTable, setObjRefTable] = useState();
  const [objName, setObjName] = useState();
  const [depCol, setDepCol] = useState();
  const [objIndex, setObjIndex] = useState();
  const [curObj, setCurObj] = useState();
  const [objType, setObjType] = useState();
  const [columnId, setColumnId] = useState();

  const [recordId, setRecordId] = useState(0);
  const [multiSelectCol, setMultiSelectCol] = useState(-1);
  const [multiSelectrow, setMultiSelectRow] = useState(-1);
  const loadtext = useRef(null);
  let listName = useRef("");
  //let callAginfm =useRef(false)
  let notAllowedForUpdate = useRef([
    "filter",
    "filter_ref",
    "other_table_filter",
    "filter_script",
    "json",
    "JSONObject",
    "group_key_value",
    "html",
    "script",
    "multi_select",
    "table_insert",
    "table_lookup",
    "image_upload",
    "label",
    "dynamic_key_value",
    "password",
    "long_description",
    "note",
    "condition",
    "activity",
    "link",
  ]);

  let listOfTypes = useRef([
    "filter",
    "filter_ref",
    "json",
    "group_key_value",
    "html",
    "reference",
    "multi_select",
    "depend_table",
  ]);
  let rowsScriptHasBeenRun = useRef([]);
  let multipleSelectedRows = useRef(new Map());
  let changedRowAndCols = useRef(new Map());

  // let alreadyRun = useRef(true);
  // filter which is already executed
  let executedFilter = useRef([]);

  //................ Functions ............//

  // const initallist = () => {
  //   let temp = [];
  //   temp.push({
  //     co: "",
  //     cl: "",
  //     mc: "",
  //     an: "",
  //     ct: "",
  //     af: "",
  //     rf: { id: "", value: "" },
  //     dc: { id: "", value: "" },
  //   });
  //   console.log("temp  : " + JSON.stringify(temp));
  //   setFiltArray([...temp]);
  //   getListRecord();
  // };

  //   window.onload=()=>{
  //     let reloading = sessionStorage.getItem("reloading");
  //     if(reloading){
  // console.log("hey this is reload");
  //     }
  //   }
  //   const handlePDF = () => {

  //     sessionStorage.setItem("reloading", "true");
  //     window.location.reload(false);
  // };

  const listJsonExport = () => {
    setSubmitLoading(true);
    axios
      .post(loca + "/list/json/export", listRcd.formRecordList, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          jsonDownload(record, listRcd.formRecordList[1].table.name);
        },
        (error) => {
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      );
  };

  const excelImport = () => {
    navigation({
      pathname: user + "/importdata",
      search: createSearchParams({}).toString(),
    });
  };

  const excelExport = () => {
    setSubmitLoading(true);
    axios
      .post(loca + "/marketplace/excel/export", listRcd, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
        responseType: "blob",
      })
      .then(
        (response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          const file = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          setSubmitLoading(false);
          link.href = url;
          link.setAttribute("download", `${tableLabel}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setSubmitLoading(false);
        },
        (error) => {
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      );
  };

  const listJsonImport = () => {
    setShowFilePicker(true);
  };

  const exportToCsv = () => {
    axios
      .post(loca + "/data/export", listRcd.formRecordList, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const record = resp.data;
        download(record, listRcd.formRecordList[1].table.name);
      });
  };

  const importFromCsv = () => {
    //setShowFilePicker(true);
    navigation({
      pathname: user + "/importdata",
      search: createSearchParams({}).toString(),
    });
  };

  const importt = (files) => {
    const fileType = files[0].name.split(".").pop().toLowerCase();
    const fileName = files[0].name
      .replace(/(\(\d+\))|(_\d{8}_\d{6})|\.(csv|json)$/g, "")
      .trim();
    // const fileName = files[0].name.replace(/(\(\d+\))|\.(csv|json$)/g, "").trim();
    let newUrl = "";
    const hasFiles = files.length !== 0;
    const validUrlOrFileName =
      url === "/upload/update/json" ||
      fileName === tableName ||
      url === "/upload/component/json";
    if (url === "/upload/update/json") {
      newUrl = "/upload/update/json";
    } else if (url === "/upload/component/json") {
      newUrl = "/upload/component/json";
    } else {
      newUrl = "/json/import";
    }

    if (fileType === "csv" && hasFiles && fileName === tableName) {
      setIsClick(true);
      const blob = new Blob([files[0].content], { type: "text/csv" });
      const file = new File([blob], files[0].name, {
        type: "text/csv",
      });
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(loca + "/data/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            const record = resp.data;
            toast(record, {
              position: "top-center",
              theme: "colored",
              type: "success",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
            clear();
            refresh();
          },
          (error) => {
            setLoading(false);
            setModal(false);
            toast("Something went wrong", {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          }
        )
        .finally(() => {
          setShowFilePicker(false);
          setIsClick(false);
        });
    } else if (fileType === "json" && hasFiles && validUrlOrFileName) {
      setSubmitLoading(true);
      setShowFilePicker(false);
      setIsClick(true);
      const blob = new Blob([files[0].content], { type: "application/json" });
      const file = new File([blob], files[0].name, {
        type: "application/json",
      });
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(loca + newUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            const record = resp.data;
            if ("Error" in record) {
              setModal(false);
              toast(record.Error, {
                position: "top-center",
                theme: "colored",
                type: "error",
              });
            } else if ("Message" in record) {
              refresh();
              toast(record.Message, {
                position: "top-center",
                theme: "colored",
                type: "success",
              });
            } else {
              toast("Unexpected Error", {
                position: "top-center",
                theme: "colored",
                type: "error",
              });
            }
            // toast(record, {
            //   position: "top-center",
            //   theme: "colored",
            //   type: "success",
            //   style: {
            //     marginBottom: userDetails.OS !== "null" ? 12 : 0,
            //   },
            // });
            clear();
            refresh();
          },
          (error) => {
            setLoading(false);
            setModal(false);
            toast("Something went wrong", {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          }
        )
        .finally(() => {
          setSubmitLoading(false);
          // setShowFilePicker(false);
          setIsClick(false);
        });
    } else {
      toast("Table not match", {
        position: "top-center",
        theme: "colored",
        type: "error",
      });
      setShowFilePicker(false);
      clear();
    }
  };

  const download = (res, tablenm) => {
    const element = document.createElement("a");
    // for csv
    element.href = `data:text/csv;charset=utf-8,${res}`;
    element.download = tablenm;
    element.click();
    element.remove();
  };

  const jsonDownload = (res, tablenm) => {
    const element = document.createElement("a");

    const cleanedData = cleanMapKeys(res);
    const jsonString = JSON.stringify(cleanedData, null, 2);
    element.href = `data:application/json;charset=utf-8,${encodeURIComponent(
      jsonString
    )}`;
    element.download = `${tablenm}.json`;
    element.click();
    element.remove();
    setSubmitLoading(false);
  };

  const cleanMapKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(cleanMapKeys); // Recursively process array elements
    } else if (typeof obj === "object" && obj !== null) {
      const cleanedObj = {};
      for (const key in obj) {
        if (key === "map" && typeof obj[key] === "object") {
          // If the key is "map", merge its properties with the parent
          Object.assign(cleanedObj, cleanMapKeys(obj[key]));
        } else if (key === "myArrayList" && Array.isArray(obj[key])) {
          // If the key is "myArrayList", unwrap the array
          return cleanMapKeys(obj[key]);
        } else {
          cleanedObj[key] = cleanMapKeys(obj[key]); // Process nested objects
        }
      }
      return cleanedObj;
    }
    return obj; // Return the value if it's not an array or object
  };

  const getSortAndFilter = () => {
    let isAccessable = true;
    let listNam = "";
    if (!isSub.current) {
      listNam = searchParam.get("tableName");
      isAccessable = checkAccess() !== null;
      listName.current = listNam;
    } else {
      if (!listName.current) listName.current = searchParam.get("tableName");
      listNam = listName.current;
    }
    axios
      .get(loca + "/marketplace/get/sortfilter/" + listNam, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let rsp = resp.data;
          // let isAccessable = checkAccess() !== null;
          getListRecord(isAccessable, listNam, rsp.value[0].sort, rsp.value[1]);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const goToFormTable = () => {
    navigation({
      pathname: user + "/form",
      search: createSearchParams({
        rty: "record",
        tableName: "form_table",
        rid: tabId,
      }).toString(),
    });
  };

  const refresh = () => {
    // setSelectedRecord([]);
    // getListRecord();
    setSrch("");
    getSortAndFilter();
    checkMenu.current = true;
    setLoading(true);
  };

  const back = () => {
    navigation(-1);
  };

  const menuFn = (body) => {
    let fn = new Function(
      [
        "back",
        "refresh",
        "exportToCsv",
        "importFromCsv",
        "listJsonImport",
        "listJsonExport",
        "excelExport",
        "excelImport",
        /*    "listExcelExport", */
        "navigation",
        "goToFormTable",
        "tableName",
        "rid",
        "tablabel",
        "user",
        "all_ref_cols",
        "tabId",
      ],
      body
    );
    fn(
      back,
      refresh,
      exportToCsv,
      importFromCsv,
      listJsonImport,
      listJsonExport,
      excelExport,
      excelImport, //"excelImport"
      navigation,
      goToFormTable,
      tableName,
      recordId,
      tablabel,
      user,
      all_ref_cols,
      tabId
    );
  };

  const setContext = (val, x, y) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setShowContext(true);
    } else {
      setShowContext(false);
    }
  };
  const setListContext = (val, x, y, rid) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setRecordId(rid);
      setShowListContext(true);
    } else {
      setShowListContext(false);
    }
  };

  const selectedRows = (checked, index) => {
    let sss = [...list];
    sss[0].rcd[index].colr[0].ref = checked;
    if (!checked) {
      sss[0].heading[0].ref = false;
    }
    setlist([...sss]);
  };

  const selectedAllRows = (checked) => {
    let rcd = list[0].rcd;
    if (checked) {
      list[0].heading[0].ref = true;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = true;
      }
      setlist([...list]);
    } else {
      list[0].heading[0].ref = false;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = false;
      }
      setlist([...list]);
    }
  };

  const selectedAction = (url) => {
    if (list[0].rcd.length > 0) {
      if (url === "/marketplace/delete/record") {
        setbtnNamee("Delete");
        setModal(true);
        setUrl(url);
      } else if (url === "/marketplace/accept") {
        setbtnNamee("Accept");
        setModal(true);
        setUrl(url);
      } else if (url === "/marketplace/capture-component/record") {
        setbtnNamee("Capture");
        setShowComponentRecord(true);
        setUrl(url);
      } else {
        selectAction(url);
      }
    }
  };

  const selectAction = (url) => {
    setIsClick(true);
    let rcd = list[0].rcd;
    let postBody = [];
    for (let i = 0; i < rcd.length; i++) {
      if (rcd[i].colr[0].ref) {
        // rcd[i].colr.splice(0,1)
        postBody.push(
          rcd[i].colr[1].r_id
          // table: rcd[i].colr[1].table
        );
        continue;
      }
    }
    var jso = {
      url: url,
      table: rcd[0].colr[1].table,
      record: postBody,
    };
    if (componentId) {
      jso.componentId = componentId;
    }

    // setLoading(true);
    axios
      .post(loca + "/marketplace/selectedAction", jso, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          if (record.Error) {
            toast(record.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
          } else if (record.Inform) {
            toast(record.Inform, {
              position: "top-center",
              theme: "colored",
              type: "info",
            });
          } else {
            if (record.message) {
              toast(record.message, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            } else {
              toast(record, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            }
            refresh();
            // setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
          setModal(false);
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setModal(false);
        setIsClick(false);
        setComponentId("");
        setShowComponentRecord(false);
      });

    if (url === "1") {
    }
  };

  useEffect(() => {
    setMainError(false);
    setSelectedRecord([]);
    setSrch("");
    setLoading(true);
    if (location) {
      filt1.current = location.filter;
    } else {
      filt1.current = null;
    }
    // setFilter("");
    document
      .getElementsByTagName("body")
      .item(0)
      .addEventListener("mousedown", () => {
        setShowContext(false);
        setShowListContext(false);
      });
    if (dash_tableName) {
      dashBoardForm();
    } else {
      if (listAccess.current.length > 0) getSortAndFilter();
    }
  }, [searchParam, showAll, leftNavData]);

  const checkAccess = () => {
    let tabname = searchParam.get("tableName");
    let listCheck = listAccess.current.find((e) => e === tabname);
    if (listCheck) {
      return tabname;
    }
    return null;
  };

  const dashBoardForm = () => {
    listName.current = dash_tableName;
    isSub.current = true;
    // getListRecord();
    getSortAndFilter();
  };

  const getListRecord = (isAccessable, listName, srrt, flt) => {
    if (isAccessable) {
      setTableName(listName);
      // let pg = localStorage.getItem("pageClicked");
      sortColumn.name = srrt.column;
      sortColumn.sort = srrt.asc === "true" ? true : false;
      let pg = getFilterByTableName(listName);
      let p = 1;
      // let filt=[];
      // let srt = true;
      let srt = srrt.asc;
      let srtPage = srrt.column;
      // let srtPage = "id";
      var filterstate = "";
      filt1.current = flt.filter;
      if (pg) {
        let pag = pg;
        p = pag.page;
        filterstate = pag.filter;
        let sort = pag.sort;
        srt = sort.sort;
        if (sort.name !== "") {
          srtPage = sort.name;
        }
        setFiltArray([...filterstate]);
        setSortColumn(sort);
        localStorage.removeItem("pageClicked");
      }
      var farray = [];
      if (filterstate === "") {
        if (
          filt1.current === "" ||
          filt1.current === "undefined" ||
          filt1.current === undefined ||
          filt1.current === "null" ||
          filt1.current === null
        ) {
          let fl = {
            co: "",
            cl: "",
            mc: "",
            an: "",
            ct: "",
            af: "",
            rf: {
              id: "",
              value: "",
            },
            dc: {
              id: "",
              value: "",
            },
            ch: [],
          };
          farray.push(fl);
          sortColumn.name = "";
          sortColumn.sort = true;
          filterstate = farray;
        } else {
          // if ("filter" in filter) {
          //   farray = filter;
          //   filterstate = farray;
          // } else {
          // farray = filt1.current.filter;
          farray = filt1.current;
          filterstate = farray;
          // }
        }
        setFiltArray(farray);
      }
      var tablere = '{"formRecordList":[';
      tablere += '{"application":{"id":"2","name":"marketplace"}}';

      tablere += ',{"table":{"id":"","name":"' + listName + '"}}';
      tablere += ',{"records":[]}';
      tablere +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"' +
        p +
        '","page_records":"0"}}';
      tablere +=
        ',{"sort":{"asc":"' +
        srt +
        '","column":"' +
        srtPage +
        '","init":"true"}}';
      tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
      tablere += ',{"timeLine":"' + "" + '"}]}';
      setList(tablere);
    } else {
      setLoading(false);
      toast(
        "Incorrect search query or you don't have permission to access this",
        { pauseOnHover: true, theme: "colored", type: "error" }
      );
    }
  };

  const handleShow = () => {
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
  };

  const handleCloseRefresh = () => {
    handleClose();
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"marketplace"}}';
    tablere += ',{"table":{"id":"","name":"' + tableName + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere +=
      ',{"sort":{"asc":"' +
      sortColumn.sort +
      '","column":"' +
      sortColumn.name +
      '","init":"' +
      sortColumn.init +
      '"}}';
    tablere += ',{"filter":' + JSON.stringify(filtarray) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';
    setList(tablere);
  };

  const leftReadOnly = () => {
    if (pageClicked === 1) {
      return true;
    } else {
      return false;
    }
  };

  const rightReadOnly = () => {
    // console.log(pageClicked, pageCount, (pageClicked === pageCount));
    // if (
    //   pageClicked === pageCount ||
    //   (pageClicked + 1 === pageCount && pageClicked !== 1)
    // )
    // if (
    //   pageClicked === pageCount && pageClicked !== 1
    // )
    if (pageClicked === pageCount) {
      return true;
    } else {
      return false;
    }
  };

  const previousPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked - 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const nextPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked + 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';
    setList(pp);
  };

  const firstPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const lastPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (recordCount % 50 == 0 ? pageCount - 1 : pageCount) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/marketplace/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let chk = res.choiceRecords;
          return chk;
          // console.log(rcd);
        },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          navigation("/error", { state: { err: err } });
        }
      );
    return ck;
  };

  const callfilter = async (farray, in_index, col_id) => {
    if (col_id !== -1) {
      // for (let i = 0; i < rcd.length; i++) {
      //   console.log(index+" index "+i+" rcddddddd "+JSON.stringify(rcd[i]));
      if (
        farray[in_index].ct === "choice" &&
        farray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          if (res.length > 0) {
            farray[in_index].ch = res;
            farray[in_index].an = res[0].name;
          } else {
            farray[in_index].ch = [];
          }
        });
        //  console.log(chcRcd.current);
      }
      // }
    }
    setFiltArray([...farray]);
  };

  const calltimeline = (timeline) => {
    setTimeline(timeline);
  };

  const callScript = (script, index) => {
    var rcd = record;
    rcd[index].value.script = script;
    // rcd[index].value = { filter: rcd[index].value.filter,script:script };
    // console.log(rcd[index].value);
    setRecord([...rcd]);
  };

  const setTimeLine = (e) => {
    setTimeline(e);
  };

  const showupdownbtn = (hd) => {
    var sarray = sortColumn;

    let flt = JSON.parse(JSON.stringify(executedFilter.current));
    // if (!alreadyRun.current) {
    //   flt.pop();
    //   let ff = {
    //     co: "",
    //     cl: "",
    //     mc: "",
    //     an: "",
    //     ct: "",
    //     af: "",
    //     rf: { id: "", value: "" },
    //     dc: { id: "", value: "" },
    //   };
    //   flt.push(ff);
    // }
    var clmn = "";
    var srt = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].name === hd) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        srt = '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
        // pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        srt = '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
        // pp +=
        //   ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < list.length; li++) {
        for (var lll = 0; lll < list[li].heading.length; lll++) {
          if (list[li].heading[lll].name === hd) {
            clmn = list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        srt += '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;

        // pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        srt +=
          '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;

        // pp +=',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    }
    // setCol_mn({ sortColumn: sarray });
    pp += ',{"filter":' + JSON.stringify(flt) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';
    storeSrot(srt, pp);
  };

  const storeSrot = (sort, pp) => {
    let js = { tableName: tableName, sort: sort };
    axios
      .post(loca + "/marketplace/set/sort", js, {
        headers: {
          Authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          setList(pp);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  // if (val === "laptop") {
  //   ap_form.removeAllOption("u_model");
  //   ap_form.addOption("acer", "acer", "u_model");
  //   ap_form.addOption("hp", "hp", "u_model");
  // } else if (val === "mobile") {
  //   ap_form.removeAllOption("u_model");
  //   ap_form.addOption("samsung", "samsung", "u_model");
  //   ap_form.addOption("motorola", "motorola", "u_model");
  // }

  const setSort = () => {
    var clmn = "";
    var pp = "";
    var srt = sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].name === sortColumn.name) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }
      if (sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        list[0].heading[1].name +
        '","init":"false"}}';
    }

    return pp;
  };

  //.................. Api Call ...............//

  const processListRecords = (listrecord, isForUpdate) => {
    let columnarry = [];
    var hd = [];
    var va_ll = [];
    setPage_Error(false);
    setAlertType("");
    setError("");

    if ("Error" in listrecord) {
      setAlertType("danger");
      setError(listrecord.Error);
      setPage_Error(true);
      setLoading(false);
      // throw new Error(listrecord.Error);
    } else {
      var scnd = listrecord.formRecordList[2];
      if ("Error" in scnd) {
        if (scnd.Error === "No record found.") {
          setlist([]);
        }
      } else {
        var scnd = listrecord.formRecordList[2];
        if ("Error" in scnd) {
          if (scnd.Error === "No record found.") {
            setlist([]);
          }
        } else {
          setTableLabel(listrecord.formRecordList[1].table.label);

          var pageClicked1 = parseInt(
            listrecord.formRecordList[3].page.page_clicked
          );
          var pageCount1 = parseInt(
            listrecord.formRecordList[3].page.page_count
          );
          var page_records1 = listrecord.formRecordList[3].page.page_records;
          var record_count1 = parseInt(
            listrecord.formRecordList[3].page.record_count
          );
          var fltarr = listrecord.formRecordList[5].filter;
          var tmln = listrecord.formRecordList[6].timeLine;
          var filterString = "";
          var leng = fltarr.length;
          for (var f = 0; f < fltarr.length; f++) {
            if (leng === 1 && fltarr[f].cl === "") {
              break;
            } else {
              if (f > 0) {
                filterString += fltarr[f].af + " ";
              }
              filterString += fltarr[f].cl + " ";
              filterString += fltarr[f].mc + " ";
              if (
                fltarr[f].ct === "reference" ||
                fltarr[f].ct === "depend_table"
              ) {
                filterString += fltarr[f].rf.value + " ";
              } else {
                filterString += fltarr[f].an + " ";
              }
            }
          }
          for (
            var i = 0;
            i < listrecord.formRecordList[2].records.length;
            i++
          ) {
            var in_vl = [];
            var record_id;
            for (
              var j = 0;
              j < listrecord.formRecordList[2].records[i].record.length;
              j++
            ) {
              if (i === 0) {
                // if (j === 0) {
                // } else {
                //   hd.push({
                //     label:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .label,
                //     name: listrecord.formRecordList[2].records[i].record[j]
                //       .name,
                //   });
                // }
              }
              if (j === 0) {
                record_id =
                  listrecord.formRecordList[2].records[i].record[j].value;
              } else if (j === 1) {
                // if (
                //   listrecord.formRecordList[2].records[i].record[j].type ===
                //   "reference"
                // ) {
                //   in_vl.push({
                //     value:
                //       listrecord.formRecordList[2].records[i].record[j].value
                //         .value,
                //     type: listrecord.formRecordList[2].records[i].record[j]
                //       .type,
                //     firstrecord: true,
                //     table: listrecord.formRecordList[1].table.name
                //       ? listrecord.formRecordList[1].table.name
                //       : listrecord.formRecordList[1].table.value,
                //     r_id: record_id,
                //     name: listrecord.formRecordList[2].records[i].record[j]
                //       .value.name,
                //   });
                // } else

                {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: true,
                    table: listrecord.formRecordList[1].table.name
                      ? listrecord.formRecordList[1].table.name
                      : listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    name: listrecord.formRecordList[2].records[i].record[j]
                      .name,
                    columnAccess:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.columnAccess,
                    displayColumn:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.displayColumn,
                    uivalid:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.uivalid,
                    validation:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.validation,
                    name: listrecord.formRecordList[2].records[i].record[j]
                      ?.name,
                    id: listrecord.formRecordList[2].records[i].record[j].id,
                    rowIndex: i,
                    isInPref:
                      listrecord.formRecordList[2].records[i].record[j]
                        .isInPref,
                    choice:
                      listrecord.formRecordList[2].records[i].record[j]?.choice,
                    label:
                      listrecord.formRecordList[2].records[i].record[j]?.label,
                    dep_choice:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.dep_choice,
                  });
                }
              } else {
                // if (
                //   listrecord.formRecordList[2].records[i].record[j].type ===
                //   "reference"
                // ) {
                //   in_vl.push({
                //     value:
                //       listrecord.formRecordList[2].records[i].record[j].value,
                //     type: listrecord.formRecordList[2].records[i].record[j]
                //       .type,
                //     firstrecord: false,
                //     table: listrecord.formRecordList[1].table.name
                //       ? listrecord.formRecordList[1].table.name
                //       : listrecord.formRecordList[1].table.value,
                //     r_id: record_id,
                //     name: listrecord.formRecordList[2].records[i].record[j]
                //       .name,
                //   });
                // } else

                {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: false,
                    table: listrecord.formRecordList[1].table.name
                      ? listrecord.formRecordList[1].table.name
                      : listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    name: listrecord.formRecordList[2].records[i].record[j]
                      .name,

                    columnAccess:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.columnAccess,
                    displayColumn:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.displayColumn,
                    uivalid:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.uivalid,
                    validation:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.validation,
                    name: listrecord.formRecordList[2].records[i].record[j]
                      ?.name,
                    id: listrecord.formRecordList[2].records[i].record[j].id,
                    rowIndex: i,
                    isInPref:
                      listrecord.formRecordList[2].records[i].record[j]
                        .isInPref,
                    choice:
                      listrecord.formRecordList[2].records[i].record[j]?.choice,
                    label:
                      listrecord.formRecordList[2].records[i].record[j]?.label,
                    dep_choice:
                      listrecord.formRecordList[2].records[i].record[j]
                        ?.dep_choice,
                  });
                }
              }
            }

            in_vl.splice(0, 0, { ref: false, isInPref: true });
            va_ll.push({ colr: in_vl });
          }
          let col_list = [];
          for (var c = 0; c < listrecord.formRecordList[9].column.length; c++) {
            if (listrecord.formRecordList[9].column[c].type === "String") {
              col_list.push({
                label: listrecord.formRecordList[9].column[c].label,
                name: listrecord.formRecordList[9].column[c].name,
                type: listrecord.formRecordList[9].column[c].type,
              });
            }
          }
          for (
            var p = 0;
            p < listrecord.formRecordList[10].preference.length;
            p++
          ) {
            hd.push({
              label: listrecord.formRecordList[10].preference[p].label,
              name: listrecord.formRecordList[10].preference[p].name,
            });
          }
          hd.splice(0, 0, { ref: false });

          columnarry.push({ heading: hd, rcd: va_ll });

          listRef.current = columnarry;

          let column = listrecord.formRecordList[9].column;
          if ("Error" in column) {
            setPage_Error(true);
            setError(column.Error);
            setLoading(false);
            toast(column.Error, {
              type: "error",
              position: "top-center",
            });
          } else {
            var cla = JSON.parse(JSON.stringify(column));
            cla.unshift({ label: "None", name: "none" });
            setlist(columnarry);
            setRefList(JSON.parse(JSON.stringify(columnarry)));
            setListRcd(listrecord);
            setPageClicked(pageClicked1);
            setPageCount(pageCount1);
            setPageRecords(page_records1);
            setRecordCount(record_count1);
            setCol_mn(cla);
            setPrefCall(column);
            executedFilter.current = JSON.parse(JSON.stringify(fltarr));
            setFilter(fltarr);
            setfilString(filterString);
            setTimeline(tmln);
            setClearButtonLoading(false);
            setRunButtonLoading(false);
            setButton(listrecord.formRecordList[11].button);
            setColumnList(col_list);
            let name_srch = false;
            for (let z = 0; z < col_list.length; z++) {
              if (col_list[z].name === srch_column) {
                name_srch = true;
                break;
              }
            }
            if (!name_srch) {
              setSrch_column(col_list[0].name);
            }
            setLoading(false);
          }
        }
      }
    }
  };

  const setList = (tablere) => {
    multipleSelectedRows.current = new Map();
    rowsScriptHasBeenRun.current = null;
    axios
      .post(`${loca}/marketplace/get/multiple/record`, tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          if ("Error" in listrecord) {
            setAlertType("danger");
            setError(listrecord.Error);
            setPage_Error(true);
            setRecord([]);
            setLoading(false);
          } else {
            setTabId(listrecord.formRecordList[1].table.id);
            setTabLabel(listrecord.formRecordList[1].table.label);
            let rcd = listrecord.formRecordList[9].column;
            let ref_cols = [];
            for (let i = 0; i < rcd.length; i++) {
              rcd[i].index = i;
              if (
                rcd[i].type === "reference" &&
                rcd[i].name === "form_table_id"
              ) {
                ref_cols.push(rcd[i]);
              }
            }
            setAll_ref_cols(ref_cols);
            processListRecords(listrecord);
          }
        },
        (error) => {
          let err = { message: error.message, code: error.response?.status };
          navigation("/error", { state: { err: err } });
        }
      );
  };

  const changeSearch = (val) => {
    if (val === "") {
      // this.setState({ srch: "" });
      setSrch("");
      filterClear();
    } else {
      // this.setState({ srch: val });
      setSrch(val);
    }
  };

  const callSearchbtn = () => {
    // var fld = this.state.srch_column;
    // var val = this.state.srch;
    // var col = this.state.columnList;
    if (srch === "") {
      return;
    }

    var type = "";
    var label = "";
    for (var c = 0; c < columnList.length; c++) {
      if (srch_column === columnList[c].name) {
        type = columnList[c].type;
        label = columnList[c].label;
      }
    }
    let filter = [
      {
        ct: type,
        af: "",
        mc: "contains",
        cl: label,
        co: srch_column,
        an: srch,
        rf: { id: "", value: "" },
        dc: { id: "", value: "" },
      },
    ];
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    fs += ',{"table":{"id":"","name":"' + tableName + '","label":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"created" , "init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filter) + "}";
    fs += ',{"timeLine":""}]}';
    setFiltArray([...filter]);
    setList(fs);
  };

  const callform = (nam, tab, r_id, e) => {
    if (nam === "first") {
      let pg = { page: pageClicked, filter: filtarray, sort: sortColumn };
      // localStorage.setItem("pageClicked", JSON.stringify(pg));
      pushInListOfFilters(tab, pg);

      if (e.button === 0) {
        navigation({
          pathname: user + "/form",
          search: createSearchParams({
            rty: "record",
            tableName: tab,
            rid: r_id,
          }).toString(),
        });
      } else if (e.button === 1) {
        const newWindow = window.open(
          user + `/form?rty=record&tableName=${tab}&rid=${r_id}`,
          `_blank`
        );
        newWindow.onload = () => {
          newWindow.document.title = tablabel + " Record";
        };
      }
      // showFormCompo(tab, r_id, "record");
    } else if (nam === "second") {
    } else if (nam === "new") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          state: {
            rty: "new",
            tableName: tab,
            rid: 0,
          },
        }).toString(),
      });
      // navigation("/form", );
      // showFormCompo(tab, 0, "new");
    }
  };

  const callReport = (nam, tab, r_id, obj_r) => {
    let repName = "";
    if (tab === "tabular_report") {
      for (let k = 0; k < obj_r.colr?.length; k++) {
        if (obj_r.colr[k]?.name === "name") {
          repName = obj_r.colr[k]?.value;
          break;
        }
      }
      if (nam === "first") {
        let pg = { page: pageClicked, filter: filtarray, sort: sortColumn };
        navigation({
          pathname: user + "/report_view",
          search: createSearchParams({
            rty: "viewReport",
            tableName: tab,
            rid: r_id,
            reportName: repName,
          }).toString(),
          state: {},
        });

        //     navigation("/htmlcall", { state: { html: ht_ml, script: script } });

        // showFormCompo(tab, r_id, "record");
      } else if (nam === "second") {
      } else if (nam === "new") {
        navigation({
          pathname: user + "/form",
          search: createSearchParams({
            state: {
              rty: "new",
              tableName: tab,
              rid: 0,
            },
          }).toString(),
        });
        // navigation("/form", );
        // showFormCompo(tab, 0, "new");
      }
    }
  };

  // const dismiss = () => {
  //   unmountMe();
  // }

  const searchColumn = (e) => {
    // this.setState({ srch_column: e.target.value });
    setSrch_column(e.target.value);
  };

  const filterItem = () => {
    setShowList(!showlist);
    setShowbtn(!showbtn);
  };

  const filterClear = () => {
    var fltarray = [];
    let flt = {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "" },
      ch: [],
    };
    fltarray.push(flt);
    setFiltArray(fltarray);
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(fltarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const filtersubmit = () => {
    // alreadyRun.current = true;
    // let runFilt = false;

    sortColumn.sort = true;
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    fs += ',{"table":{"id":"","name":"' + tableName + '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"created","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';

    let len = filtarray.length;

    // for (let i = 0; i < filtarray.length; i++) {
    //   if (filtarray[i].ct === "reference") {
    //     if (filtarray[i].rf.value !== "" && filtarray[i].rf.id !== "0") {

    //       runFilt = true;
    //     } else {
    //       runFilt = false;
    //       break;
    //     }
    //   } else {
    //     if (filtarray[i].an !== "") {

    //       runFilt = true;
    //     } else {
    //       runFilt = false;
    //       break;
    //     }
    //   }
    // }

    // if (runFilt) {

    if (
      filtarray[len - 1].mc === "is filled" ||
      filtarray[len - 1].mc === "is empty" ||
      filtarray[len - 1].an !== "" ||
      filtarray[len - 1].rf?.value !== "" ||
      filtarray[len - 1].dc?.value !== "" ||
      timeline != ""
    ) {
      // executedFilter.current=JSON.parse(JSON.stringify(filtarray))
      setList(fs);
    }
    // }
  };

  const callbtn = (nam) => {
    var btn = button;
    var mnrecord = mainrecord;
    if (nam === "update") {
      let changedRC = changedRowAndCols.current;
      updateRecord({
        changedRowAndCols: changedRC,
        refList,
        list,
        setSubmitLoading,
        listName,
        token,
        loca,
        button,
        listRcd,
        setRefList,
      });
    }
    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        var btntype = btn[ij].buttonWebType;
        var nextPage = btn[ij].returnWebLocation;
        var callType = btn[ij].call_type;
        //   var token = localStorage.getItem("token");

        setPage_Error(false);
        setError("");
        setLoading(true);
        if (callType === "module") {
          var scpt = btn[ij].script;
          var f = new Function(
            ["navigation", "createSearchParams", "user"],
            scpt
          );
          f(navigation, createSearchParams, user);
        } else {
          if (nextPage === "NextPage") {
            callform("new", tableName, 0);
          } else {
            mnrecord.formRecord[2].record = record;
            axios
              .post(loca + btn[ij].webUrl, mnrecord, {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "Bearer " + token,
                  "market-application": appname,
                },
              })
              .then((resp) => {
                const rcd = resp.data;
                setLoading(false);
                var msg = rcd.formRecord[4].message;
                if (msg !== "") {
                  // setState({ page_message: true, message: msg });
                }
                if (btntype === "NextPage") {
                }
              });
          }
        }
      }
    }
  };

  const saveFilter = () => {
    let ft = { filter: filtarray, timeLine: timeline };
    let js = { tableName: tableName, filter: ft };
    axios
      .post(loca + "/marketplace/save/filter", js, {
        headers: {
          Authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          // setList(pp);
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const removeFilter = () => {
    let js = { tableName: tableName };
    axios
      .post(loca + "/marketplace/remove/filter", js, {
        headers: {
          Authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          setShowList(!showlist);
          setShowbtn(!showbtn);
          getSortAndFilter();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    dataLoadForUpdate.current = false;
  }, [listRcd]);

  const handleClick = (btnName, button) => {
    if (
      btnName === "upload" &&
      (tableName === "upload_updatejson" ||
        tableName === "upload_componentjson")
    ) {
      setUrl(button.webUrl);
      setbtnNamee(btnName);
      setShowFilePicker(true);
    } else {
      callbtn(btnName);
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      callSearchbtn();
    }
  };

  const setDefaultOption = () => {
    const selectElement = mySelect.current;
    selectElement.selectedIndex = 0;
  };

  useEffect(() => {
    let h = document.getElementById("list")?.getBoundingClientRect().height;
    if (h > 10) {
      setOverlayH(h);
    }
  }, [loading]);

  const formInner = (objr, ot) => {
    objr.index = ot.colIndex;
    return (
      <FormInnerCompo
        obj={objr}
        multiUpdate={true}
        m_rid={ot?.recordId}
        formChangefn={formChangefn}
        validationfn={validationfn}
        clickRefrence={clickRefrence}
      ></FormInnerCompo>
    );
  };

  const handleUpdateSave = (rowIndex, colCell) => {
    let otb = [...list];
    otb[0].rcd[rowIndex].colr[colCell].editingCellIndex = false;

    callAgainFormChangeFn(colCell, otb[0].rcd[rowIndex].colr[colCell]);
    setlist(otb);

    setTimeout(() => {
      callbtn("update");
    }, 500);
  };

  const handleUpdateCancel = (rowIndex, colCell) => {
    let otb = [...list];
    otb[0].rcd[rowIndex].colr[colCell].editingCellIndex = false;
    removeInChangedRowsAndCols(colCell, list[0].rcd[rowIndex].colr[colCell]);
    // this.setState({list:otb})
    setlist(otb);
  };

  const setRef = (id, val, properties, refTable) => {
    formChangefn(
      val,
      objIndex,
      objName,
      objType,
      id,
      true,
      curObj,
      refTable,
      "",
      "",
      "",
      properties,
      depCol
    );
    cancelModelList();
  };

  const cancelModelList = () => {
    setshowModelList(false);
  };

  const checkIfTypeNotExists = (type) => {
    for (let i = 0; i < listOfTypes.current?.length; i++) {
      if (listOfTypes?.current[i] === type) {
        return false;
      }
    }
    return true;
  };

  const removeInChangedRowsAndCols = (index, obj) => {
    let refLst = [...refList];
    let lst = [...list];
    let col = index;
    let unChanged = refLst[0].rcd[obj.rowIndex].colr[col];
    let changed = lst[0].rcd[obj.rowIndex].colr[col];
    if (unChanged.type === "reference" || unChanged.type === "depend_table") {
      formChangefn(
        unChanged.value.name,
        index,
        unChanged.name,
        unChanged.type,
        unChanged.value.id,
        true,
        unChanged,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        unChanged.uivalid.read_only
      );
    } else {
      formChangefn(
        unChanged.value,
        index,
        unChanged.name,
        unChanged.type,
        unChanged.id,
        true,
        unChanged,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        unChanged.uivalid.read_only
      );
    }

    if (changedRowAndCols.current) {
      const changedRowAndCols2 = changedRowAndCols.current;

      if (changedRowAndCols2.has(obj.rowIndex)) {
        let collArr = changedRowAndCols2.get(obj.rowIndex);
        let newCollArr = collArr.filter?.((e) => e !== index);
        if (newCollArr?.length !== 0) {
          changedRowAndCols2.set(obj.rowIndex, newCollArr);
        } else {
          changedRowAndCols2.delete(obj.rowIndex);
        }
      }
      changedRowAndCols.current = changedRowAndCols2;
    }
  };

  const addInChangedRowAndCols = (index, obj) => {
    if (!changedRowAndCols.current) {
      changedRowAndCols.current = new Map();
    }
    const changedRowAndCols2 = changedRowAndCols.current;
    if (changedRowAndCols2.has(obj.rowIndex)) {
      let collArr = changedRowAndCols2.get(obj.rowIndex);
      let found = collArr.find?.((e) => e === index);
      if (!found) collArr.push(index);
    } else {
      let collsArr = [];
      collsArr.push(index);
      changedRowAndCols2.set(obj.rowIndex, collsArr);
    }

    changedRowAndCols.current = changedRowAndCols2;
  };

  const addToMultipleSelectedRows = (varible, key, value) => {
    if (!varible) varible = new Map();
    if (varible) {
      varible.set(key, value);
    }
  };

  const removeFromMultipleSelectedRows = (varible, key) => {
    if (varible) {
      varible.delete(key);
    }
  };

  const getKeysFromMultipleSelectedRows = (varible) => {
    if (varible) {
      return Array.from(varible.keys());
    }
    return [];
  };

  const isColSelected = (row, col) => {
    if (!multipleSelectedRows.current) {
      multipleSelectedRows.current = new Map();
    }
    let newMultipleSelectedRows = multipleSelectedRows.current;
    if (
      col === multiSelectCol &&
      getKeysFromMultipleSelectedRows(newMultipleSelectedRows)?.includes(row)
    ) {
      return true;
    }
    return false;
  };

  const getIdForUpdate = (allCols) => {
    for (let k = 0; k < allCols?.length; k++) {
      if (allCols[k].firstrecord) {
        return allCols[k].r_id;
      }
    }
  };

  const handleRowClick = (e, rowIndex, colCel) => {
    e.preventDefault();
    let newMultipleSelectedRows = new Map();
    let otb = [...list];

    let allCols = otb[0].rcd[rowIndex].colr;
    let uni_id = "";
    uni_id = getIdForUpdate(allCols);

    if (multipleSelectedRows.current) {
      newMultipleSelectedRows = multipleSelectedRows.current;
    }

    if (colCel != multiSelectCol || rowIndex != multiSelectrow) {
      //  this.setState({multiSelectCol:colCel});
      setMultiSelectCol(colCel);
      setMultiSelectRow(rowIndex);
      newMultipleSelectedRows = new Map();
    }

    //  newMultipleSelectedRows  =[...this.multipleSelectedRows.current];
    if (!multipleSelectedRows.current) newMultipleSelectedRows = new Map();

    if (
      !getKeysFromMultipleSelectedRows(newMultipleSelectedRows).includes(
        rowIndex
      )
    ) {
      addToMultipleSelectedRows(newMultipleSelectedRows, rowIndex, uni_id);
      multipleSelectedRows.current = newMultipleSelectedRows;
    } else if (
      getKeysFromMultipleSelectedRows(newMultipleSelectedRows).includes?.(
        rowIndex
      ) &&
      e.ctrlKey
    ) {
      removeFromMultipleSelectedRows(newMultipleSelectedRows, rowIndex);
      multipleSelectedRows.current = newMultipleSelectedRows;
    }
  };

  const handleMouseDown = (rowIndex, colIndex, event) => {
    if (event.shiftKey) {
      // this.setState({
      //   isDragging: true,
      //   dragStartCell: { rowIndex, colIndex },
      // });
      setIsDragging(true);
      setDragStartCell({ rowIndex, colIndex });
      // Prevent text selection during drag
      document.body.style.userSelect = "none";

      // Change cursor to + sign when dragging starts
      document.body.style.cursor = "crosshair";
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStartCell(null);
    // Re-enable text selection
    document.body.style.userSelect = "auto";

    // Reset cursor back to default
    document.body.style.cursor = "default";
  };

  const handleMouseOver = (rowIndex, colIndex) => {
    //   const { isDragging, dragStartCell } = this.state;

    // console.log("rowIndex ",rowIndex);

    let otb = [...list];
    if (isDragging && dragStartCell) {
      const selected = multipleSelectedRows.current;
      for (
        let i = Math.min(dragStartCell.rowIndex, rowIndex);
        i <= Math.max(dragStartCell.rowIndex, rowIndex);
        i++
      ) {
        if (!getKeysFromMultipleSelectedRows(selected).includes?.(i)) {
          let allCols = otb[0].rcd[rowIndex].colr;
          let uni_id = "";
          uni_id = getIdForUpdate(allCols);
          addToMultipleSelectedRows(selected, rowIndex, uni_id);
        }
      }
      // this.setState({ selectedCells: selected })
      multipleSelectedRows.current = selected;
    }
  };

  const getRowsForUpdate = async (multipleSelectedRows) => {
    let filt = JSON.parse(
      '{"ct":"","rf":{"id":"","value":""},"af":"","ch":[],"mc":"","cl":"","co":"","an":"","dc":{"id":"","value":""}}'
    );
    let filtArr = [];

    if (multipleSelectedRows.current) {
      let cnt = 0;
      for (let [key, value] of multipleSelectedRows.current) {
        let obj = { ...filt };
        obj.ct = "String";
        obj.co = "uni_id";
        obj.cl = "Uni Id";
        obj.mc = "is";
        obj.an = value;
        if (cnt != 0) obj.af = "OR";
        filtArr.push(obj);
        cnt++;
      }

      // var pp = '{"formRecordList":[';
      // pp += '{"application":{"id":"","name":"marketplace"}}';
      // pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
      // pp += ',{"records":[]}';
      // pp +=
      //   ',{"page":{"record_count":"0","page_count":"1",' +
      //   '"page_clicked":"' +
      //   (pageClicked + 1) +
      //   '","page_records":"0"}}';
      // pp += setSort();
      // pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
      // pp += ',{"timeLine":"' + timeline + '"}]}';

      var fs = '{"formRecordList":[';
      fs += '{"application":{"id":"","name":"marketplace"}}';
      fs += ',{"table":{"id":"","name":"' + tableName + '","label":""}}';
      fs += ',{"records":[]}';
      // fs +=
      //   ',{"page":{"record_count":"0","page_count":"1",' +
      //   '"page_clicked":"1","page_records":"0"}}';
      fs +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"' +
        pageClicked +
        '","page_records":"0"}}';

      fs += setSort();
      fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
      fs += ',{"timeLine":"' + timeline + '"}] ,"forUpdate":true}';

      // console.log("fsss" ,fs);

      let url = loca + `/marketplace/get/multiple/record`;

      let res = await axios.post(url, fs, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      });

      const listrecord = res.data;
      processListRecords(listrecord, true);
    }
  };

  const handleDoubleClickOpenForUpdate = async (rowIndex, colCell) => {
    let otb = [];
    if (!dataLoadForUpdate.current) {
      await getRowsForUpdate(multipleSelectedRows);
      setTimeout(() => {
        dataLoadForUpdate.current = true;
      }, 500);
      otb = [...listRef.current];
      checkRefrecord();
    } else otb = [...list];
    if (!otb[0].rcd[rowIndex].colr[colCell].editingCellIndex) {
      otb[0].rcd[rowIndex].colr[colCell].editingCellIndex = true;
    }

    setlist(otb);
  };

  const checkRefrecord = async () => {
    let resp = await axios.get(
      loca + "/marketplace/get/reference/qualifier/" + listName.current,
      {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      }
    );
    const rq_data = JSON.parse(JSON.stringify(resp.data));
    setrecord_rq(rq_data);
  };

  const getMultiSelectValues = (obj) => {
    // console.log(obj);
    // let rcd=obj.record;
    // let res="";
    // if(rcd?.length!=0){
    //   for(let k=0; k<10 && k<rcd?.length;k++){
    //     if(k>0)
    //       res+=",";
    //      res=res+ rcd[k]?.value
    //   }
    // }
    // console.log(res);
    // return res;
  };

  const formChangefn = (
    vl,
    index,
    ob,
    type,
    id,
    vrf,
    obj,
    outerIndex,
    innerIndex,
    nme,
    props,
    depCol,
    readonly
  ) => {
    let record = [...list];
    //var record = otb[0].rcd[obj.rowIndex].colr;
    let abc = {
      vl,
      index,
      ob,
      type,
      id,
      vrf,
      obj,
      outerIndex,
      innerIndex,
      nme,
      props,
      depCol,
      readonly,
    };
    formChangeFunction({
      location: "list",
      vl,
      index,
      ob,
      type,
      id,
      vrf,
      obj,
      outerIndex,
      innerIndex,
      nme,
      props,
      depCol,
      readonly,
      record,
      setRecord: setlist,
      tableName: listName,
      addInChangedRowAndCols,
      loca,
      token,
    });

    // console.log(record);
  };

  const validationfn = (vl, index, ob, type, id, obj) => {
    let record = [...list];
    validationFunction({
      vl,
      index,
      ob,
      type,
      id,
      setError,
      record,
      setRecord: setlist,
      location: "list",
      obj,
      loca,
      token,
    });
  };

  const clickRefrence = (
    e,
    id,
    name,
    type,
    index,
    label,
    readonly,
    depend_column,
    refTable,
    obj
  ) => {
    e.preventDefault();
    //  console.log(record_rq, depend_column);
    setcolumn({
      index,
      name,
      type,
      col: id,
      readonly,
      depend_column,
      refTable,
      record_rq,
      setref_filter,
      setColumnId,
      setshowModelList,
      setObjRefTable,
      setObjName,
      setDepCol,
      setObjType,
      setObjIndex,
      setCurObj,
      obj,
      list,
      mainFilt,
    });
    // setColumnId(id);
    // setObjName(name);
    // setObjType(type);
    // setObjIndex(index);
    // setshowModelList(true);
    setObjLabel(label);
  };

  const callAgainFormChangeFn = (colIndex, obj) => {
    let otb = [...list];

    let allRows = getKeysFromMultipleSelectedRows(multipleSelectedRows.current);
    if (allRows?.includes(obj?.rowIndex)) {
      let att = allRows;
      for (let k = 0; k < att?.length; k++) {
        if (att[k] != obj.rowIndex) {
          let column = otb[0].rcd[att[k]].colr[colIndex];
          //   this.runScriptOnLoadWhenUpdate(column.rowIndex, colIndex);
          if (column.type === "reference" || column.type === "depend_table") {
            // formChangefn(obj.value.value ,colIndex ,column.name,column.type , obj.value.id , true ,column.uivalid.read_only,undefined,undefined,undefined,column)
            formChangefn(
              obj.value.name,
              colIndex,
              column.name,
              column.type,
              obj.value.id,
              true,
              column,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              column.uivalid.read_only
            );
          } else
            formChangefn(
              obj.value,
              colIndex,
              column.name,
              column.type,
              column.id,
              true,
              column,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              column.uivalid.read_only
            );
        }
      }
    }
    // this.callAginfm.current=false;
  };

  // form Change Function (fcf) sub functions

  useEffect(() => {
    if (submitLoading) {
      const messageTimeouts = [
        setTimeout(() => {
          loadtext.current?.classList.add("show");
          setSubLoadAck("Hang tight, we're processing your request...");
        }, 2000),
        setTimeout(
          () =>
            setSubLoadAck("Working on it... This might take a few moments."),
          7000
        ),
      ];

      // Clear timeouts when component unmounts
      return () => {
        loadtext.current?.classList.add("hide");
        setSubLoadAck("");
        messageTimeouts.forEach((timeout) => clearTimeout(timeout));
      };
    }
  }, [submitLoading]);
  const processText = (text) => {
    return String(text).replace(/\\n/g, " ");
  };

  if (!loading) {
    return (
      <div className="overfl-tab" id="list">
        {submitLoading && (
          <>
            <div
              style={{ height: overlayH, width: "75%" }}
              className="loading-overlay d-flex align-items-center justify-content-center"
            ></div>
            <div style={{ height: overlayH }} className="loading-ack">
              <h3 ref={loadtext} className="loading-msg">
                {subLoadAck}
              </h3>
            </div>
          </>
        )}
        <Modal show={modal} onHide={() => setModal(!modal)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm {btnNamee}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setModal(!modal)}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => selectAction(url)}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              {btnNamee}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showFilePicker}
          onHide={() => setShowFilePicker(!showFilePicker)}
        >
          <Modal.Header closeButton onClick={() => clear()}>
            <Modal.Title>
              <h5 className="mb-0">Import {tableLabel} Data</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="" sty>
            <div className="text-center">
              {filesContent.length === 0 && (
                <button className="w-100" onClick={() => openFilePicker()}>
                  Select files{" "}
                </button>
              )}
              {filesContent.map((file, index) => (
                <div key={index}>
                  <div key={file.name}>{file.name}</div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={() => clear()}>
              Clear
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => importt(filesContent)}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>

        {loading === true ? (
          <WorkInProgress />
        ) : (
          <div>
            {/* <ToastContainer
              position="top-center"
              autoclose={1800}
            /> */}
            {isMobile === true ? (
              <div className="row bck bck-rel">
                <div className="col-lg mid">
                  <span className="obj_head ">{tableLabel}</span>
                </div>
                <div className="col-lg filter-ic p-0">
                  {showlist === false && (
                    <select
                      style={{
                        // lineHeight: "0.8em",
                        width: "10em",
                        display: "inline",
                      }}
                      className="form-select namelist-mob"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch form-control"
                      type="search"
                      placeholder="Search"
                      value={srch}
                      id="b4-search"
                      onChange={(e) => changeSearch(e.target.value)}
                    ></input>
                  )}

                  {showlist === false && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"
                      onClick={() => callSearchbtn()}
                      style={{
                        height: document
                          .getElementById("b4-search")
                          ?.getBoundingClientRect().height,
                      }}
                    ></input>
                  )}
                </div>
                <div className="col-lg martop10 disfl filter-ic">
                  <i
                    className="fa fa-filter vlpointer pdtop5"
                    onClick={filterItem}
                  ></i>
                  <i
                    className="fa fa-cog vlpointer cog_pd pdtop5"
                    onClick={handleShow}
                  ></i>
                  <div className="" style={{ textAlign: "end" }}>
                    <span style={{ marginLeft: "0.5em" }}>
                      <input
                        className=" mybt"
                        type="button"
                        value="<<"
                        onClick={firstPage}
                        disabled={leftReadOnly()}
                      />
                      <input
                        className=" mybt"
                        type="button"
                        value="<"
                        onClick={previousPage}
                        disabled={leftReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className="in-put"
                        type="text"
                        readOnly={true}
                        value={pageRecords}
                      ></input>
                    </span>
                    <span className="text-uppercase spfont"> of </span>
                    <span className="rcd_count spfont">{recordCount}</span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">"
                        onClick={nextPage}
                        disabled={rightReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">>"
                        disabled={rightReadOnly()}
                        onClick={lastPage}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg martop10">
                  {button.length > 0 &&
                    button.map((obj, oo_i) => (
                      <input
                        type="button"
                        className="csm_btn csm_btn_pri col-md-2 sub-btn"
                        key={oo_i}
                        value={obj.name}
                        onMouseDown={(e) => callbtn(e, obj.value)}
                      ></input>
                    ))}
                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Run"
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Clear"
                      onClick={filterClear}
                    ></input>
                  )}
                </div>
                {/* <div className="col-lg martop10">
                  {showlist === true && (
                   
                  )}
                </div> */}
              </div>
            ) : (
              <div
                className="row bck bck-rel"
                onContextMenu={(e) => {
                  e.preventDefault();
                  if (isSub.current) {
                    let parent = document
                      .getElementById(list_id)
                      .getBoundingClientRect();
                    setContext(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContext(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              >
                <div>
                  {showContext && (
                    <ContextMenu
                      menuX={menuX}
                      menuY={menuY}
                      contextMenu={contextMenu}
                      menuFn={menuFn}
                      excluded_menu={[
                        "copy",
                        "paste",
                        "open_in_new_tab",
                        "json_export",
                        "json_import",
                        "record_insert",
                        // "import_from_csv",
                        // "export_to_csv",
                      ]}
                      conditionFn={true}
                    />
                  )}
                  {showListContext && (
                    <ContextMenu
                      menuX={menuX}
                      menuY={menuY}
                      contextMenu={contextMenu}
                      menuFn={menuFn}
                      excluded_menu={[
                        "copy",
                        "paste",
                        "refresh",
                        "form_view_setUp",
                        "back",
                        "json_export",
                        "export",
                        "import",
                        "record_insert",
                        "form_table",
                        "configure_all",
                      ]}
                      conditionFn={true}
                    />
                  )}
                </div>
                <div className="col-lg filter-ic p-0">
                  <i
                    className="fa fa-filter vlpointer"
                    onClick={filterItem}
                  ></i>

                  <i
                    className="fa fa-cog vlpointer cog_pd"
                    onClick={handleShow}
                  ></i>

                  {showlist === true && (
                    <input
                      className=" insrtbtn2 btn btn btn-primary btn-sm"
                      type="button"
                      value="Run"
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className=" insrtbtn2 btn btn btn-primary btn-sm"
                      type="button"
                      value="Clear"
                      onClick={filterClear}
                    ></input>
                  )}

                  {showlist === false && (
                    <select
                      style={{
                        width: "10em",
                        display: "inline",
                        lineHeight: "0.8em",
                      }}
                      className="form-select"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch form-control"
                      type="search"
                      aria-label="Search"
                      value={srch}
                      onChange={(e) => changeSearch(e.target.value)}
                      onKeyDown={handleEnterPress}
                      style={{ height: "1.72rem" }}
                    ></input>
                  )}

                  {showlist === false && (
                    <button
                      className=" insrtbtn2 btn btn btn-primary btn-sm"
                      type="button"
                      onClick={() => callSearchbtn()}
                      style={{
                        height: "1.73rem",
                      }}
                    >
                      Search
                    </button>
                  )}
                </div>
                <div
                  className="col-lg mid"
                  onTouchStart={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      setContext(2, e.touches[0].pageX, e.touches[0].pageY);
                    }, 100);
                  }}
                >
                  <p className="obj_head m-0">{tableLabel}</p>
                </div>
                <div className="col-lg bt_padd ">
                  <div>
                    <select
                      ref={mySelect}
                      className="dropdn"
                      onChange={(e) => {
                        e.target.value !== "none" &&
                          selectedAction(e.target.value);
                      }}
                    >
                      <option value={"none"}>None</option>
                      {button.length > 0 &&
                        list[0].rcd.length > 0 &&
                        button.map(
                          (obj, oo_i) =>
                            obj.ui_type === "selected_action" && (
                              <option key={oo_i} value={obj.webUrl}>
                                {obj.value}
                              </option>
                            )
                        )}
                    </select>
                    {/* <select onChange={(e) => { selectAction(e.target.value) }}>
                      <option>None</option>
                      <option value={1}>Delete</option>
                    </select> */}
                  </div>
                  {button.length > 0 &&
                    button.map(
                      (obj, oo_i) =>
                        obj.ui_type === "form_button" && (
                          <button
                            className=" insrtbtn2 btn btn btn-primary btn-sm"
                            key={oo_i}
                            onMouseDown={(e) => handleClick(obj.name, obj)}
                            style={{
                              height: "1.73rem",
                            }}
                          >
                            {obj.name}
                          </button>
                        )
                    )}
                  <div style={{ display: "flex" }}>
                    <input
                      className=" mybt"
                      type="button"
                      value="<<"
                      onClick={firstPage}
                      disabled={leftReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value="<"
                      onClick={previousPage}
                      disabled={leftReadOnly()}
                    />

                    <span className="in-put">{pageRecords}</span>
                    <span className="text-uppercase spfont"> of </span>

                    <span className="rcd_count spfont">{recordCount}</span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">"
                      onClick={nextPage}
                      disabled={rightReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value=">>"
                      disabled={rightReadOnly()}
                      onClick={lastPage}
                    />
                  </div>
                </div>
              </div>
            )}
            {showlist === false && filString !== "" && (
              <div style={{ textAlign: "start" }}>{filString}</div>
            )}
            {showlist === true && (
              <div>
                <div className="d-flex mb-2">
                  <input
                    className=" btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                    type="button"
                    value="Save Filter"
                    // disabled={rightReadOnly()}
                    onClick={saveFilter}
                  />

                  <input
                    className="btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                    type="button"
                    value="Remove Filter"
                    // disabled={rightReadOnly()}
                    onClick={removeFilter}
                  />
                </div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={col_mn}
                  call_fil={(filtarray, in_index, col_id) => {
                    callfilter(filtarray, in_index, col_id);
                  }}
                  filtarray={filtarray}
                  setFiltArray={setFiltArray}
                  tableName={tableName}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline)}
                  setScript={(script) => {}}
                  isScript={false}
                  col_depend={column_depend}
                  setColumn_depend={setColumn_depend}
                />
              </div>
            )}
            {list.map((lstobj, lst_i) => (
              <div
                className="heading_top table_set overflow-auto"
                style={{ height: formHeight - 10, position: "relative" }}
                key={lst_i}
              >
                <table
                  className="table table-bordered table-striped table-hover p-1"
                  style={{ borderTop: "1px solid gray" }}
                >
                  <thead
                    className="table-dark"
                    style={{ position: "sticky", top: "-1px" }}
                  >
                    <tr className="obj_name">
                      {lstobj.heading.map(
                        (obj, obj_i) =>
                          obj.type !== "group_key_value" &&
                          obj.type !== "key_value" &&
                          obj.type !== "password" && (
                            <th
                              key={obj_i}
                              className="vlpointer  px-2 py-0"
                              style={{ verticalAlign: "middle" }}
                            >
                              {obj_i === 0 ? (
                                <span>
                                  <input
                                    type="checkbox"
                                    checked={obj.ref}
                                    onChange={(e) => {
                                      selectedAllRows(e.target.checked);
                                    }}
                                  ></input>
                                </span>
                              ) : (
                                <div
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    padding: "0.5rem",
                                    textWrap: "nowrap",
                                  }}
                                  onClick={(e) => {
                                    showupdownbtn(obj.name);
                                  }}
                                >
                                  {obj.label}
                                  {sortColumn.name === obj.name &&
                                    sortColumn.sort === true && (
                                      <i className=" icpadding fa fa-arrow-up"></i>
                                    )}
                                  {sortColumn.name === obj.name &&
                                    sortColumn.sort === false && (
                                      <i className=" icpadding fa fa-arrow-down"></i>
                                    )}
                                </div>
                              )}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {lstobj.rcd.map((objj, objj_i) => (
                      <tr className="obj_value" key={objj_i}>
                        {objj.colr.map(
                          (objr, objr_i) =>
                            objr.type !== "key_value" &&
                            objr.type !== "group_key_value" &&
                            objr.type !== "password" &&
                            objr.isInPref && (
                              <td
                                key={objr_i}
                                className={
                                  objr.firstrecord === true
                                    ? "val_pad val_under vlpointer"
                                    : isColSelected(objj_i, objr_i)
                                    ? "selected val_pad"
                                    : "val_pad"
                                }
                                onDoubleClick={() =>
                                  handleDoubleClickOpenForUpdate(objj_i, objr_i)
                                }
                                onClick={(e) => {
                                  handleRowClick(e, objj_i, objr_i);
                                }}
                                onMouseDown={
                                  objr.firstrecord === true
                                    ? callType.callType === "viewreport"
                                      ? (e) =>
                                          callReport(
                                            "first",
                                            objr.table,
                                            objr.r_id,
                                            objj
                                          )
                                      : (e) =>
                                          callform(
                                            "first",
                                            objr.table,
                                            objr.r_id,
                                            e
                                          )
                                    : (e) => handleMouseDown(objj_i, objr_i, e)
                                }
                                onMouseUp={handleMouseUp}
                                onMouseOver={() =>
                                  handleMouseOver(objj_i, objr_i)
                                }
                                onContextMenu={(e) => {
                                  e.preventDefault();
                                  setListContext(
                                    e.button,
                                    e.nativeEvent.pageX,
                                    e.nativeEvent.pageY,
                                    objr.r_id
                                  );
                                }}
                              >
                                {objr_i === 0 ? (
                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={objr.ref}
                                      onChange={(e) => {
                                        selectedRows(e.target.checked, objj_i);
                                      }}
                                    ></input>
                                  </span>
                                ) : objr.type === "image_upload" ? (
                                  objr.value !== "null" && objr.value !== "" ? (
                                    <img
                                      className="list-images"
                                      // style={{
                                      //   height: "90px",
                                      //   width: "100%",
                                      //   objectFit: "contain",
                                      // }}
                                      src={imageloca + objr.value}
                                      alt=""
                                    />
                                  ) : (
                                    <span>No Image</span>
                                  )
                                ) : objr.type === "video" ? (
                                  <video
                                    loop
                                    className="user-video-list"
                                    controls
                                  >
                                    <source
                                      src={imageloca + objr.value}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : objr.type === "reference" ? (
                                  <span>
                                    {objr.value.name !== "null"
                                      ? objr.value.name
                                      : ""}
                                  </span>
                                ) : objr.type === "long_description" ? (
                                  <span>
                                    {objr.value !== "null"
                                      ? processText(objr.value)
                                      : ""}
                                  </span>
                                ) : (
                                  <span>
                                    {(objr.type === "filter" ||
                                      objr.type === "filter_ref" ||
                                      objr.name === "json") &&
                                      // JSON.stringify(
                                      //   objr.value?.length > 50
                                      //     ? objr.value.slice(0, 50) + " ..."
                                      //     : objr.value
                                      // )
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) &&
                                            formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              handleUpdateSave(objj_i, objr_i);
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : JSON.stringify(objr.value)?.length >
                                        50 ? (
                                        JSON.stringify(objr.value).slice(
                                          0,
                                          50
                                        ) + " ..."
                                      ) : (
                                        JSON.stringify(objr.value)
                                      ))}
                                    {objr.type === "group_key_value" &&
                                      // JSON.stringify(
                                      //   objr.value.properties?.length > 50
                                      //     ? objr.value.properties.slice(0, 50) +
                                      //         " ..."
                                      //     : objr.value.properties
                                      // )
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) &&
                                            formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              handleUpdateSave(objj_i, objr_i);
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                        JSON.stringify(
                                          objr.value.properties?.length > 50
                                            ? objr.value.properties.slice(
                                                0,
                                                50
                                              ) + " ..."
                                            : objr.value.properties
                                        )
                                      ))}

                                    {objr.type === "html" &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) &&
                                            formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              handleUpdateSave(objj_i, objr_i);
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              objr.value?.length > 50
                                                ? objr.value.slice(0, 50) +
                                                  " ..."
                                                : objr.value,
                                          }}
                                        ></span>
                                      ))}

                                    {(objr.type === "reference" ||
                                      objr.type === "depend_table") &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) &&
                                            formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {!notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) &&
                                            objr?.uivalid?.visible ===
                                              "false" && (
                                              <p className="inVisibleField">
                                                Security prevents writing to
                                                this field
                                              </p>
                                            )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              handleUpdateSave(objj_i, objr_i);
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : objr.value?.name?.length > 50 ? (
                                        objr.value?.name.slice(0, 50) + " ..."
                                      ) : (
                                        objr?.value?.name
                                      ))}

                                    {objr.type === "multi_select" &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) &&
                                            formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              handleUpdateSave(objj_i, objr_i);
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : getMultiSelectValues(objr.value)
                                          .length > 50 ? (
                                        getMultiSelectValues(objr.value).slice(
                                          0,
                                          50
                                        ) + " ..."
                                      ) : (
                                        getMultiSelectValues(objr.value)
                                      ))}

                                    {checkIfTypeNotExists(objr.type) &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) &&
                                            formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}

                                          {!notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) &&
                                            objr?.uivalid.visible ===
                                              "false" && (
                                              <p className="inVisibleField">
                                                Security prevents writing to
                                                this field
                                              </p>
                                            )}
                                          {notAllowedForUpdate?.current.includes?.(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              handleUpdateSave(objj_i, objr_i);
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : objr.value?.length > 50 ? (
                                        objr.value.slice(0, 50) + " ..."
                                      ) : (
                                        objr.value
                                      ))}
                                  </span>
                                )}
                              </td>
                            )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {lstobj.rcd.length === 0 && (
                  <div
                    style={{
                      fontWeight: "bold",
                      textDecoration: "none",
                      fontStyle: "normal",
                    }}
                  >
                    No Record Found
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        <Modal size="lg" centered show={showPref} onHide={handleClose}>
          <Modal.Header closeButton className="py-2">
            <Modal.Title>Personalized List Column</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mod-ht">
            <PrefCompo
              pref="user"
              setRefer={(val) => setRef_Fields(val)}
              colarray={prefCall}
              tablename={tableName}
              handleClose={() => handleCloseRefresh()}
            ></PrefCompo>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    return <WorkInProgress />;
  }
};

export default ListComponent;

import axios from "axios";
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  createSearchParams,
  Link,
  useBlocker,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import RelationList from "./RelationList";
import ApForm from "../HelperClasses/ApForm";
import ModelList from "./ModelList";
import ApUser from "../HelperClasses/ApUser";
import { toast } from "react-toastify";
import { Modal, Button, Tab, Nav, ListGroup, Dropdown } from "react-bootstrap";
import JoditEditor from "jodit-react";
import FormInnerCompo from "./FormInnerCompo";
import { isMobile } from "react-device-detect";
import "../css/FormCSS.css";
import "../css/relationlist.css";
import InvoiceLabel from "./InvoiceLabel";
import DragModel from "./DargModel";
import ContextMenu from "./ContextMenu";

const FormComponent = ({ dash_tabname, dash_rty, dash_rid, form_id }) => {
  // useNavigation hook
  const navigation = useNavigate();

  const dataa = useLocation();
  const location = useLocation().state;
  const pathName = useLocation().pathname;
  const [copyRcd, setCopyRcd] = useState([]);
  const [isKV, setIsKV] = useState(false);
  // useContext hook
  const {
    token,
    setShowContainer,
    showContainer,
    contextMenu,
    labelContext,
    loca,
    userDetails,
    imageloca,
    appname,
    user,
    listAccess,
    newAccess,
    leftNavData,
    formHeight,
    appName,
  } = useContext(MarketContext);

  // useState hooks
  // const [column_depend, setColumn_depend] = useState([]);
  const [selectedJson, setSelectedJson] = useState();
  const [objRefTable, setObjRefTable] = useState();
  const [uiflag, setUiflag] = useState(false);
  const [defUiValid, setDefUiValid] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tabRel, setTabRel] = useState(false);
  const [tabRelation, setTabRelation] = useState({});
  const [page_error, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [page_message, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [record, setRecord] = useState([]);
  const [rcdView, setRcdView] = useState([]);
  const [refrecord, setRefRecord] = useState({});
  const [button, setButton] = useState([]);
  const [sendProps, setSendProps] = useState("");
  const [mainRecord, setMainRecord] = useState([]);
  const [selection, setSelection] = useState("");
  const [uiScript, setUiScript] = useState([]);
  const [ap_form, setap_form] = useState();
  const [ap_user, setap_user] = useState();
  const [add, setAdd] = useState(false);
  const [multiSv, setMultiSv] = useState("");
  const [depCol, setDepCol] = useState();
  const [showModelList, setshowModelList] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [passwordReadOnly, setPasswordReadOnly] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [mscList, setMscList] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [current_Col_Types, setCurrent_Col_Types] = useState([]);
  const [cid, setCid] = useState(-1);
  const [column_depend, setColumn_depend] = useState([]);
  const [all_ref_cols, setAll_ref_cols] = useState([]);
  const [labelData, setLabelData] = useState(null);
  const [formChange, setFormChange] = useState(true);
  const [btnNamee, setbtnNamee] = useState("");
  const [relationLoading, setrelationLoading] = useState(false);
  const [invRcd, setInvRcd] = useState();
  const [filtarray, setFiltArray] = useState([
    {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "" },
      ch: [],
    },
  ]);
  const [tablabel, setTabLabel] = useState("");
  const [tabId, setTabId] = useState("");
  const [filt, setFilt] = useState();
  const [ref_filter, setref_filter] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [col_mn, setCol_mn] = useState([]);
  const [choice_mn, setChoice_mn] = useState([]);
  const [timeline, setTimeline] = useState("");
  const [columnId, setColumnId] = useState();
  const [objName, setObjName] = useState();
  const [objLabel, setObjLabel] = useState();
  const [objIndex, setObjIndex] = useState();
  const [objType, setObjType] = useState();
  const [record_rq, setrecord_rq] = useState([]);
  const [policyflag, setPolicyFlag] = useState(false);
  const [policyaction, setPolicyAction] = useState([]);
  const [policyFilter, setPolicyFilter] = useState();
  const [reScript, setReScript] = useState([]);
  const [formData, setFormData] = useState();
  const [objdata, setObjData] = useState();
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [runningScript, setRunningScript] = useState(false);
  const [verifyError, setVerifyError] = useState(true);
  const [infoRcdView, setInfoRcdView] = useState([]);
  const [showModelInfo, setShowModelInfo] = useState(false);
  const [infoRcdDetail, setInfoRcdDetail] = useState({});
  const [dcFiltVal, setDcFiltVal] = useState();
  const [downRcdView, setDownRcdView] = useState([]);
  const [activity, setActivity] = useState([]);
  const [secondaryStartIndex, setSecondaryStartIndex] = useState(0);
  const [overlayH, setOverlayH] = useState(0);
  const [secondarycallsCols, setSecondarycallsCols] = useState(new Map());
  const [shouldBlock, setShouldBlock] = useState(false);
  const [subLoadAck, setSubLoadAck] = useState("");
  const [viewList, setViewList] = useState([]);
  const [choice_ref_mn, setChoice_ref_mn] = useState([]);
  const formNavHeight = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonValue, setButtonValue] = useState("");
  const multipart = useRef(new FormData()).current;
  const [imgObj, setImgObj] = useState([]);
  const [modal, setModal] = useState(false);
  const [warning, setWarning] = useState("");
  let deftimeline = useRef("");
  const loadtext = useRef(null);
  const [keyValueJson, setKeyValueJson] = useState({
    properties: [],
  });
  const [groupkeyValue, setGroupKeyValue] = useState({
    properties: [
      {
        name: "",
        choice: [{ value: "" }],
      },
    ],
  });

  // const [i_d, seti_d] = useState(0)
  // const [activeSuggestion, setActiveSuggestion] = useState("");
  const [searchrcd, setSearchrcd] = useState([
    { name: "ap_form", show: false },
    { name: "getFieldValue()", show: false },
    { name: "setFieldValue()", show: false },
  ]);
  const [recordSuggestion, setRecordSuggestion] = useState([
    { name: "ap_form", show: false },
    { name: "ap_user", show: false },
    { name: "getFieldValue()", show: false },
    { name: "setFieldValue()", show: false },
    { name: "setMandatory()", show: false },
    { name: "isMandatory()", show: false },
    { name: "setVisible()", show: false },
    { name: "addOption()", show: false },
    { name: "removeOption()", show: false },
    { name: "removeAllOption()", show: false },
  ]);
  const [userRecordSuggestion, setUserRecordSuggestion] = useState([
    { name: "ap_user", show: false },
    { name: "getUserId()", show: false },
    { name: "getId()", show: false },
    { name: "getLabel ", show: false },
    { name: "getUniId()", show: false },
  ]);
  const [mainFilt, setMainFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
    dc: { id: "", value: "" },
    ch: [],
  });
  const [filtRefArray, setFiltRefArray] = useState([
    {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "" },
      ch: [],
    },
  ]);

  // useRef hooks
  let insert = useRef("");
  let tableName = useRef("");
  let rty = useRef("");
  let rid = useRef(0);
  let rcrd = useRef("");
  let tabRe = useRef("");
  let isSub = useRef(false);
  let count = useRef(1);
  let chcRcd = useRef([]);
  let uScript = useRef("");
  let listRef = useRef([]);
  let i_d = useRef(0);
  const editor = useRef(null);
  let menuX = useRef(0);
  let menuY = useRef(0);
  let m_rid = useRef(0);
  let checkMenu = useRef(false);
  let refValue = useRef("");
  let rcdRef = useRef("");
  let closeRef = useRef(false);
  let fieldName = useRef("").current;
  let recordNew = useRef("").current;
  let isBackAlertShow = useRef(true);

  useEffect(() => {
    if (searchParam.get("rty") === "new" && dataa.state) {
      ref_cols_insert();
    }
  }, [record, dataa]);

  const ref_cols_insert = () => {
    let rcdJson = dataa.state.ref_cols;
    if (rcdJson) {
      let rdJson = JSON.parse(JSON.stringify(rcdJson));
      var rcd = record;
      for (let i = 0; i < rdJson.length; i++) {
        for (let j = 0; j < rcd.length; j++) {
          if (rcd[j].name === rdJson[i].name) {
            rcd[j].value = rdJson[i].value;
            rcd[j].rcd_info = "true";
          }
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  };

  const getCaretPos = (e, index) => {
    // Getting the textarea element
    let textarea = document.getElementById("script" + index);

    // Adjusting textarea to prevent scrolling
    textarea.style.height = `${e.target.scrollHeight}px`;

    // Appending element to the DOM after textarea
    textarea.insertAdjacentHTML(
      "afterend",
      `<span id = 'dummy'>${textarea.value}</span>`
    );

    // Getting position info of the rectangles inside dummy element
    let rectangles = document.getElementById("dummy").getClientRects();
    let last = rectangles[rectangles.length - 1];

    // Getting position info of the textarea
    let text = document
      .getElementById("script" + index)
      .getBoundingClientRect();

    // Setting coordinates
    let x = last.x + last.width;
    let y = text.y + text.height - last.height;

    // Removing dummy
    document.getElementById("dummy").remove();

    // Returning variables
    return [x, y];
  };

  const onSelection = (type) => {
    let s;
    switch (type) {
      case "onsubmit":
        s = "function onSubmit(){";
        break;
      case "onload":
        s = "function onLoad(){";
        break;
      case "onchange":
        s = "function onChange(val){";
        break;
      case "oncelledit":
        s = "function onCellEdit(val){";
        break;

      case "onreference":
        s = "function onReference(){";
        break;

      default:
        s = "";
        break;
    }
    setSelection(s);
  };

  const searchModule = (nam, where, type) => {
    if (true) {
      if (type === "form") {
        var search = recordSuggestion;
        var abc = [];
        for (var i = 0; i < search.length; i++) {
          var app_m = search[i].name.toLowerCase();
          if (app_m.includes(nam.toLowerCase())) {
            if (where) {
              if (i === 0 || i === 1) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            } else {
              if (i > 0) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            }
          }
        }
        setSearchrcd([...abc]);
      } else if (type === "user") {
        var search = userRecordSuggestion;
        var abc = [];
        for (var i = 0; i < search.length; i++) {
          var app_m = search[i].name.toLowerCase();
          if (app_m.includes(nam.toLowerCase())) {
            if (where) {
              if (i === 0) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            } else {
              if (i > 0) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            }
          }
        }
        setSearchrcd([...abc]);
      }
    } else if (nam === "") {
      setSearchrcd([...recordSuggestion]);
    }
  };

  const callFieldList = (tabId, call_check) => {
    var filterstate = filtarray;
    var farray = [];
    if (
      filtarray === "" ||
      filtarray === "undefined" ||
      filtarray === undefined ||
      filtarray === "null" ||
      filtarray === null
    ) {
      farray.push(filt);
      filterstate = farray;
    } else {
      if (filtarray instanceof Array) {
        farray = filtarray;
        filterstate = farray;
      } else {
        farray = filtarray.filter;
        filterstate = farray;
      }
    }
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"marketplace"}}';
    tablere += ',{"table":{"id":"' + tabId + '","name":"","label":""}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"uni_id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';
    setList(tablere, call_check);
  };

  const setList = (tablere, call_check) => {
    // setLoading(true);
    axios
      .post(loca + "/marketplace/get/multiple/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const listrecord = resp.data;
        var columnarry = [];
        var hd = [];
        var va_ll = [];
        if (listrecord !== "") {
          if ("Error" in listrecord) {
            // this.setState({
            //   loading: false,
            //   page_error: true,
            //   error: listrecord.Error,
            // });
            setLoading(false);
            setPageError(true);
            setError(listrecord.Error);
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                // this.setState({ list: [] });
                // setlist([]);
                toast("No record found", { type: "error" });
              }
            } else {
              var page_clicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var page_count1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );

              var flt = listrecord.formRecordList[5];
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              for (var f = 0; f < fltarr.length; f++) {
                if (leng === 1 && fltarr[f].cl === "") {
                  break;
                } else {
                  if (f > 0) {
                    filterString += fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  filterString += fltarr[f].mc + " ";
                  filterString += fltarr[f].an + " ";
                }
              }

              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (i === 0) {
                    if (j === 0) {
                    } else {
                      hd.push({
                        label:
                          listrecord.formRecordList[2].records[i].record[j]
                            .label,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  }
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  }
                }
                va_ll.push({ colr: in_vl });
              }
              columnarry.push({ heading: hd, rcd: va_ll });
              var cla = JSON.parse(
                JSON.stringify(listrecord.formRecordList[9].column)
              );
              var choicearry = [];
              for (
                var z = 0;
                z < listrecord.formRecordList[9].column.length;
                z++
              ) {
                choicearry.push({
                  id: listrecord.formRecordList[9].column[z].id,
                  name: listrecord.formRecordList[9].column[z].name,
                  label: listrecord.formRecordList[9].column[z].label,
                  value: listrecord.formRecordList[9].column[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              if (!cla.Error) cla.unshift({ label: "None", name: "none" });
              // setTabLabel(listrecord.formRecordList[1].table.label);
              setPageError(false);
              setLoading(false);
              setChoice_mn(choicearry);
              setCol_mn(cla);
              setTimeline(tmln);
              // if (call_check) {
              //   relationVerify();
              // }
            }
          }
        }
      });
  };
  const callRefFieldList = (tabId, call_check) => {
    var filterstate = filtRefArray;
    var farray = [];
    if (
      filtRefArray === "" ||
      filtRefArray === "undefined" ||
      filtRefArray === undefined ||
      filtRefArray === "null" ||
      filtRefArray === null
    ) {
      farray.push(filt);
      filterstate = farray;
    } else {
      if (filtRefArray instanceof Array) {
        farray = filtRefArray;
        filterstate = farray;
      } else {
        farray = filtRefArray.filter;
        filterstate = farray;
      }
    }
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"marketplace"}}';
    tablere += ',{"table":{"id":"' + tabId + '","name":"","label":""}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"uni_id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';

    setRefList(tablere, call_check);
  };

  const setRefList = (tablere, call_check) => {
    // setLoading(true);
    axios
      .post(loca + "/marketplace/get/multiple/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const listrecord = resp.data;
        var columnarry = [];
        var hd = [];
        var va_ll = [];
        if (listrecord !== "") {
          if ("Error" in listrecord) {
            // this.setState({
            //   loading: false,
            //   page_error: true,
            //   error: listrecord.Error,
            // });
            setLoading(false);
            setPageError(true);
            setError(listrecord.Error);
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                // this.setState({ list: [] });
                // setlist([]);
                toast("No record found", { type: "error" });
              }
            } else {
              var page_clicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var page_count1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );

              var flt = listrecord.formRecordList[5];
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              for (var f = 0; f < fltarr.length; f++) {
                if (leng === 1 && fltarr[f].cl === "") {
                  break;
                } else {
                  if (f > 0) {
                    filterString += fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  filterString += fltarr[f].mc + " ";
                  filterString += fltarr[f].an + " ";
                }
              }

              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (i === 0) {
                    if (j === 0) {
                    } else {
                      hd.push({
                        label:
                          listrecord.formRecordList[2].records[i].record[j]
                            .label,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  }
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  }
                }
                va_ll.push({ colr: in_vl });
              }
              columnarry.push({ heading: hd, rcd: va_ll });
              var cla = JSON.parse(
                JSON.stringify(listrecord.formRecordList[9].column)
              );
              var choicearry = [];
              for (
                var z = 0;
                z < listrecord.formRecordList[9].column.length;
                z++
              ) {
                choicearry.push({
                  id: listrecord.formRecordList[9].column[z].id,
                  name: listrecord.formRecordList[9].column[z].name,
                  label: listrecord.formRecordList[9].column[z].label,
                  value: listrecord.formRecordList[9].column[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              cla.unshift({ label: "None", name: "none" });
              setPageError(false);
              setChoice_ref_mn(choicearry);
              setTimeline(tmln);
            }
          }
        }
      });
  };

  const setPolicy = (frecord, policyId, ob, reverse) => {
    let arr = defUiValid;
    // if (!(reverse === "false")) {
    if (!(reverse === "false")) {
      for (let t = 0; t < policyaction.length; t++) {
        for (let n = 0; n < frecord.length; n++) {
          if (
            policyId === policyaction[t].policy_id &&
            policyaction[t].form_column_id === frecord[n].id
          ) {
            let foundObject = arr.find((obj) => obj.id === frecord[n].id);
            if (!foundObject) {
              arr.push({
                id: frecord[n].id,
                uivalid: JSON.parse(JSON.stringify(frecord[n].uivalid)),
                name: ob,
              });
            }
          }
        }
      }
    }
    setDefUiValid(arr);
    for (let t = 0; t < policyaction.length; t++) {
      if (policyId === policyaction[t].policy_id) {
        for (let j = 0; j < frecord.length; j++) {
          if (frecord[j].id === policyaction[t].form_column_id) {
            if (
              policyaction[t].visible !== "null" &&
              policyaction[t].visible !== "leave"
            ) {
              frecord[j].uivalid.visible = policyaction[t].visible;
            }
            if (
              policyaction[t].read_only !== "null" &&
              policyaction[t].read_only !== "leave"
            ) {
              frecord[j].uivalid.read_only = policyaction[t].read_only;
            }
            if (
              policyaction[t].mandatory !== "null" &&
              policyaction[t].mandatory !== "leave"
            ) {
              frecord[j].uivalid.mandatory = policyaction[t].mandatory;
            }
          }
        }
      }
    }
    // }
  };
  const removeAction = (frecord, ob) => {
    let arr = [];

    for (let o = 0; o < frecord.length; o++) {
      for (let p = 0; p < defUiValid.length; p++) {
        if (frecord[o].id === defUiValid[p].id && ob === defUiValid[p].name) {
          frecord[o].uivalid = defUiValid[p].uivalid;
          arr.push(defUiValid[p]);
        }
      }
    }
    let newArray2 = defUiValid.filter(
      (obj2) => !arr.some((obj1) => obj1.id === obj2.id)
    );
    setDefUiValid(newArray2);
  };

  const getSecondaryFields = (ob, id, vl) => {
    let arr = secondarycallsCols.get(ob);

    let pt = "";
    if (secondarycallsCols.get(ob)) {
      var frecord = record;
      for (let p = 0; p < arr.length; p++) {
        if (p > 0) pt += ",";
        pt += JSON.stringify(arr[p]);
      }
      // let st=`{tabName:"${vl} , id:"${id} , columns:${pt}}`
      let st = { tabname: vl, id: id, columns: arr };

      axios
        .post(loca + "/marketplace/get/secondary/record", st, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          let data = resp.data;
          let colsRecord = data.colsRecord;
          for (let k = 0; k < colsRecord.length; k++) {
            for (
              let start = secondaryStartIndex;
              start < frecord.length;
              start++
            ) {
              if (frecord[start].formView.path === colsRecord[k].path) {
                frecord[start].value = colsRecord[k].value;
                if (frecord[start].type === "reference") {
                  frecord[start].rcd_info = "true";
                }
              }
            }
          }
          setRecord([...frecord]);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const formChangefn = (
    vl,
    index,
    ob,
    type,
    id,
    vrf,
    obj,
    outerIndex,
    innerIndex,
    nme,
    props,
    depCol,
    readonly
  ) => {
    let frecord = record;
    //  setting inital states before changing values
    fcf_intital(index, ob, vl, type, id);

    //  runs ui policy
    fcf_Uipolicy(ob, vl, frecord);
    if (type === "key_value_hidden_val") {
      let current_rcd = record[index];
      let curnt_prop_gkv = current_rcd.gkv.column_value.properties;
      let curnt_prop_gkv_ind =
        current_rcd.gkv.column_value.properties[outerIndex];
      let curnt_prop_hiddenvl = current_rcd.hidden_val.properties;
      let curnt_prop_hiddenvl_ind =
        current_rcd.hidden_val.properties[outerIndex];
      let nme = curnt_prop_gkv_ind.name;
      let vll = curnt_prop_gkv_ind.choice[innerIndex].value;
      curnt_prop_hiddenvl_ind.name = nme;
      curnt_prop_hiddenvl_ind.value = vll;
    }

    if (type === "reference") {
      fcf_reference(index, vl, id, type, ob, obj, vrf, props, depCol, frecord);
    } else if (type === "depend_table") {
      if (frecord[index].name === ob) {
        if (vl === "") {
          frecord[index].value.name = "";
          frecord[index].value.id = "0";
        } else {
          frecord[index].value.name = vl;
          frecord[index].value.id = id;
        }
        frecord[index].verified = "";
        frecord[index].rcd_info = "true";
        setRecord([...frecord]);
      }
    } else if (type === "choice") {
      if (readonly === "false") {
        frecord[index].value = vl;
        setRecord([...frecord]);
      }
    } else if (type === "dynamic_key_value") {
      fcf_dynamicKeyValue(vl, index, innerIndex, outerIndex, frecord);
    } else if (type === "key_value") {
      let crnt_rcd = record[index];
      for (let ab = 0; ab < record.length; ab++) {
        if (
          record[ab].type === "reference" &&
          record[ab].refTable.value === crnt_rcd.depend_table
        ) {
          record[ab].value.id = id;
          record[ab].value.name = vl;
        }
      }
      crnt_rcd.selected = true;
      crnt_rcd.gkv = props;
      setRefRecord({});
      let tmp_prpp = { properties: [] };
      if (crnt_rcd.gkv) {
        crnt_rcd.selected = true;
        for (let i = 0; i < crnt_rcd.gkv.column_value.properties.length; i++) {
          tmp_prpp.properties.push({ name: "", value: "" });
        }
        crnt_rcd.hidden_val = tmp_prpp;
      } else {
        crnt_rcd.selected = false;
      }
      if (vl.length > 2) {
        if (vrf === true) {
          if (frecord[index].name === ob) {
            frecord[index].value = vl;
            setRecord([...frecord]);
            setRefRecord({});
          }
        } else {
          if (frecord[index].name === ob) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }

          var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
          veri += '{"tabvalue":"' + vl + '"}]}';
          var rff = [];
          axios
            .post(loca + `/marketplace/reference/record`, veri.toString(), {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
                "market-application": appname,
              },
            })
            .then(
              (resp) => {
                const refrencercd = resp.data;
                if ("Error" in refrencercd) {
                } else {
                  rff = {
                    index: index,
                    record: refrencercd.referenceRecordList[2].records,
                  };
                  setShowContainer(true);
                  setRefRecord({ ...rff });
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      } else {
        if (frecord[index].name === ob) {
          frecord[index].value = vl;
          if (vl === "") {
            for (let ab = 0; ab < record.length; ab++) {
              if (
                record[ab].type === "reference" &&
                record[ab].refTable.value === crnt_rcd.depend_table
              ) {
                record[ab].value.id = "0";
                record[ab].value.name = vl;
                break;
              }
            }
            frecord[index].hidden_val = "";
          }
          setRecord([...frecord]);
          setRefRecord({});
        }
      }
      if (crnt_rcd.value.length > 1) {
        crnt_rcd.uivalid.mandatory = "true";
      } else {
        crnt_rcd.uivalid.mandatory = "false";
      }
    } else if (type === "group_key_value") {
      if (nme === "name") {
        frecord[index].value.properties[outerIndex][nme] = vl;
      }
      if (nme === "choice" && innerIndex !== null) {
        frecord[index].value.properties[outerIndex][nme][innerIndex].value = vl;
      }
      setRecord([...frecord]);
    } else {
      if (frecord[index].name === ob) {
        frecord[index].value = vl;
        setRecord([...frecord]);
      }
    }
    fcf_Uiscript(ob, vl, obj);
  };

  const fcf_intital = (index, name, value, object, type, refId, frecord) => {
    if (isBackAlertShow.current) {
      setShouldBlock(true);
    } else {
      isBackAlertShow.current = true;
    }

    if (name === "form_table_id" && value === "") {
      setChoice_mn([]);
    }

    if (name === "item_details_id") {
      let a = frecord.findIndex((e) => e.name === name);
      index = a;
    }

    if (type === "reference" && value.length < 3) {
      let a = frecord.findIndex(
        (e) => e.depend_column === object.name && e.name !== object.name
      );

      if (a >= 0) {
        frecord[a].dep_choice = [];
        setRecord(frecord);
      }
    }

    if (tableName.current === "reference_filter") {
      if (name === "form_column_id") {
        callColumnByColId(type, "form_column_id");
      }
    }
  };

  const fcf_Uipolicy = (colName, value, frecord) => {
    let selectedId = "";
    for (let t = 0; t < viewList?.length; t++) {
      if (viewList[t].selected === "true") selectedId = viewList[t].id;
    }

    if (policyFilter != undefined) {
      for (let s = 0; s < policyFilter.length; s++) {
        let pf = policyFilter[s].filter;
        let pfId = policyFilter[s].id;
        let global = policyFilter[s].global;
        let form_view_id = policyFilter[s].form_view_id;
        let reverse = policyFilter[s].reverse_if_false;

        if (
          pf !== undefined &&
          pf !== "" &&
          (global === "true" || selectedId === form_view_id)
        ) {
          var pfilt = pf.filter;
          for (let i = 0; i < pfilt.length; i++) {
            let p_policy = pfilt;
            let name = pfilt[i].co;
            let type = pfilt[i].ct;
            let sign = pfilt[i].mc;
            let answer;
            if (type === "reference") {
              answer = pfilt[i].rf.value;
            } else if (type === "depend_table") {
              answer = pfilt[i].dc.value;
            } else {
              answer = pfilt[i].an;
            }
            if (name == colName) {
              let arr = defUiValid;
              switch (sign) {
                case "is":
                  if (answer == value) {
                    setPolicy(frecord, pfId, colName, reverse);
                    // setPolicy(frecord, pfId, colName, reverse);
                  } else {
                    removeAction(frecord, colName);
                    // removeAction(frecord, colName);
                  }
                  break;
                case ">":
                  if (answer > value) {
                    if (
                      !isNaN(Date.parse(answer)) &&
                      !isNaN(Date.parse(value))
                    ) {
                    } else {
                    }
                    setPolicy(frecord, pfId, colName);
                  } else {
                    removeAction(frecord, colName);
                  }
                  break;
                case "<":
                  if (answer < value) {
                    setPolicy(frecord, pfId, colName);
                  } else {
                    removeAction(frecord);
                  }
                  break;

                default:
                  break;
              }
            }
          }
        }
      }
    }
  };

  const fcf_reference = (
    index,
    value,
    refId,
    type,
    colName,
    object,
    verify,
    properties,
    depend_column,
    frecord
  ) => {
    if (frecord[index].type.includes("group_key_value")) {
      if (value.properties) {
        keyValueJson.properties = [];
        let len = value.properties.length;
        for (let i = 0; i < len; i++) {
          keyValueJson.properties.push({
            name: "",
            value: "",
          });
        }
        setKeyValueJson(keyValueJson);
      }
      for (let i = 0; i < frecord.length; i++) {
        if (frecord[i].type === "key_value") {
          frecord[i].value = properties;
          break;
        }
      }
      setRecord([...frecord]);
    }

    if (tableName.current === "reference_filter") {
      if (colName === "form_column_id") {
        callColumnByColId(refId, "form_column_id");
      }
      if (colName === "form_table_id") {
        callTableColumn(value, false, depend_column, true);
      }
    } else if (
      (colName === "form_table_id" ||
        colName === "u_form_table_id" ||
        colName === "u_teacher_ref" ||
        object === "form_table") &&
      tableName.current !== "table_access" &&
      verify
    ) {
      // if (depend_column === colName){
      callTableColumn(value, false, depend_column);
      // }
    } else {
      if (tableName.current === "report") {
        callColumnByColId(refId);
      }
    }
    if (colName === "reference_id" && refId !== "0") {
      callRefFieldList(refId, true);
    }
    if (verify === false) {
      frecord[index].clicked = false;
    }
    if (value.length > 2) {
      if (verify === true) {
        if (frecord[index].name === colName) {
          frecord[index].value.name = value;
          frecord[index].clicked = true;
          frecord[index].value.id = refId;
          frecord[index].verified = "verified";
          frecord[index].rcd_info = "true";

          // index colName type
          getSecondaryFields(
            index,
            colName,
            type,
            refId,
            frecord[index]?.refTable?.value
          );
          setRecord([...frecord]);
          setRefRecord({});
          // validationfn(value, index, colName, type, id);
        }
      } else {
        if (frecord[index].name === colName) {
          frecord[index].value.name = value;
          frecord[index].value.id = refId;
          frecord[index].rcd_info = "true";
          getSecondaryFields(
            index,
            colName,
            type,
            refId,
            frecord[index]?.refTable?.value
          );
          setRecord([...frecord]);
        }

        var veri = '{"referenceRecord":[{"columnid":"' + refId + '"},';

        veri += '{"tabvalue":"' + value + '"}]}';
        var rff = [];
        axios
          .post(loca + `/marketplace/reference/record`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then(
            (resp) => {
              const refrencercd = resp.data;
              if (refrencercd == "" || "Error" in refrencercd) {
                toast.dismiss();
                toast("Something went wrong!", {
                  position: "top-center",
                  theme: "colored",
                  type: "error",
                });
              } else {
                rff = {
                  index: index,
                  record: refrencercd.referenceRecordList[2].records,
                };
                if (refrencercd.referenceRecordList[2].records.length > 0) {
                  frecord[index].verified = "";
                } else {
                  frecord[index].verified = "unverified";
                }
                frecord[index].value.name = value;
                frecord[index].value.id = refId;

                setShowContainer(true);
                setRefRecord({ ...rff });
                setRecord([...frecord]);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    } else {
      if (frecord[index].name === colName) {
        frecord[index].value.name = value;
        if (value === "") {
          frecord[index].value.id = "0";
        } else {
          frecord[index].value.id = refId;
        }
        frecord[index].verified = "";
        setRecord([...frecord]);
        setRefRecord({});
      }
    }
  };

  const fcf_dynamicKeyValue = (
    value,
    index,
    keyInnerIndex,
    keyOuterIndex,
    frecord
  ) => {
    let props = value;
    let abc = frecord[index].value[keyOuterIndex];
    if (keyInnerIndex === 0) {
      // console.log(
      //   frecord[index].value,
      //   Object.keys(abc),
      //   Object.keys(abc).includes(props)
      // );
      for (let i = 0; i < frecord[index].value.length; i++) {
        const ob = frecord[index].value[i];
        if (Object.keys(ob).includes(props)) {
          return;
        }
      }
      var key = Object.keys(abc)[0];
      var val = Object.values(abc)[0];
      delete abc[key];
      if (val) {
        abc[props] = val;
      } else {
        abc[props] = "";
      }
    } else {
      var key = Object.keys(abc)[0];
      if (key) {
        abc[key] = props;
      } else {
        abc[""] = props;
      }
    }
    setRecord([...frecord]);
  };

  const fcf_Uiscript = (colName, value, object) => {
    for (let i = 0; i < uiScript.length; i++) {
      let field = uiScript[i].field.name;
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      if (field === colName && type === "onchange") {
        setTimeout(() => {
          onChange(func, value, object);
        }, 100);
      }
    }
  };

  const fieldverify = (type, vl, validation) => {
    if (type === "String") {
      if (validation === "number") {
        if (/^[0-9]*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your number validation");
          return "unverified";
        }
      } else if (validation === "name") {
        if (/^[a-z][a-z0-9_]*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your name validation");
          return "unverified";
        }
      } else if (validation === "numbersAndCharacters") {
        if (/^[a-z0-9_]*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your numbersAndCharacters validation");
          return "unverified";
        }
      } else if (validation === "character") {
        if (/^[a-zA-Z\s]+$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your character validation");
          return "unverified";
        }
      } else if (validation === "withoutSpecialCharacter") {
        if (/^[_A-z0-9\s]*((-|\s)*[_A-z0-9])*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError(
            "Please verify your withoutSpecialCharacter validation"
          );
          return "unverified";
        }
      } else if (validation === "zipCode") {
        if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your zipCode validation");
          return "unverified";
        }
      } else if (validation === "decimal") {
        if (/^\d*\.?\d*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your decimal validation");
          return "unverified";
        }
      } else if (validation === "app_code") {
        if (/^[a-z][a-z0-9]*$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your app_code validation");
          return "unverified";
        }
      } else if (validation === "ipAddress") {
        if (/((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/.test(vl)) {
          return "verified";
        } else {
          setVerifyError("Please verify your ipAddress validation");
          return "unverified";
        }
      } else if (validation === "all") {
        return "verified";
      } else {
        return "verified";
      }
    } else if (type === "email") {
      if (/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        setVerifyError("Please verify your email!");
        return "unverified";
      }
    } else if (type === "int") {
      if (/^[0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    } else if (type === "datetime") {
      return "verified";
    } else {
      return "verified";
    }
  };

  const validationfn = (vl, index, ob, type, id) => {
    var formrecord = record;
    if (type === "reference") {
      if (vl.name !== "") {
        var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
        veri += '{"tabvalue":"' + JSON.stringify(vl) + '"}]}';
        axios
          .post(loca + `/marketplace/reference/verify`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then((resp) => {
            const refrencercd = resp.data;
            if (refrencercd.Result === "Unverified") {
              formrecord[index].verified = "unverified";
              // this.setState({ record: formrecord });
              setRecord([...formrecord]);
            } else {
              formrecord[index].verified = "verified";
              // this.setState({ record: formrecord, refrecord: [] });
              setRecord([...formrecord]);
              setRefRecord([]);
            }

            // this.setState({ record: formrecord });
            setRecord([...formrecord]);

            return;
          });
      } else {
        formrecord[index].verified = "initial";
        // this.setState({ record: formrecord });
        setRecord([...formrecord]);
      }
    } else {
      var minLength = formrecord[index].uivalid.min_length;
      if (minLength !== 0 && vl.length < minLength) {
        setVerifyError("Length of character or number should be " + minLength);
        formrecord[index].verified = "unverified";
      } else {
        if (formrecord[index].name === ob) {
          if (type === "int" || vl !== "") {
            formrecord[index].verified = fieldverify(
              formrecord[index].type,
              vl,
              formrecord[index].validation
            );
          } else {
            formrecord[index].verified = "initial";
          }
        }
      }
      setRecord([...formrecord]);
    }
  };

  const changeView = (index) => {
    let stdView = viewList[index];
    axios
      .post(loca + `/marketplace/change/view`, stdView, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        let rsp = resp.data;
        if ("Error" in rsp) {
          console.log(rsp.Error);
        } else {
          setViewList(resp);
          refresh();
        }
      });
  };

  const callForClient = (rcd, nam, btn, ij, btntype) => {
    let script = btn[ij].script;
    onSubmit(script);
    setTimeout(() => {
      setSubmitLoading(false);
      setIsClick(false);
    }, 400);
  };

  const callForScript = (nam, mnrecord) => {
    axios
      .post(loca + "/marketplace/serverrule/" + nam, mnrecord, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let rcdd = resp.data;
          if (rcdd !== "" && rcdd !== null) {
            if ("Error" in rcdd) {
              setModal(false);
              toast(rcdd.Error, {
                position: "top-center",
                theme: "colored",
                type: "error",
              });
            } else if ("Message" in rcdd) {
              refresh();
              toast(rcdd.Message, {
                position: "top-center",
                theme: "colored",
                type: "success",
              });
            } else {
              toast("Unexpected Error", {
                position: "top-center",
                theme: "colored",
                type: "error",
              });
            }
          }
        },
        (error) => {
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setIsClick(false);
        setSubmitLoading(false);
      });
  };

  const callForOthers = (mnrecord, btn, ij, nam, app) => {
    let bd;
    if (nam === "Approve" || nam === "Reject") {
      bd = {
        button: nam,
        recordId: rid.current,
        table: tableName.current,
        application: app,
        type: "manual",
        script: "",
      };
    } else {
      bd = mnrecord;
    }
    if (insert.current === "recordInsert")
      bd.isCalledFromUpdate = true;
    axios
      .post(loca + btn[ij].webUrl, bd, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          // "market-application": "marketplace",
        },
      })
      .then(
        (resp) => {
          let rcdd = resp.data;
          if (rcdd !== "" && rcdd !== null) {
            if ("Error" in rcdd) {
              setModal(false);
              toast(rcdd.Error, {
                position: "top-center",
                theme: "colored",
                type: "error",
              });
            } else if ("Warning" in rcdd) {
              // setModal(false);
              // setWarning(rcdd.Warning);
              toast(rcdd.Warning, {
                position: "top-center",
                theme: "colored",
                type: "warning",
              });
            } else if ("Inform" in rcdd) {
              toast(rcdd.Inform, {
                position: "top-center",
                theme: "colored",
                type: "info",
              });
            } else {
              let r_id = 0;
              if ("appRecord" in rcdd) {
                r_id = rcdd.appRecord[2].record[0].value;
              } else if ("columnRecord" in rcdd) {
                r_id = rcdd.columnRecord[2].record[0].value;
              } else if ("formRecord" in rcdd) {
                //  r_id = rcdd.formRecord[2].record[0].value;
                r_id = getUnidId(rcdd.formRecord[2].record);
              } else if ("choiceRecord" in rcdd) {
                r_id = rcdd.choiceRecord[2].record[0].value;
              } else if ("labelRecord" in rcdd) {
                r_id = rcdd.labelRecord[2].record[0].value;
              } else if ("buttonRecord" in rcdd) {
                r_id = rcdd.buttonRecord[2].record[0].value;
              } else if ("tableRecord" in rcdd) {
                r_id = rcdd.tableRecord[2].record[0].value;
              } else if ("typeRecord" in rcdd) {
                r_id = rcdd.typeRecord[2].record[0].value;
              } else if ("uivalidationRecord" in rcdd) {
                r_id = rcdd.uivalidationRecord[2].record[0].value;
              } else if ("languageRecord" in rcdd) {
                r_id = rcdd.languageRecord[2].record[0].value;
              } else if ("userRecord" in rcdd) {
                r_id = rcdd.userRecord[1].record[0].value;
              }

              if (
                btn[ij].returnWebLocation === "NextPage" &&
                insert.current === ""
              ) {
                localStorage.removeItem("pageClicked");
                navigation({
                  pathname: user + "/list",
                  search: createSearchParams({
                    tableName: tableName.current,
                  }).toString(),
                });
              } else {
                // if (insert.current === "insertAndStay") {
                //   insert.current = "";
                // } else
                if (insert.current === "recordInsert") {
                  rty.current = "record";
                  rid.current = r_id;
                  navigation({
                    pathname: user + "/form",
                    search: createSearchParams({
                      rty: "record",
                      tableName: tableName.current,
                      rid: r_id,
                    }).toString(),
                  });
                  insert.current = "";
                } else {
                  getFormDetails();
                }

                // if (checkMenu.current || ) {

                checkMenu.current += false;
                // } else {
                //   searchParam.set("rid", r_id);
                //   searchParam.set("tableName", tableName.current);
                //   searchParam.set("rty", "record");
                //   setSearchParam(searchParam);
                //   // getRecordForm(r_id, "");
                // }
              }
              if (nam.toLowerCase() === "insert") {
                toast("Insert Successfully", {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                  style: {
                    marginBottom: userDetails.OS !== "null" ? 12 : 0,
                  },
                });
              } else if (nam.toLowerCase() === "update") {
                setModal(false);
                toast("Updated Successfully", {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                  style: {
                    marginBottom: userDetails.OS !== "null" ? 12 : 0,
                  },
                });
              } else if (nam.toLowerCase() === "delete") {
                setModal(false);
                toast("Deleted Successfully", {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                  style: {
                    marginBottom: userDetails.OS !== "null" ? 12 : 0,
                  },
                });
              } else {
                toast("Successfull", {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                });
                setModal(false);
              }
            }
          } else {
            toast("Unexpected Error", {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          }
        },
        (error) => {
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSubmitLoading(false);
        // setLoading(false);
        setIsClick(false);
      });
  };

  const getUnidId = (rcd) => {
    for (let k = 0; k < rcd.length; k++) {
      if (rcd[k].name === "uni_id") {
        return rcd[k].value;
      }
    }
  };

  const checkMandatories = async (rcd, mandatory) => {
    if (rcd.uivalid.visible === "true" && rcd.type !== "boolean") {
      if (rcd.uivalid.mandatory === "true") {
        if (rcd.value === "") {
          mandatory.push(rcd.label.name);
        } else if (
          (rcd.type === "reference" || rcd.type === "depend_table") &&
          rcd.value.name === ""
        ) {
          mandatory.push(rcd.label.name);
        } else if (typeof rcd.value === "string" && rcd.value.trim() === "") {
          mandatory.push(rcd.label.name);
        } else if (rcd.type === "dynamic_key_value") {
          let objArr = rcd.value;
          for (let j = 0; j < objArr.length; j++) {
            if (
              Object.keys(objArr[j]).length === 0 ||
              Object.keys(objArr[j]).some(
                (key) => key === "" || objArr[j][key] === ""
              )
            ) {
              mandatory.push(rcd.label.name);
            }
          }
        } else if (rcd.type === "key_words" && rcd.value?.length === 0) {
          mandatory.push(rcd.label.name);
        }
      }
    }
    // if (
    //   rcd.type === "String" ||
    //   rcd.type === "int" ||
    //   rcd.type === "email" ||
    //   rcd.type === "date"
    // ) {
    //   var veri = rcd.verified;
    //   console.log("verif : " + veri);
    //   if (veri === "unverified") {
    //     unverified.push(rcd.label.name);
    //   }
    // }
    // if (
    //   rcd.type === "image_upload" &&
    //   rcd.fdata &&
    //   rcd.value !== "" &&
    //   rcd.value.includes("blob")
    // ) {
    //   if (mandatory.length === 0) {
    //     const ims = await uploadImage(
    //       rcd,
    //       imggg,
    //       rcd.file_path,
    //       rcd.compress
    //     );
    //     rcd.value = ims;
    //     rcdRef.current = ims;
    //     imggg++;
    //   }
    // }
    // if (rcd.type === "reference") {
    //   // var veri =refVerify(rcd.id,rcd.value);
    //   if (rcd.verified === "unverified") {
    //     unverified.push(rcd.label.name);
    //   }
    // }
    //   }
    // }
  };
  const finalFormSubmit = (btnRtype, btntype, rcd, mnrecord, btn, ij, nam) => {
    if (btnRtype === "html") {
      var ht_ml = "";
      var script = "";
      for (let i = 0; i < rcd.length; i++) {
        if (rcd[i].type === "html") {
          ht_ml = rcd[i].value;
        }
        if (rcd[i].name === "script") {
          script = rcd[i].value;
        }
      }
      navigation("/htmlcall", {
        state: { html: ht_ml, script: script },
      });
    } else if (btnRtype === "Grid") {
      navigation("/clientinforecord", {
        state: { rid: rid.current, tableName: tableName.current },
      });
    } else {
      //Check record type
      const keys = [
        "appRecord",
        "columnRecord",
        "formRecord",
        "choiceRecord",
        "labelRecord",
        "buttonRecord",
        "tableRecord",
        "typeRecord",
        "uivalidationRecord",
        "languageRecord",
        "userRecord",
      ];

      keys.forEach((key) => {
        if (key in mnrecord) {
          mnrecord[key][2].record = rcd;
        }
      });
      if (btn[ij].call_type === "client") {
        callForClient(rcd, nam, btn, ij, btntype);
      } else if (btn[ij].call_type === "run_script") {
        let app = "";
        for (let i = 0; i < rcd.length; i++) {
          if (rcd[i].name === "market_application") {
            app = rcd[i].value;
          }
        }
        var jn = {
          button: nam,
          recordId: rid.current,
          table: tableName.current,
          application: app,
          type: "manual",
          script: "",
        };
        setRunningScript(true);
        axios
          .post(loca + btn[ij].webUrl, jn, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then((resp) => {
            setSubmitLoading(false);
            const rcd = resp.data;
            if (rcd.download) {
              pdfDownload(rcd.label_details[0]);
            }
            if (rcd !== "") {
              setRunningScript(false);
              if ("Error" in rcd) {
                setSubmitLoading(false);
                setPageError(true);
                setError(rcd.Error);
              } else {
                setSubmitLoading(false);
                var msg = rcd.message;
                if (msg !== "") {
                  setPageMessage(true);
                  setMessage(msg);
                  // getRecordForm(rid.current, "");
                }
                if (btntype === "NextPage") {
                  var nextP = btn[ij].nextPage;
                  if (nextP === "List") {
                  }
                }
              }
            }
          })
          .finally(() => {
            setIsClick(false);
          });
      } else if (btn[ij].call_type === "run_script_server") {
        callForScript(nam, mnrecord);
      } else if (
        btn[ij].webUrl === "/update/json/export" ||
        btn[ij].webUrl === "/update/component/export"
      ) {
        exportData(mnrecord, btn[ij].webUrl);
      } else if (
        btn[ij].webUrl === "/update/json/import/" ||
        btn[ij].webUrl === "/component/json/import/"
      ) {
        importData(mnrecord, btn[ij].webUrl);
      } else {
        let app = "";
        for (let i = 0; i < rcd.length; i++) {
          if (rcd[i].name === "application") {
            app = rcd[i].value;
          }
        }
        callForOthers(mnrecord, btn, ij, nam, app);
      }
    }
  };
  const showMandatoryError = (mandatory, unverified) => {
    setSubmitLoading(false);
    setSubmitLoading(false);
    setIsClick(false);
    setModal(false);
    var error_String = "";
    if (mandatory.length > 0) {
      error_String += " Check mandatory fields not set: " + mandatory;
    }
    if (unverified.length > 0) {
      error_String += " Fields unverified:" + unverified;
    }
    setError(error_String);
    setPageError(true);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const checkFeildVerified = (rcd, unverified) => {
    if (
      rcd.type === "String" ||
      rcd.type === "int" ||
      rcd.type === "email" ||
      rcd.type === "date"
    ) {
      if (rcd.value != "") {
        var veri = fieldverify(rcd.type, rcd.value, rcd.validation);
        if (veri === "unverified") {
          unverified.push(rcd.label.name);
        }
      }
    }
    if (rcd.type === "reference") {
      if (rcd.verified === "unverified") {
        unverified.push(rcd.label.name);
      }
    }
  };
  const callbtn = async (nam) => {
    try {
      let btn = button;
      var mnrecord = mainRecord;
      var rcd = record;
      var mandatory = [];
      var unverified = [];
      let imggg = 0;
      var btntype = "";
      var btnRtype = "";
      var foundbtn = false;

      for (let i = 0; i < uiScript?.length; i++) {
        let func = uiScript[i].script;
        let type = uiScript[i].type;
        if (type === "onsubmit") {
          onSubmit(func);
        }
      }
      if (insert.current !== "") {
        addInsertButton(btn);
      }

      for (var i = 0; i < rcd.length; i++) {
        if (rcd[i].uivalid.visible === "true") {
          if (rcd[i].uivalid.mandatory === "true") {
            checkMandatories(rcd[i], mandatory);
          }
          checkFeildVerified(rcd[i], unverified);

          if (
            rcd[i].type === "image_upload" &&
            rcd[i].fdata &&
            rcd[i].value !== "" &&
            rcd[i].value.includes("blob")
          ) {
            if (mandatory.length === 0) {
              const ims = await uploadImage(
                rcd[i],
                imggg,
                rcd[i].file_path,
                rcd[i].compress
              );
              rcd[i].value = ims;
              rcdRef.current = ims;
              imggg++;
            }
          }

          if (rcd[i].type === "upload_pdf" && rcd[i].fdata) {
            if (mandatory.length === 0) {
              const ims = await uploadPdf(rcd[i], rcd[i].file_path);
              rcd[i].value = ims;
              rcdRef.current = ims;
              imggg++;
            }
          }
          if (
            rcd[i].type === "video" &&
            rcd[i].value !== "" &&
            rcd[i].value.includes("blob")
          ) {
            if (mandatory.length === 0) {
              const ims = await uploadVideo(rcd[i], imggg, null);
              rcd[i].value = ims;
              rcdRef.current = ims;
            }
          }
          if (rcd[i].type === "key_value") {
            if (rcd[i].hidden_val && rcd[i].value !== "") {
              rcd[i].value = rcd[i].hidden_val;
            } else {
              rcd[i].value = { properties: [] };
            }
          }
        } else {
          if (rcd[i].type === "key_value") {
            if (rcd[i].hidden_val && rcd[i].value !== "") {
              rcd[i].value = rcd[i].hidden_val;
            } else {
              rcd[i].value = { properties: [] };
            }
          }
        }
      }

      // checkMandatories(rcd, mandatory, unverified, imggg);
      for (var ij = 0; ij < btn.length; ij++) {
        if (btn[ij].value === nam) {
          btntype = btn[ij].returnWebLocation;
          btnRtype = btn[ij].recordReturnType;
          foundbtn = true;
          break;
        }
      }
      if (foundbtn === true || insert.current !== "") {
        setPageError(false);
        setError("");
        setPageMessage(false);
        setMessage("");
        setTimeout(() => {
          if (mandatory.length === 0 && unverified.length === 0) {
            setIsClick(true);
            setSubmitLoading(true);
            setShouldBlock(false);
            setWarning("");
            finalFormSubmit(btnRtype, btntype, rcd, mnrecord, btn, ij, nam);
          } else {
            showMandatoryError(mandatory, unverified);
          }
        }, 100);
      } else {
        setIsClick(false);
        setSubmitLoading(false);
        setShouldBlock(true);
      }
    } catch (error) {
      setSubmitLoading(false);
      setIsClick(false);
      setLoading(false);
      toast("Something went wrong", {
        position: "bottom-center",
        theme: "colored",
        type: "error",
      });
    }
  };

  const setMSC = (val, index, name, type, id) => {
    formChangefn(val, index, name, type, id, true);
  };

  const setContextMenu = (val, x, y) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setShowContextMenu(true);
    } else {
      setShowContextMenu(false);
    }
  };

  const setContext = (val, x, y) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setShowContext(true);
    } else {
      setShowContext(false);
    }
  };

  const generatePassword = () => {
    let frecord = record;
    let count = 0;
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 8;
    var password = "";
    for (var i = 0; i < passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === "password") {
        frecord[i].value = password;
        count++;
      }
      if (frecord[i].name === "reset_password") {
        frecord[i].value = "true";
        count++;
      }
      if (count == 2) {
        break;
      }
    }
    setRecord([...frecord]);
    setPasswordReadOnly(true);
  };

  const setRefRcd = (vl, index, ob, type, id, obj) => {
    // setcolumn(index, ob, type, obj.id, "false", obj.depend_column)
    formChangefn(
      vl,
      index,
      ob,
      type,
      id,
      true,
      obj,
      0,
      0,
      "",
      "",
      obj.depend_column
    );
  };

  const getFieldValue = (col_id) => {
    var rcd = record;
    console.log(rcd);
    
    if (rcd !== "null" && rcd !== "") {
      for (var r = 0; r < rcd.length; r++) {
        if (rcd[r].id === col_id) {
          return rcd[r].value;
        }
      }
    }
  };

  const setcolumn = async (
    index,
    name,
    type,
    col,
    readonly,
    depCol,
    refTable
  ) => {
    if (readonly === "false") {
      var rf = record_rq?.referenceQualifier;
      console.log(rf);
      // var ref_filt = ref_filter;
      let ref_filt = [];
      let filtt = [];

      for (var r = 0; r < rf.length; r++) {
        if (rf[r].formColumn.id === col && "filter" in rf[r]) {
          filtt = rf[r].filter.filter;
          //  var filt = rf[r].filter.filter;
          for (var f = 0; f < filtt.length; f++) {
            if (
              filtt[f].mc === "is dependent" ||
              filtt[f].ct === "depend_table"
            ) {
              var addFilt = "";
              if (f > 0) {
                addFilt = filtt[f].af;
              }

              var col_name = filtt[f].rf.value;
              var co_label = filtt[f].rf.label;
              console.log(filtt,record);
              
              let ab = await getFieldValue(filtt[f].dc.id);
              var filtt1 = JSON.parse(JSON.stringify(mainFilt));
              console.log(ab);
              

              filtt1.co = col_name;
              filtt1.cl = co_label;
              filtt1.ct = filtt[f].ct;
              filtt1.mc = "is";
              filtt1.an = "";
              filtt1.af = addFilt;
              filtt1.rf = { id: ab?.id, value: ab?.name };
              filtt1.dc = { id: 0, value: "" };
              ref_filt.push(filtt1);
            } else {
              ref_filt.push(filtt[f]);
            }
          }
        }
      }
      if (ref_filt.length === 0) {
        const refarray = {
          filter: [
            {
              co: "",
              cl: "",
              mc: "",
              an: "",
              ct: "",
              af: "",
              rf: { id: "", value: "" },
              dc: { id: "", value: "" },
            },
          ],
        };
        setref_filter(refarray);
      } else {
        const refarray = {
          filter: ref_filt,
        };
        setref_filter(refarray);
      }

      setColumnId(col);
      setObjIndex(index);
      setObjType(type);
      setDepCol(depCol);
      setObjName(name);
      setObjRefTable(refTable);
      setshowModelList(true);
      setTimeout(() => {
        if (name === "field_id" || name === "form_column_id") {
          if (ref_filter.length > 0) {
            setReference();
          }
        }
      }, 500);
    }
  };

  const clickRefrence = (
    e,
    id,
    name,
    type,
    index,
    label,
    readonly,
    depend_column,
    refTable,
    tf
  ) => {
    e.preventDefault();
    setIsKV(tf);
    setcolumn(index, name, type, id, readonly, depend_column, refTable);
    // setColumnId(id);
    // setObjName(name);
    // setObjType(type);
    // setObjIndex(index);
    // setshowModelList(true);
    setObjLabel(label);
  };

  const getSingleInfo = (e, obj) => {
    e.preventDefault();
    let infoRecord = {};
    if (obj.type === "depend_table") {
      infoRecord.tabName = "form_column";
      infoRecord.tabid = obj.value.id;
    } else {
      infoRecord.tabName = obj.refTable.value;
      infoRecord.tabid = obj.value.id;
    }
    closeRef.current = true;
    if (infoRecord.tabid != "0") {
      axios
        .get(
          loca +
            `/marketplace/get/singlerecord/${infoRecord.tabName}/${infoRecord.tabid}`,
          {
            headers: {
              // "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          }
        )
        .then(
          (resp) => {
            const record = resp.data;
            var mmm;
            if ("userRecord" in record) {
              mmm = record.userRecord[2].record;
              infoRecord.tabLabel = record.userRecord[1].table.label;
            } else {
              mmm = record.formRecord[2].record;
              infoRecord.tabLabel = record.formRecord[1].table.label;
            }
            setInfoRcdDetail(infoRecord);

            let rcd = [];
            if ("Error" in record) {
              navigation("/dashboard", { state: { error: record.Error } });
            } else {
              if ("formRecord" in record) {
                rcd = record.formRecord[2].record;
              } else if ("buttonRecord" in record) {
                rcd = record.buttonRecord[2].record;
              } else if ("tableRecord" in record) {
                rcd = record.tableRecord[2].record;
              } else if ("typeRecord" in record) {
                rcd = record.typeRecord[2].record;
              } else if ("uivalidationRecord" in record) {
                rcd = record.uivalidationRecord[2].record;
              } else if ("languageRecord" in record) {
                rcd = record.languageRecord[2].record;
              } else if ("userRecord" in record) {
                rcd = record.userRecord[2].record;
              } else {
                console.log("table record not found");
              }
            }
            if (tableName.current === "reference_filter") {
              axios
                .get(loca + `/marketplace/get/refFilter/${infoRecord.tabid}`, {
                  headers: {
                    // "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                  },
                })
                .then(
                  (resp) => {
                    const coldata = resp.data;
                    if (coldata !== "") {
                      if ("Error" in coldata) {
                        setLoading(false);
                        setPageError(true);
                        setError(coldata.Error);
                      } else {
                        var col_array = [];
                        col_array.push({ id: "", name: "none", label: "None" });
                        for (var c = 0; c < coldata.columnRecords.length; c++) {
                          col_array.push(coldata.columnRecords[c]);
                        }
                        var choicearry = [];
                        for (var z = 0; z < coldata.columnRecords.length; z++) {
                          choicearry.push({
                            id: coldata.columnRecords[z].id,
                            name: coldata.columnRecords[z].name,
                            label: coldata.columnRecords[z].label,
                            value: coldata.columnRecords[z].name,
                            choice_order: z * 100 + "",
                            form_column_id: "",
                          });
                        }
                        setColumn_depend(choicearry);
                      }
                    }
                  },
                  (error) => {
                    // setLoading(false)
                    let err = {
                      message: error.message,
                      code: error.response.status,
                    };
                    navigation("/error", { state: { err: err } });
                  }
                );
            }
            for (let i = 0; i < rcd.length; i++) {
              rcd[i].index = i;
            }

            rcd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );
            let rd = [];
            let rdd = [];
            let left_rd = [];
            let left_check = false;
            let downRcd = [];
            for (let i = 0; i < rcd.length; i++) {
              if (rcd[i].type === "activity") {
                downRcd.push(rcd[i]);
              } else if (rcd[i].formView.position === "left") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i + 1 < rcd.length &&
                  rcd[i + 1].formView.position === "full"
                ) {
                  left_check = true;
                }
              } else if (rcd[i].formView.position === "right") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i === rcd.length - 1 ||
                  rcd[i + 1].formView.position === "full"
                ) {
                  rdd.push({
                    split: "true",
                    rcd: rd,
                    formView: { co: rd[0].formView.co },
                  });
                  for (let j = 0; j < left_rd.length; j++) {
                    rdd.push(left_rd[j]);
                  }
                  rd = [];
                  left_check = false;
                  left_rd = [];
                }
              } else {
                if (left_check) {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  left_rd.push(rcd[i]);
                } else {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  rdd.push(rcd[i]);
                }
              }
            }
            for (let i = 0; i < rdd.length; i++) {
              if (rdd[i].split === "true") {
                rdd[i].rcd.sort((a, b) =>
                  parseInt(a.formView.pn) > parseInt(b.formView.pn)
                    ? 1
                    : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                    ? -1
                    : 0
                );
              }
            }
            rdd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );
            rcd.sort((a, b) =>
              parseInt(a.index) > parseInt(b.index)
                ? 1
                : parseInt(a.index) < parseInt(b.index)
                ? -1
                : 0
            );
            setInfoRcdView([...rdd]);

            setShowModelInfo(!showModelInfo);
          },
          (error) => {
            let err = { message: error.message, code: error.response.status };
            navigation("/error", { state: { err: err } });
          }
        );
    } else {
      toast.error("No Record");
    }
  };

  const dashBoardForm = () => {
    tableName.current = dash_tabname;
    rty.current = dash_rty;
    rid.current = dash_rid;
    isSub.current = true;
    getScriptList();
  };

  const checkAccess = () => {
    let tabname = searchParam.get("tableName");
    let listCheck = listAccess.current.find((e) => e === tabname);
    let newCheck = newAccess.current.find((e) => e === tabname);
    if (newCheck || listCheck) {
      tableName.current = tabname;
      rty.current = searchParam.get("rty");
      if (rty.current === "new" && newCheck) {
        return true;
      } else if (rty.current === "record" && (listCheck || newCheck)) {
        rid.current = searchParam.get("rid");
        return true;
      }
    }
    return false;
  };

  const invoice = (url) => {
    axios
      .get(loca + `/marketplace/get/invoice/${rid.current}`, {
        headers: {
          // "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          setInvRcd(record);
          // navigation(
          //   url.toString(), { state: { inv: record, rcd: rcdView[1].value } }
          // );
        },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          navigation("/error", { state: { err: err } });
        }
      );
  };

  const getScriptList = () => {
    let isAccessable = true;
    if (!isSub.current) {
      isAccessable = checkAccess();
    }
    if (isAccessable) {
      axios
        .get(loca + "/marketplace/get/script", {
          headers: { authorization: "Bearer " + token },
        })
        .then(
          (resp) => {
            let res = resp.data;
            setReScript(res.reusableScript);
            setLoading(true);
            getFormDetails();
          },
          (error) => {
            let err = { message: error.message, code: error.response.status };
            navigation("/error", { state: { err: err } });
          }
        );
    } else {
      setLoading(false);
      toast(
        "Incorrect search query or you don't have permission to access this",
        { pauseOnHover: true, theme: "colored", type: "error" }
      );
    }
  };
  const getInitial = async () => {
    let res = await axios
      .get(loca + "/marketplace/get/updatejson", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let rec = res.record;
          // let arr=[]
          let curr = undefined;
          for (let i = 0; i < rec.length; i++) {
            if (rec[i].selected === "true") {
              curr = rec[i];
              return curr;
            }
            // else{
            //   arr.push(rec[i])
            // }
          }
          // setCurrentRec(curr)
          // setUnselectedRec([...arr])
          // setUpdateJsonRec(res.record);
        },
        (error) => {
          return undefined;
        }
      );
    return res;
  };

  const hasError = (data) => {
    for (const key in data) {
      if (Array.isArray(data[key])) {
        for (const record of data[key]) {
          if (record.record && record.record.Error) {
            return { error: true, msg: record.record.Error }; // Error found
          }
        }
      }
    }
    return { error: false, msg: "" }; // No errors found
  };
  const getFormDetails = async (bool) => {
    let updatedJSON = await getInitial();
    // setLoading(true);
    let jj = [];
    if (bool !== true) {
      setCol_mn(jj);
      setError("");
      setPageError(false);
      setMessage("");
      setPageMessage(false);
    }
    if (rty.current === "new") {
      axios
        .get(`${loca}/marketplace/get/blankrecord/${tableName.current}`, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": "marketplace",
          },
          // setViewList,
        })
        .then(
          (resp) => {
            // setRelationload(false);
            const blkrecord = resp.data;
            let ref_tab_name = "";
            const err = hasError(blkrecord);
            if (Object.values(blkrecord).includes("Error")) {
              navigation("/dashboard", { state: { error: blkrecord.Error } });
            } else if (err.error) {
              setError(err.msg);
              setPageError(true);
              setRecord([]);
              setLoading(false);
            } else {
              // setMainRecord(blkrecord);
              let view_list = [];
              if ("appRecord" in blkrecord) {
                setCopyRcd(blkrecord.appRecord[2].record);
                setRecord(blkrecord.appRecord[2].record);
                setButton(blkrecord.appRecord[3].button);
                setTabLabel(blkrecord.appRecord[1].table.label);
                setTabId(blkrecord.appRecord[1].table.id);
                i_d.current = blkrecord.appRecord[1].table.id;
                view_list = blkrecord?.appRecord[7]?.view;
              } else if ("columnRecord" in blkrecord) {
                setCopyRcd(blkrecord.columnRecord[2].record);
                setRecord(blkrecord.columnRecord[2].record);
                setButton(blkrecord.columnRecord[3].button);
                setTabLabel(blkrecord.columnRecord[1].table.label);
                setTabId(blkrecord.columnRecord[1].table.id);
                i_d.current = blkrecord.columnRecord[1].table.id;
                view_list = blkrecord?.columnRecord[7]?.view;
              } else if ("formRecord" in blkrecord) {
                setCopyRcd(blkrecord.formRecord[2].record);
                setRecord(blkrecord.formRecord[2].record);
                setButton(blkrecord.formRecord[3].button);
                setTabLabel(blkrecord.formRecord[1].table.label);
                setTabId(blkrecord.formRecord[1].table.id);
                i_d.current = blkrecord.formRecord[1].table.id;
                view_list = blkrecord?.formRecord[7]?.view;
              } else if ("choiceRecord" in blkrecord) {
                setCopyRcd(blkrecord.choiceRecord[2].record);
                setRecord(blkrecord.choiceRecord[2].record);
                setButton(blkrecord.choiceRecord[3].button);
                setTabLabel(blkrecord.choiceRecord[1].table.label);
                setTabId(blkrecord.choiceRecord[1].table.id);
                i_d.current = blkrecord.choiceRecord[1].table.id;
                view_list = blkrecord?.choiceRecord[7]?.view;
              } else if ("labelRecord" in blkrecord) {
                setCopyRcd(blkrecord.labelRecord[2].record);
                setRecord(blkrecord.labelRecord[2].record);
                setButton(blkrecord.labelRecord[3].button);
                setTabLabel(blkrecord.labelRecord[1].table.label);
                setTabId(blkrecord.labelRecord[1].table.id);
                i_d.current = blkrecord.labelRecord[1].table.id;
                view_list = blkrecord?.labelRecord[7]?.view;
              } else if ("buttonRecord" in blkrecord) {
                setCopyRcd(blkrecord.buttonRecord[2].record);
                setRecord(blkrecord.buttonRecord[2].record);
                setButton(blkrecord.buttonRecord[3].button);
                setTabLabel(blkrecord.buttonRecord[1].table.label);
                setTabId(blkrecord.buttonRecord[1].table.id);
                i_d.current = blkrecord.buttonRecord[1].table.id;
                view_list = blkrecord?.buttonRecord[7]?.view;
              } else if ("tableRecord" in blkrecord) {
                setCopyRcd(blkrecord.tableRecord[2].record);
                setRecord(blkrecord.tableRecord[2].record);
                setButton(blkrecord.tableRecord[3].button);
                setTabLabel(blkrecord.tableRecord[1].table.label);
                setTabId(blkrecord.tableRecord[1].table.id);
                i_d.current = blkrecord.tableRecord[1].table.id;
                view_list = blkrecord?.tableRecord[7]?.view;
              } else if ("typeRecord" in blkrecord) {
                setCopyRcd(blkrecord.typeRecord[2].record);
                setRecord(blkrecord.typeRecord[2].record);
                setButton(blkrecord.typeRecord[3].button);
                setTabLabel(blkrecord.typeRecord[1].table.label);
                setTabId(blkrecord.typeRecord[1].table.id);
                i_d.current = blkrecord.typeRecord[1].table.id;
                view_list = blkrecord?.typeRecord[7]?.view;
              } else if ("uivalidationRecord" in blkrecord) {
                setCopyRcd(blkrecord.uivalidationRecord[2].record);
                setRecord(blkrecord.uivalidationRecord[2].record);
                setButton(blkrecord.uivalidationRecord[3].button);
                setTabLabel(blkrecord.uivalidationRecord[1].table.label);
                setTabId(blkrecord.uivalidationRecord[1].table.id);
                i_d.current = blkrecord.uivalidationRecord[1].table.id;
                view_list = blkrecord?.uivalidationRecord[7]?.view;
              } else if ("languageRecord" in blkrecord) {
                setCopyRcd(blkrecord.languageRecord[2].record);
                setRecord(blkrecord.languageRecord[2].record);
                setButton(blkrecord.languageRecord[3].button);
                setTabLabel(blkrecord.languageRecord[1].table.label);
                setTabId(blkrecord.languageRecord[1].table.id);
                i_d.current = blkrecord.languageRecord[1].table.id;
                view_list = blkrecord?.languageRecord[7]?.view;
              } else if ("userRecord" in blkrecord) {
                setCopyRcd(blkrecord.userRecord[2].record);
                setRecord(blkrecord.userRecord[2].record);
                setButton(blkrecord.userRecord[3].button);
                setTabLabel(blkrecord.userRecord[1].table.label);
                setTabId(blkrecord.userRecord[1].table.id);
                i_d.current = blkrecord.userRecord[1].table.id;
                view_list = blkrecord?.userRecord[7]?.view;
              } else {
                console.log("table record not found");
              }
              setViewList(view_list);
              let script = null;
              let policy = [];
              let rcd = [];
              if ("formRecord" in blkrecord) {
                script = blkrecord.formRecord[4].uiscript;
                rcd = blkrecord.formRecord[2].record;
                policy = blkrecord.formRecord[5].uipolicy;
                setCid(blkrecord.formRecord[6].client.cid);
              } else if ("appRecord" in blkrecord) {
                script = blkrecord.appRecord[4].uiscript;
                policy = blkrecord.appRecord[5].uipolicy;
                rcd = blkrecord.appRecord[2].record;
                setCid(blkrecord.appRecord[6].client.cid);
              } else if ("tableRecord" in blkrecord) {
                script = blkrecord.tableRecord[4].uiscript;
                policy = blkrecord.tableRecord[5].uipolicy;
                rcd = blkrecord.tableRecord[2].record;
                setCid(blkrecord.tableRecord[6].client.cid);
              } else if ("typeRecord" in blkrecord) {
                script = blkrecord.typeRecord[4].uiscript;
                policy = blkrecord.typeRecord[5].uipolicy;
                rcd = blkrecord.typeRecord[2].record;
                setCid(blkrecord.typeRecord[6].client.cid);
              } else if ("uivalidationRecord" in blkrecord) {
                script = blkrecord.uivalidationRecord[4].uiscript;
                policy = blkrecord.uivalidationRecord[5].uipolicy;
                rcd = blkrecord.uivalidationRecord[2].record;
                setCid(blkrecord.uivalidationRecord[6].client.cid);
              } else if ("buttonRecord" in blkrecord) {
                script = blkrecord.buttonRecord[4].uiscript;
                policy = blkrecord.buttonRecord[5].uipolicy;
                rcd = blkrecord.buttonRecord[2].record;
                setCid(blkrecord.buttonRecord[6].client.cid);
              } else if ("choiceRecord" in blkrecord) {
                script = blkrecord.choiceRecord[4].uiscript;
                rcd = blkrecord.choiceRecord[2].record;
                policy = blkrecord.choiceRecord[5].uipolicy;
                setCid(blkrecord.choiceRecord[6].client.cid);
              } else if ("languageRecord" in blkrecord) {
                script = blkrecord.languageRecord[4].uiscript;
                rcd = blkrecord.languageRecord[2].record;
                policy = blkrecord.languageRecord[5].uipolicy;
                setCid(blkrecord.languageRecord[6].client.cid);
              } else if ("columnRecord" in blkrecord) {
                script = blkrecord.columnRecord[4].uiscript;
                rcd = blkrecord.columnRecord[2].record;
                policy = blkrecord.columnRecord[5].uipolicy;
                setCid(blkrecord.columnRecord[6].client.cid);
              } else if ("labelRecord" in blkrecord) {
                script = blkrecord.labelRecord[4].uiscript;
                rcd = blkrecord.labelRecord[2].record;
                policy = blkrecord.labelRecord[5].uipolicy;
                setCid(blkrecord.labelRecord[6].client.cid);
              } else if ("userRecord" in blkrecord) {
                script = blkrecord.userRecord[4].uiscript;
                policy = blkrecord.userRecord[5].uipolicy;
                rcd = blkrecord.userRecord[2].record;
                setCid(blkrecord.userRecord[6].client.cid);
              }
              // onload
              let userDetails = JSON.parse(localStorage.getItem("userDetails"));
              if (script !== null) {
                for (let i = 0; i < script.length; i++) {
                  // let field = script[i].field.name;
                  let func = script[i].script;
                  let type = script[i].type;
                  // if (type === "onload" && count.current === 1) {
                  if (type === "onload") {
                    // count.current = 0;
                    onLoad(
                      func,
                      new ApUser(userDetails, setRecord),
                      new ApForm(rcd, setRecord, tabRelation, setTabRelation)
                    );
                  }
                }
              }

              let StartIndex = 0;
              let mapObj = new Map();
              let ref_cols = [];
              for (let i = 0; i < rcd.length; i++) {
                rcd[i].index = i;
                if (
                  rcd[i].type === "reference" &&
                  rcd[i].name === "form_table_id"
                ) {
                  ref_cols.push(rcd[i]);
                }
                for (let k = 0; k < fieldName.length; k++) {
                  if (rcd[i].name === fieldName[k]) {
                    let ref = { id: 0, name: "" };
                    for (let j = 0; j < recordNew.length; j++) {
                      if (k > 0 && recordNew[j].name === fieldName[k]) {
                        ref = recordNew[j].value;
                      } else if (k === 0) {
                        if (recordNew[j].name === "id") {
                          ref.id = recordNew[j].value;
                        }
                        if (recordNew[j].displayColumn === "true") {
                          ref.name = recordNew[j].value;
                        }
                      }
                    }
                    rcd[i].value = ref;
                  }
                }

                if (rcd[i]?.secondary === "true" && StartIndex === 0) {
                  setSecondaryStartIndex(i);
                  StartIndex++;
                }

                // If addNone check box is not selected in form_choice then the field will not be mandatory
                if (rcd[i]?.type === "choice") {
                  if (
                    rcd[i]?.choice[0]?.name !== "none" &&
                    rcd[i]?.choice[0]?.name !== ""
                  ) {
                    rcd[i].uivalid.mandatory = "false";
                  }
                }

                if (rcd[i]?.secondary === "true") {
                  let fv = rcd[i]?.formView;
                  let col = fv.path.split(".")[1];
                  if (mapObj.get(col)) {
                    mapObj.get(col).push(fv);
                  } else {
                    let vl = [];
                    vl.push(fv);
                    mapObj.set(col, vl);
                  }
                }
              }

              setSecondarycallsCols(mapObj);
              rcd.sort((a, b) =>
                parseInt(a.formView.pn) > parseInt(b.formView.pn)
                  ? 1
                  : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                  ? -1
                  : 0
              );
              let rd = [];
              let rdd = [];
              let left_rd = [];
              let downRcd = [];
              let left_check = false;
              for (let i = 0; i < rcd.length; i++) {
                if (rcd[i].type === "table_insert") {
                  var clr = [
                    { name: "Total", value: "Total" },
                    { name: "", value: "" },
                    { name: "Total Quantity", value: "" },
                    { name: "", value: "" },
                    { name: "", value: "" },
                    { name: "Total Amount", value: "" },
                  ];
                  rcd[i].value.record.push({ colr: clr });
                  var clr1 = [
                    { name: "Total", value: "Total" },
                    { name: "Taxable Value", value: "" },
                    { name: "", value: "" },
                    { name: "Tax Amount", value: "" },
                  ];
                  rcd[i].value.record.push({ colr: clr1 });
                }
                if (rcd[i].type === "filter") {
                  //  rcd[i].value = keyValueJson;
                  deftimeline.current = rcd[i].value.timeLine;
                }
                if (rcd[i].type === "key_value") {
                  rcd[i].selected = false;
                  rcd[i].value = "";
                }
                if (rcd[i].type === "activity") {
                  downRcd.push(rcd[i]);
                } else if (rcd[i].formView.position === "left") {
                  // rcd[i].index = i;
                  // if (rcd[i].uivalid.visible=== "true") {
                  rd.push(rcd[i]);
                  // }
                  if (
                    i + 1 < rcd.length &&
                    rcd[i + 1].formView.position === "full"
                  ) {
                    rdd.push({
                      split: "true",
                      rcd: rd,
                      formView: {
                        co: rd[0].formView.co,
                        pn: rd[0].formView.pn,
                      },
                    });
                    left_rd = [];
                    left_check = true;
                    rd = [];
                  }
                } else if (rcd[i].formView.position === "right") {
                  rd.push(rcd[i]);
                  if (
                    i === rcd.length - 1 ||
                    rcd[i + 1].formView.position === "full"
                  ) {
                    rdd.push({
                      split: "true",
                      rcd: rd,
                      formView: {
                        co: rd[0].formView.co,
                        pn: rd[0].formView.pn,
                      },
                    });
                    rd = [];
                    left_check = false;
                    left_rd = [];
                  }
                } else {
                  if (left_check) {
                    rcd[i].split = "false";
                    rdd.push(rcd[i]);
                  } else {
                    rcd[i].split = "false";
                    rdd.push(rcd[i]);
                  }
                }
              }
              for (let i = 0; i < rdd.length; i++) {
                if (rdd[i].split === "true") {
                  rdd[i].rcd.sort((a, b) =>
                    parseInt(a.formView.pn) > parseInt(b.formView.pn)
                      ? 1
                      : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                      ? -1
                      : 0
                  );
                }
              }
              rdd.sort((a, b) =>
                parseInt(a.formView.pn) > parseInt(b.formView.pn)
                  ? 1
                  : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                  ? -1
                  : 0
              );
              rcd.sort((a, b) =>
                parseInt(a.index) > parseInt(b.index)
                  ? 1
                  : parseInt(a.index) < parseInt(b.index)
                  ? -1
                  : 0
              );

              setPolicyFilter(policy.ui_policy);
              setPolicyAction(policy.policy_action);
              setMainRecord(blkrecord);
              setUiScript([...script]);
              uScript.current = script;

              rcrd.current = rcd;
              setRcdView(rdd);
              setDownRcdView(downRcd);

              setap_form(
                new ApForm(rcd, setRecord, tabRelation, setTabRelation)
              );
              setap_user(new ApUser(userDetails, setRecord));

              // on load ui policy
              let selectedId = "";
              for (let t = 0; t < view_list?.length; t++) {
                if (view_list[t].selected === "true")
                  selectedId = view_list[t].id;
              }
              for (let s = 0; s < policy?.ui_policy?.length; s++) {
                let pf = policy.ui_policy[s].filter;
                let pfId = policy.ui_policy[s].id;
                let global = policy.ui_policy[s].global;
                let form_view_id = policy.ui_policy[s].form_view_id;
                let onload = policy.ui_policy[s].onload;
                if (
                  pf !== undefined &&
                  pf !== "" &&
                  (global === "true" || selectedId === form_view_id) &&
                  onload === "true"
                ) {
                  var pfilt = pf.filter;
                  for (let i = 0; i < pfilt.length; i++) {
                    for (let t = 0; t < policy.policy_action.length; t++) {
                      if (pfId === policy.policy_action[t].policy_id) {
                        let valueMatch = false;
                        let colMatch = false;
                        let rcdIndex;
                        for (let j = 0; j < rcd.length; j++) {
                          if (
                            policy.policy_action[t].form_column_id == rcd[j].id
                          ) {
                            colMatch = true;
                            rcdIndex = j;
                          }
                          if (pfilt[i].co == rcd[j].name) {
                            if (pfilt[i].ct === "reference") {
                              if (
                                // pfilt[i].co == "" ||
                                pfilt[i].rf.value == rcd[j].value.name
                              ) {
                                valueMatch = true;
                              }
                            } else {
                              if (
                                // pfilt[i].co == "" ||
                                // pfilt[i].an == "" ||
                                pfilt[i].an == rcd[j].value
                              ) {
                                valueMatch = true;
                              }
                            }
                          }
                        }
                        if (colMatch && valueMatch) {
                          if (
                            policy.policy_action[t].visible != "null" &&
                            policy.policy_action[t].visible != "leave"
                          ) {
                            rcd[rcdIndex].uivalid.visible =
                              policy.policy_action[t].visible;
                          }
                          if (
                            policy.policy_action[t].read_only != "null" &&
                            policy.policy_action[t].read_only != "leave"
                          ) {
                            rcd[rcdIndex].uivalid.read_only =
                              policy.policy_action[t].read_only;
                          }
                          if (
                            policy.policy_action[t].mandatory != "null" &&
                            policy.policy_action[t].mandatory != "leave"
                          ) {
                            rcd[rcdIndex].uivalid.mandatory =
                              policy.policy_action[t].mandatory;
                          }
                        }
                      }
                    }
                  }
                }
              }
              // let rccd=JSON.parse(JSON.stringify(rcd))
              setRecord(rcd);
              setAll_ref_cols(ref_cols);
              checkRefrecord();

              // setTimeout(() => {
              setFormChange(!formChange);
              // }, 5000);
            }
          },
          (error) => {
            let err = { message: error.message, code: error.response.status };
            navigation("/error", { state: { err: err } });
          }
        );
    } else if (rty.current === "record") {
      axios
        .get(
          `${loca}/marketplace/get/singlerecord/${tableName.current}/${rid.current}`,
          {
            headers: {
              // "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application": "marketplace",
            },
          }
        )
        .then(
          (resp) => {
            const record = resp.data;
            let script = null;
            let rcd = [];
            let policy = [];
            let view_list = [];
            if ("Error" in record) {
              // navigation("/dashboard");
              toast.error(record.Error);
            } else {
              setMainRecord(record);

              if ("appRecord" in record) {
                rcd = record.appRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.appRecord[3].button);
                setTabLabel(record.appRecord[1].table.label);
                setTabId(record.appRecord[1].table.id);
                setCid(record.appRecord[6].client.cid);
                setViewList(record.appRecord[8]?.view);
                setActivity(record.appRecord[9]?.activity);
              } else if ("columnRecord" in record) {
                rcd = record.columnRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.columnRecord[3].button);
                setTabLabel(record.columnRecord[1].table.label);
                setTabId(record.columnRecord[1].table.id);
                setCid(record.columnRecord[6].client.cid);
                setViewList(record.columnRecord[8]?.view);
                setActivity(record.columnRecord[9]?.activity);
              } else if ("formRecord" in record) {
                rcd = record.formRecord[2].record;
                script = record.formRecord[6].uiscript;
                setButton(record.formRecord[3].button);
                setTabLabel(record.formRecord[1].table.label);
                setTabId(record.formRecord[1].table.id);
                setCid(record.formRecord[7].client.cid);
                setViewList(record.formRecord[9]?.view);
                setActivity(record.formRecord[10]?.activity);
                policy = record.formRecord[4].uipolicy;
                view_list = record.formRecord[9]?.view;
              } else if ("choiceRecord" in record) {
                rcd = record.choiceRecord[2].record;
                script = record.formRecord[6].uiscript;
                setButton(record.choiceRecord[3].button);
                setTabLabel(record.choiceRecord[1].table.label);
                setTabId(record.choiceRecord[1].table.id);
                setCid(record.choiceRecord[7].client.cid);
                setViewList(record.choiceRecord[9]?.view);
                setActivity(record.choiceRecord[10]?.activity);
              } else if ("labelRecord" in record) {
                rcd = record.labelRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.labelRecord[3].button);
                setTabLabel(record.labelRecord[1].table.label);
                setTabId(record.labelRecord[1].table.id);
                setCid(record.labelRecord[6].client.cid);
                setViewList(record.labelRecord[8]?.view);
                setActivity(record.labelRecord[9]?.activity);
              } else if ("buttonRecord" in record) {
                rcd = record.buttonRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.buttonRecord[3].button);
                setTabLabel(record.buttonRecord[1].table.label);
                setTabId(record.buttonRecord[1].table.id);
                setCid(record.buttonRecord[6].client.cid);
                setViewList(record.buttonRecord[8]?.view);
                setActivity(record.buttonRecord[9]?.activity);
              } else if ("tableRecord" in record) {
                rcd = record.tableRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.tableRecord[3].button);
                setTabLabel(record.tableRecord[1].table.label);
                setTabId(record.tableRecord[1].table.id);
                setCid(record.tableRecord[6].client.cid);
                setViewList(record.tableRecord[8]?.view);
                setActivity(record.tableRecord[9]?.activity);
              } else if ("typeRecord" in record) {
                rcd = record.typeRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.typeRecord[3].button);
                setTabLabel(record.typeRecord[1].table.label);
                setTabId(record.typeRecord[1].table.id);
                setCid(record.typeRecord[6].client.cid);
                setViewList(record.typeRecord[8]?.view);
                setActivity(record.typeRecord[9]?.activity);
              } else if ("uivalidationRecord" in record) {
                rcd = record.uivalidationRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.uivalidationRecord[3].button);
                setTabLabel(record.uivalidationRecord[1].table.label);
                setTabId(record.uivalidationRecord[1].table.id);
                setCid(record.uivalidationRecord[6].client.cid);
                setViewList(record.uivalidationRecord[8]?.view);
                setActivity(record.uivalidationRecord[9]?.activity);
              } else if ("languageRecord" in record) {
                rcd = record.languageRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.languageRecord[3].button);
                setTabLabel(record.languageRecord[1].table.label);
                setTabId(record.languageRecord[1].table.id);
                setCid(record.languageRecord[6].client.cid);
                setViewList(record.languageRecord[8]?.view);
                setActivity(record.languageRecord[9]?.activity);
              } else if ("userRecord" in record) {
                rcd = record.userRecord[2].record;
                script = record.userRecord[6].uiscript;
                setButton(record.userRecord[3].button);
                setTabLabel(record.userRecord[1].table.label);
                setTabId(record.userRecord[1].table.id);
                setCid(record.userRecord[7].client.cid);
                setViewList(record.userRecord[9]?.view);
                setActivity(record.userRecord[10]?.activity);
                policy = record.userRecord[4]?.uipolicy;
                view_list = record.userRecord[9]?.view;
              } else {
                console.log("table record not found");
              }
            }

            var mmm;
            if ("userRecord" in record) mmm = record.userRecord[2].record;
            else mmm = record.formRecord[2].record;
            setCopyRcd(mmm);
            let b = mmm.findIndex(
              (e) =>
                e.depend_column !== "" &&
                e.type === "filter" &&
                e.type === "multi_select"
            );
            if (b >= 0) {
              var col_array = [];
              col_array.push({ id: "", name: "none", label: "None" });
              for (var c = 0; c < mmm[b].dep_choice.length; c++) {
                col_array.push({
                  id: mmm[b].dep_choice[c].id,
                  name: mmm[b].dep_choice[c].name,
                  label: mmm[b].dep_choice[c].label,
                });
              }
              setCol_mn(col_array);
            }
            let rValue = "";
            let ref_cols = [];
            // this will set first one as display Name
            let displayNameFixed = false;
            for (var i = 0; i < mmm.length; i++) {
              if (mmm[i].type === "reference") {
                ref_cols.push(mmm[i]);
              }

              // If addNone check box is not selected in form_choice then the field will not be mandatory
              if (mmm[i]?.type === "choice") {
                if (
                  mmm[i]?.choice[0]?.name !== "none" &&
                  mmm[i]?.choice[0]?.name !== ""
                ) {
                  mmm[i].uivalid.mandatory = "false";
                }
              }

              if (mmm[i].name === "uni_id") {
                i_d.current = mmm[i].value;
              }

              if (mmm[i].name === "name") {
                let jso = {};
                let id = 0;
                for (let tmp = 0; tmp < mmm.length; tmp++) {
                  if (mmm[tmp].name === "uni_id") {
                    id = mmm[tmp].value;
                    break;
                  }
                }
                let fake_jso = JSON.parse(JSON.stringify(mmm[i]));
                fake_jso.name = tableName.current + "_id";
                jso.id = id;
                jso.name = mmm[i].value;
                fake_jso.value = jso;
                ref_cols.push(fake_jso);
              }
              if (mmm[i].value !== "") {
                mmm[i].verified = "verified";
              } else {
                mmm[i].verified = "initial";
              }
              if (mmm[i].type === "datetime") {
                // showcal.push({ value: false });
                // showCalender.push({ index: i, value: false });
              }
              if (mmm[i].type === "password") {
                mmm[i].value = "";
              }
              if (mmm[i].displayColumn === "true" && !displayNameFixed) {
                displayNameFixed = true;
                rValue = mmm[i].value;
              }
              if (mmm[i].type === "key_value") {
                mmm[i].hidden_val = JSON.parse(JSON.stringify(mmm[i].value));
                for (let j = 0; j < mmm.length; j++) {
                  if (
                    mmm[j].type === "reference" &&
                    mmm[j].col_types.includes("group_key_value") &&
                    mmm[j].refTable.value === mmm[i].for_table
                  ) {
                    mmm[i].value = mmm[j].value.name;
                    mmm[i].selected = true;
                    mmm[i].uivalid.mandatory = false;
                    break;
                  }
                }
              }
            }
            refValue.current = rValue;

            let count1 = 0;
            let call_check = true;
            let StartIndex = 0;
            let mapObj = new Map();

            for (let i = 0; i < mmm.length; i++) {
              if (
                mmm[i].type === "filter" &&
                mmm[i].value !== "" &&
                mmm[i].value.filter
              ) {
                var filter = mmm[i].value.filter;
                setDcFiltVal(filter[0]?.dc.label);
                setFiltArray(mmm[i].value);
                setTimeline(mmm[i].value.timeline);
                deftimeline.current = mmm[i].value.timeline;
                count1++;
              }
              if (mmm[i].type === "filter_script" && mmm[i].value !== "") {
                setFiltArray(mmm[i].value);
                count1++;
              }
              if (mmm[i].type === "filter_ref" && mmm[i].value !== "") {
                setFiltRefArray(mmm[i].value);
                count1++;
              }
              if (mmm[i].name === "form_table_id") {
                callFieldList(mmm[i].value.id, true);
                // call_check = false;
                count1++;
              }
              if (mmm[i].type === "multi_select") {
                let parsVal = mmm[i].value.record;
                setMscList(parsVal);
                count1++;
              }
              if (count1 === 5) {
                break;
              }

              if (mmm[i]?.secondary === "true" && StartIndex === 0) {
                setSecondaryStartIndex(i);
                StartIndex++;
              }
              if (mmm[i]?.secondary === "true") {
                let fv = mmm[i]?.formView;
                let col = fv.path.split(".")[1];
                if (mapObj.get(col)) {
                  mapObj.get(col).push(fv);
                } else {
                  let vl = [];
                  vl.push(fv);
                  mapObj.set(col, vl);
                }
              }
              if (tableName.current === "reference_filter") {
                if (mmm[i].name === "form_table_id") {
                  callTableColumn(
                    mmm[i].value.name,
                    false,
                    mmm[i].depend_column,
                    true,
                    { load: true, record: mmm }
                  );
                }
              }
            }

            setSecondarycallsCols(mapObj);
            for (let i = 0; i < rcd.length; i++) {
              rcd[i].index = i;
            }

            rcd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );

            let rd = [];
            let rdd = [];
            let left_rd = [];
            let left_check = false;
            let downRcd = [];
            for (let i = 0; i < rcd.length; i++) {
              if (rcd[i].type === "activity") {
                downRcd.push(rcd[i]);
              }
              if (rcd[i].formView.position === "left") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i + 1 < rcd.length &&
                  rcd[i + 1].formView.position === "full"
                ) {
                  left_check = true;
                }
              } else if (rcd[i].formView.position === "right") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i === rcd.length - 1 ||
                  rcd[i + 1].formView.position === "full"
                ) {
                  rdd.push({
                    split: "true",
                    rcd: rd,
                    formView: { co: rd[0].formView.co },
                  });
                  for (let j = 0; j < left_rd.length; j++) {
                    rdd.push(left_rd[j]);
                  }
                  rd = [];
                  left_check = false;
                  left_rd = [];
                }
              } else {
                if (left_check) {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  left_rd.push(rcd[i]);
                } else {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  rdd.push(rcd[i]);
                }
              }
            }
            for (let i = 0; i < rdd.length; i++) {
              if (rdd[i].split === "true") {
                rdd[i].rcd.sort((a, b) =>
                  parseInt(a.formView.pn) > parseInt(b.formView.pn)
                    ? 1
                    : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                    ? -1
                    : 0
                );
              }
            }
            rdd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );
            rcd.sort((a, b) =>
              parseInt(a.index) > parseInt(b.index)
                ? 1
                : parseInt(a.index) < parseInt(b.index)
                ? -1
                : 0
            );
            // if (true) {
            for (let p = 0; p < rcd.length; p++) {
              if (rcd[p].name === "uni_id") {
                if (
                  tableName.current === "updatejson" &&
                  rcd[p].value === updatedJSON?.id
                ) {
                  setSelectedJson(true);
                } else {
                  setSelectedJson(false);
                }
                break;
              }
            }
            // }

            // on load ui policy
            let selectedId = "";
            for (let t = 0; t < view_list?.length; t++) {
              if (view_list[t].selected === "true")
                selectedId = view_list[t].id;
            }
            for (let s = 0; s < policy?.ui_policy?.length; s++) {
              let pf = policy.ui_policy[s].filter;
              let pfId = policy.ui_policy[s].id;
              let global = policy.ui_policy[s].global;
              let form_view_id = policy.ui_policy[s].form_view_id;
              let onload = policy.ui_policy[s].onload;
              if (
                pf !== undefined &&
                pf !== "" &&
                (global === "true" || selectedId === form_view_id) &&
                onload === "true"
              ) {
                var pfilt = pf.filter;
                for (let i = 0; i < pfilt.length; i++) {
                  for (let t = 0; t < policy.policy_action.length; t++) {
                    if (pfId === policy.policy_action[t].policy_id) {
                      let valueMatch = false;
                      let colMatch = false;
                      let rcdIndex;
                      for (let j = 0; j < rcd.length; j++) {
                        if (
                          policy.policy_action[t].form_column_id == rcd[j].id
                        ) {
                          colMatch = true;
                          rcdIndex = j;
                        }
                        if (pfilt[i].co == rcd[j].name) {
                          if (pfilt[i].ct === "reference") {
                            if (
                              // pfilt[i].co == "" ||
                              pfilt[i].rf.value == rcd[j].value.name
                            ) {
                              valueMatch = true;
                            }
                          } else {
                            if (
                              // pfilt[i].co == "" ||
                              // pfilt[i].an == "" ||
                              pfilt[i].an == rcd[j].value
                            ) {
                              valueMatch = true;
                            }
                          }
                        }
                      }
                      if (colMatch && valueMatch) {
                        if (
                          policy.policy_action[t].visible != "null" &&
                          policy.policy_action[t].visible != "leave"
                        ) {
                          rcd[rcdIndex].uivalid.visible =
                            policy.policy_action[t].visible;
                        }
                        if (
                          policy.policy_action[t].read_only != "null" &&
                          policy.policy_action[t].read_only != "leave"
                        ) {
                          rcd[rcdIndex].uivalid.read_only =
                            policy.policy_action[t].read_only;
                        }
                        if (
                          policy.policy_action[t].mandatory != "null" &&
                          policy.policy_action[t].mandatory != "leave"
                        ) {
                          rcd[rcdIndex].uivalid.mandatory =
                            policy.policy_action[t].mandatory;
                        }
                      }
                    }
                  }
                }
              }
            }

            setAll_ref_cols(ref_cols);
            setRecord(rcd);
            rcrd.current = rcd;
            setRcdView([...rdd]);
            setDownRcdView(downRcd);
            setPolicyFilter(policy?.ui_policy);
            setPolicyAction(policy?.policy_action);
            setMainRecord(record);
            setUiScript(script);
            setap_form(new ApForm(rcd, setRecord, tabRelation, setTabRelation));
            setap_user(new ApUser(userDetails, setRecord));
            uScript.current = script;
            if (call_check) {
              var chk = false;
              for (let i = 0; i < script?.length; i++) {
                let type = script[i].type;
                if (type === "onload") {
                  chk = true;
                  count.current = 1;
                }
              }
              if (!chk) {
                setLoading(false);
              }

              relationVerify();
            } else {
              checkRefrecord();
            }
          },
          (error) => {
            let err = { message: error.message, code: error.response.status };
            navigation("/error", { state: { err: err } });
          }
        );
    }
  };

  // functions for model list

  const setRef = (id, val, properties, refTable) => {
    // setKeyValueJson(properties);
    formChangefn(
      val,
      objIndex,
      objName,
      objType,
      id,
      true,
      refTable,
      "",
      "",
      "",
      properties,
      depCol
    );
    cancelModelList();
  };

  const setReference = () => {
    for (let i = 0; i < uiScript.length; i++) {
      // let field = uiScript[i].field.name;
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      if (type === "onreference" && ref_filter[0].co === "") {
        onReference(func);
      }
    }
  };

  const cancelModelList = () => {
    setshowModelList(false);
  };

  const afterCellEdit = (vl, ob) => {
    for (let i = 0; i < uiScript.length; i++) {
      let field = uiScript[i].field.name;
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      if (field === ob && type === "oncelledit") {
        onCellEdit(func, vl);
      }
    }
  };

  const checkRefrecord = () => {
    axios
      .get(loca + "/marketplace/get/reference/qualifier/" + tableName.current, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const rq_data = JSON.parse(JSON.stringify(resp.data));
        if (rq_data !== "") {
          setrecord_rq(rq_data);
          let script = uScript.current;
          // onload
          let userDetails = JSON.parse(localStorage.getItem("userDetails"));
          if (script?.length > 0) {
            for (let i = 0; i < script.length; i++) {
              // let field = script[i].field.name;
              let func = script[i].script;
              let type = script[i].type;
              // if (type === "onload" && count.current === 1) {
              if (type === "onload") {
                // count.current = 0;
                onLoad(
                  func,
                  new ApUser(userDetails, setRecord),
                  new ApForm(
                    rcrd.current,
                    setRecord,
                    tabRe.current,
                    setTabRelation
                  )
                );
              } else {
                setTabRel(true);
              }
            }
            setLoading(false);
          } else {
            setLoading(false);
            setTabRel(true);
          }
        }
      });
  };

  const relationVerify = () => {
    // var token = localStorage.getItem("token");
    // this.setState({ relation_loading: true });
    setrelationLoading(true);
    let rvalue = refValue.current;
    var json = {
      relation: [
        {
          tabname: tableName.current,
          recordid: rid.current,
          value: rvalue,
        },
      ],
    };
    setTabRel(false);
    axios
      .post(`${loca}/marketplace/get/relation`, json, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": "marketplace",
        },
      })
      .then(
        (resp) => {
          const rvrecord = resp.data;
          if ("Error" in rvrecord) {
            setrelationLoading(false);
            setLoading(false);
            setPageError(true);
            setError(rvrecord.error);
          } else {
            setrelationLoading(false);
            if (rvrecord.relation.length === 0) {
              setTabRelation(rvrecord);
              tabRe.current = rvrecord;
              // setTabRel(true);
            } else if ("Error" in rvrecord.relation[0]) {
            } else {
              setTabRelation(rvrecord);
              tabRe.current = rvrecord;
            }
            checkRefrecord();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const onChange = (func, val, obj) => {
    let fn = new Function(["ap_user", "ap_form", "val", "obj"], func);
    fn(ap_user, ap_form, val, obj);
  };

  const onCellEdit = (func, val) => {
    let fn = new Function(["ap_user", "ap_form", "val"], func);
    fn(ap_user, ap_form, val);
  };

  const onSubmit = (func) => {
    let fn = new Function(
      [
        "ap_user",
        "ap_form",
        "navigation",
        "user",
        "createSearchParams",
        "record",
      ],
      func
    );
    fn(ap_user, ap_form, navigation, user, createSearchParams, record);
  };

  const onLoad = (func, user, form) => {
    let fn = new Function(
      ["ap_user", "ap_form", "appName", "recordType"],
      func
    );
    fn(user, form, appName, rty.current);
    setTimeout(() => {
      setLoading(false);
      setTabRel(true);
    }, 500);
  };

  const onReference = (func) => {
    let fn = new Function(["ap_user", "ap_form"], func);
    let result = fn(ap_user, ap_form);
    // let result=ap_form.getFieldValue('form_table_id').name
    if (result !== undefined) {
      console.log(result);
      
      setFilt(result);
      setref_filter([result]);
    }
  };

  const callfilter = async (filtarray, index, in_index, col_id) => {
    var rcd = record;
    if (col_id !== -1) {
      if (
        (rcd[index].type === "filter" ||
          rcd[index].type === "filter_script" ||
          rcd[index].type === "condition") &&
        filtarray[in_index].ct === "choice"
        // && filtarray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          if (res.length > 0) {
            filtarray[in_index].ch = res;
            filtarray[in_index].an = res[0].name;
          } else {
            filtarray[in_index].ch = [];
          }
        });
      }
      // }
    }
    rcd[index].value.filter = filtarray;
    setRecord([...rcd]);
    // setFiltArray([...filtarray]);
  };

  const calltimeline = (timeline, index) => {
    var rcd = record;
    deftimeline.current = timeline;
    if (index != undefined && index != null) {
      rcd[index].value.timeline = timeline;
      setRecord([...rcd]);
    }
  };

  const callScript = (script, index) => {
    var rcd = record;
    rcd[index].value.script = script;
    // rcd[index].value = { filter: rcd[index].value.filter,script:script };
    // console.log(rcd[index].value);
    setRecord([...rcd]);
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/marketplace/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let chk = res.choiceRecords;
          return chk;
          // console.log(rcd);
        },
        (error) => {
          console.log(error);
        }
      );
    return ck;
  };

  const goToFormTable = () => {
    checkMenu.current = true;
    setTabRel(false);
    searchParam.set("rty", "record");
    searchParam.set("tableName", "form_table");
    searchParam.set("rid", tabId);
    setSearchParam(searchParam);
    // getRecordForm(m_rid.current, "form_column");
  };

  const goToColumn = () => {
    checkMenu.current = true;
    setTabRel(false);
    searchParam.set("rty", "record");
    searchParam.set("tableName", "form_column");
    searchParam.set("rid", m_rid.current);
    setSearchParam(searchParam);
    // getRecordForm(m_rid.current, "form_column");
  };

  const goToLabel = () => {
    checkMenu.current = true;
    axios
      .get(loca + "/marketplace/get/singlerecord/label/" + m_rid.current, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let lab_id = resp.data;
          if (
            lab_id !== null ||
            lab_id !== "" ||
            lab_id !== "null" ||
            lab_id !== undefined
          ) {
            searchParam.set("rty", "record");
            searchParam.set("tableName", "form_label");
            searchParam.set("rid", lab_id);
            setSearchParam(searchParam);
            // getRecordForm(lab_id, "form_label");
          }
        },
        (error) => {
          toast("Some thing went wrong", { type: "error" });
        }
      );
  };

  const goTouivalid = () => {
    checkMenu.current = true;
    let id = "";
    let type = "";
    for (let i = 0; i < viewList.length; i++) {
      if (viewList[i].selected === "true") {
        id = viewList[i].id;
        type = viewList[i].type;
      }
    }
    axios
      .get(
        loca +
          "/marketplace/get/singlerecord/uivalid/" +
          m_rid.current +
          "/" +
          searchParam.get("rty"),
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(
        (resp) => {
          let lab_id = resp.data;
          if (
            lab_id !== null ||
            lab_id !== "" ||
            lab_id !== "null" ||
            lab_id !== undefined
          ) {
            searchParam.set("rty", "record");
            searchParam.set("tableName", "uivalidation");
            searchParam.set("rid", lab_id);
            setSearchParam(searchParam);
            // getRecordForm(lab_id, "form_label");
          }
        },
        (error) => {
          toast("Some thing went wrong", { type: "error" });
        }
      );
  };

  // const insertAndStay = () => {
  //   insert.current = "insertAndStay";
  //   callbtn("Insert");
  // };
  const recordInsert = () => {
    insert.current = "recordInsert";
    callbtn("Insert");
  };

  const addInsertButton = (btn) => {
    let att = {
      apiType: "post",
      call_type: "api",
      mobUrl: "/marketplace/insert/record",
      name: "Insert",
      nextPage: "naxt page",
      recordReturnType: "list",
      returnMobLocation: "NextActivity",
      returnWebLocation: "NextPage",
      script: "null",
      show_type: "Insert",
      ui_type: "form_button",
      value: "Insert",
      webUrl: "/marketplace/insert/record",
    };
    btn.push?.(att);
  };

  const copy = () => {
    localStorage.setItem("rcdJson", JSON.stringify(copyRcd));
    localStorage.setItem("tableName", tableName.current);
    let rcdJson = localStorage.getItem("rcdJson");
  };

  const paste = () => {
    // vl,index,ob,type,id,vrf,obj,outerIndex,innerIndex,nme,props
    setLoading(true);
    let rcdJson = localStorage.getItem("rcdJson");
    let tab = localStorage.getItem("tableName");
    let tabId = "";
    let rdJson = JSON.parse(rcdJson);
    let obj = "";
    if (tab === tableName.current) {
      var rcd = record;
      for (let i = 0; i < rdJson.length; i++) {
        for (let j = 0; j < rcd.length; j++) {
          if (rcd[j].name === rdJson[i].name) {
            // appending new values
            let vl = rdJson[i].value,
              index = j,
              ob = rcd[j].name,
              type = rcd[j].type,
              id = 0,
              vrf = true,
              obj = rcd[j],
              outerIndex = 0,
              innerIndex = 0,
              nme = "",
              props = null,
              depend_column = null,
              readonly = "false";
            if (rdJson[i].name === "uni_id") {
              vl = "";
            }
            if (type === "reference" || type === "depend_table") {
              vl = rdJson[i].value.name;
              id = rdJson[i].value.id;
              depend_column = rdJson[i].depend_column;
            }
            // calling form change function
            formChangefn(
              vl,
              index,
              ob,
              type,
              id,
              vrf,
              obj,
              outerIndex,
              innerIndex,
              nme,
              props,
              depend_column,
              readonly
            );
          }
        }
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const refresh = () => {
    getScriptList();
    checkMenu.current = true;
    count.current = 1;
    // setRelationload(true);
    setShouldBlock(false);
    setChoice_mn([]);
    setLoading(true);
  };

  const back = () => {
    navigation(-1);
    // setLoading(true);
  };

  const jsonExport = () => {
    axios
      .post(loca + "/json/export", mainRecord.formRecord, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const record = resp.data;
        download(record, mainRecord.formRecord[1].table.name);
      });
  };

  const menuFn = (body) => {
    let fn = new Function(
      [
        "back",
        "refresh",
        "copy",
        "paste",
        "jsonExport",
        "navigation",
        "tableName",
        "goToColumn",
        "goToFormTable",
        "goToLabel",
        "goTouivalid",
        "user",
        "all_ref_cols",
        "tabId",
        // "insertAndStay",
        "recordInsert",
      ],
      body
    );
    fn(
      back,
      refresh,
      copy,
      paste,
      jsonExport,
      navigation,
      tableName.current,
      goToColumn,
      goToFormTable,
      goToLabel,
      goTouivalid,
      user,
      all_ref_cols,
      tabId,
      // insertAndStay,
      recordInsert
    );
  };

  const callColumnByColId = async (id, dep_col) => {
    if (id !== "0" && id !== "none") {
      //    setColumn_depend([]);
      axios
        .get(loca + "/marketplace/get/columnrecords/" + id, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          const coldata = resp.data;
          let rcd = [];
          let rdf = [];

          rdf = record;

          for (let i = 0; i < rdf.length; i++) {
            if (
              rdf[i].depend_column === dep_col &&
              rdf[i].name !== dep_col &&
              rdf[i].type !== "filter" &&
              rdf[i].type !== "condition"
            ) {
              rcd.push(rdf[i]);
            } else if (
              rdf[i].depend_column === dep_col &&
              (rdf[i].type === "filter" || rdf[i].type === "condition")
            ) {
              rcd.push(rdf[i]);
            }
          }
          if (coldata !== "") {
            if ("Error" in coldata) {
              setLoading(false);
              setPageError(true);
              setError(coldata.Error);
            } else {
              let col_array = [];
              col_array.push({ id: "", name: "none", label: "None" });
              for (let c = 0; c < coldata.columnRecords.length; c++) {
                col_array.push(coldata.columnRecords[c]);
              }
              let choicearry = [];
              for (let z = 0; z < coldata.columnRecords.length; z++) {
                choicearry.push({
                  id: coldata.columnRecords[z].id,
                  name: coldata.columnRecords[z].name,
                  label: coldata.columnRecords[z].label,
                  value: coldata.columnRecords[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }

              for (let j = 0; j < rcd.length; j++) {
                let a = rdf.findIndex((e) => e.id === rcd[j].id);

                let frecord = rdf;
                if (
                  frecord[a].type !== "filter" &&
                  frecord[a].type !== "condition"
                ) {
                  frecord[a].dep_choice = choicearry;
                  setRecord(frecord);
                } else {
                  frecord[a].dep_choice = col_array;
                  setRecord(frecord);
                  setCol_mn(col_array);
                }
                setChoice_mn(choicearry);

                setLoading(false);
              }
            }
          }
        });
    }
  };

  const callTableColumn = (val, ot, dep_col, setInDependsCols, rcdd) => {
    if (val !== "" && val !== "none") {
      setbtnNamee(val);
      // setCol_mn([]);
      axios
        .get(loca + "/marketplace/get/column/" + val, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          const coldata = resp.data;
          if (coldata !== "") {
            if ("Error" in coldata) {
              setLoading(false);
              setPageError(true);
              setError(coldata.Error);
            } else {
              let col_array = [];
              col_array.push({ id: "", name: "none", label: "None" });
              for (let c = 0; c < coldata.columnRecords.length; c++) {
                col_array.push(coldata.columnRecords[c]);
              }
              let choicearry = [];
              let rcd = [];
              let rdf = [];
              if (rcdd?.load && rcdd?.record) {
                rdf = rcdd?.record;
              } else {
                rdf = record;
              }
              for (let i = 0; i < rdf.length; i++) {
                if (
                  rdf[i].depend_column === dep_col &&
                  rdf[i].name !== dep_col &&
                  rdf[i].type !== "filter" &&
                  rdf[i].type !== "condition"
                ) {
                  rcd.push(rdf[i]);
                } else if (
                  rdf[i].depend_column === dep_col &&
                  (rdf[i].type === "filter" || rdf[i].type === "condition")
                ) {
                  rcd.push(rdf[i]);
                }
              }
              for (let z = 0; z < coldata.columnRecords.length; z++) {
                choicearry.push({
                  id: coldata.columnRecords[z].id,
                  name: coldata.columnRecords[z].name,
                  label: coldata.columnRecords[z].label,
                  value: coldata.columnRecords[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }

              for (let j = 0; j < rcd.length; j++) {
                let a = rdf.findIndex((e) => e.id === rcd[j].id);
                if (ot === true) {
                  let frecord = rdf;
                  frecord[a].dep_choice = choicearry;
                  setRecord(frecord);
                  setChoice_mn(choicearry);
                } else {
                  let frecord = rdf;
                  if (setInDependsCols) {
                    setColumn_depend(choicearry);
                  }
                  if (
                    frecord[a].type !== "filter" &&
                    frecord[a].type !== "condition"
                  ) {
                    frecord[a].dep_choice = choicearry;
                    setRecord(frecord);
                  } else {
                    frecord[a].dep_choice = col_array;
                    setRecord(frecord);
                    setCol_mn(col_array);
                    
                  }

                  setChoice_mn(choicearry);
                }

                setLoading(false);
              }
            }
          }
        });
    } else {
      setCol_mn([]);
    }
  };

  let blocker = useBlocker(() => {
    return shouldBlock;
  });

  const cleanMapKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(cleanMapKeys); // Recursively process array elements
    } else if (typeof obj === "object" && obj !== null) {
      const cleanedObj = {};
      for (const key in obj) {
        if (key === "map" && typeof obj[key] === "object") {
          // If the key is "map", merge its properties with the parent
          Object.assign(cleanedObj, cleanMapKeys(obj[key]));
        } else if (key === "myArrayList" && Array.isArray(obj[key])) {
          // If the key is "myArrayList", unwrap the array
          return cleanMapKeys(obj[key]);
        } else {
          cleanedObj[key] = cleanMapKeys(obj[key]); // Process nested objects
        }
      }
      return cleanedObj;
    }
    return obj; // Return the value if it's not an array or object
  };

  const download = (res, tablenm) => {
    const element = document.createElement("a");

    const cleanedData = cleanMapKeys(res);
    const jsonString = JSON.stringify(cleanedData, null, 2);

    // Get current datetime and format it (e.g., YYYYMMDD_HHMMSS)
    const now = new Date();
    const formattedDate = now.toISOString().split("T")[0].replace(/-/g, ""); // YYYYMMDD
    const formattedTime = now.toTimeString().split(" ")[0].replace(/:/g, ""); // HHMMSS
    const formattedDateTime = `${formattedDate}_${formattedTime}`; // YYYYMMDD_HHMMSS format

    element.href = `data:application/json;charset=utf-8,${encodeURIComponent(
      jsonString
    )}`;
    element.download = `${tablenm}_${formattedDateTime}.json`;
    element.click();
    element.remove();
  };

  const exportData = (mnrecord, url) => {
    axios
      .post(loca + url, mnrecord, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          let uj_name = "";
          const rcd = mnrecord.formRecord[2].record;
          for (let i = 0; i < rcd.length; i++) {
            if (rcd[i].name === "name") {
              uj_name = rcd[i].value;
            }
          }
          download(record, uj_name);
        },
        (error) => {
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setSubmitLoading(false);
        // setLoading(false);
        setIsClick(false);
      });
  };

  const importData = (mnrecord, url) => {
    let fileName;
    let uuj_id; //upload_updatejso Uni_id
    const rcd = mnrecord.formRecord[2].record;
    for (let i = 0; i < rcd.length && (!fileName || !uuj_id); i++) {
      if (rcd[i].name === "name") {
        fileName = rcd[i].value;
      } else if (rcd[i].name === "uni_id") {
        uuj_id = rcd[i].value;
      }
      // if (fileName && uuj_id) {
      //   break;
      // }
    }
    axios
      .get(loca + url + fileName + "/" + uuj_id, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          if ("Error" in record) {
            setModal(false);
            toast(record.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          } else if ("Message" in record) {
            refresh();
            toast(record.Message, {
              position: "top-center",
              theme: "colored",
              type: "success",
            });
          } else {
            toast("Unexpected Error", {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          }
        },
        (error) => {
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setSubmitLoading(false);
        setModal(false);
        // setLoading(false);
        setIsClick(false);
      });
  };

  const handleClick = (obj) => {
    if (obj.webUrl === "/marketplace/delete/record") {
      setbtnNamee(obj.value);
      setModal(true);
    } else if (obj.webUrl === "/invoice") {
      invoice(obj.webUrl);
    } else if (
      obj.webUrl === "/update/json/import/" ||
      obj.webUrl === "/component/json/import/" ||
      obj.webUrl === "/marketplace/tranform"
    ) {
      setbtnNamee(obj.value);
      setModal(true);
    } else {
      callbtn(obj.value);
    }
  };

  useEffect(() => {
    if (searchParam.get("rty") === "new" && location) {
      ref_cols_insert();
    }
  }, [formChange]);

  useEffect(() => {
    count.current = 1;
    // setRelationload(true);
    if (dash_tabname) {
      // Sub Form Component

      dashBoardForm();
    } else {
      // Parent Form Component

      if (location?.fieldName) {
        recordNew = location.recordNew;
        fieldName = location.fieldName;
      }
      let p = user + "/form";
      if (pathName === p && newAccess.current.length>0) {
        getScriptList();
      }
    }

    document
      .getElementsByTagName("body")
      .item(0)
      .addEventListener("mousedown", () => {
        setShowContextMenu(false);
        setShowContext(false);
      });

    return () => {
      setShowModelInfo(false);
    };
  }, [searchParam,leftNavData]);
  useEffect(() => {
    let h = document
      .getElementById("form" + tableName.current)
      ?.getBoundingClientRect().height;
    if (h > 10) {
      setOverlayH(h);
    }
  }, [loading]);

  useEffect(() => {
    if (submitLoading) {
      const messageTimeouts = [
        setTimeout(() => {
          loadtext.current?.classList.add("show");
          setSubLoadAck("Hang tight, we're processing your request...");
        }, 2000),
        setTimeout(
          () =>
            setSubLoadAck("Working on it... This might take a few moments."),
          7000
        ),
      ];

      // Clear timeouts when component unmounts
      return () => {
        loadtext.current?.classList.add("hide");
        setSubLoadAck("");
        messageTimeouts.forEach((timeout) => clearTimeout(timeout));
      };
    }
  }, [submitLoading]);

  const launchChoose = (id) => {
    let l = document.getElementById(id);
    l.click();
  };
  const handleFileChangeForVideo = (event, objj) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const fileSize = selectedFile.size;
      const maxSizeInKb = Number(objj.uivalid.max_length) / 10;
      if (+(fileSize / 1024).toFixed(2) <= maxSizeInKb) {
        const selectedFile = event.target.files[0];
        let uarr = "images/" + selectedFile.name;
        const imageURL = URL.createObjectURL(selectedFile);
        let tmp = [];
        if (imgObj) {
          tmp = imgObj;
        }
        tmp.push(uarr);
        setImgObj([...tmp]);
        let frecord = record;
        frecord[objj.index].value = imageURL;
        frecord[objj.index].localvid = true;
        setRecord([...frecord]);
        formChangefn(
          imageURL,
          objj.index,
          objj.name,
          objj.type,
          objj.id,
          false
        );
        multipart.set("path", null);
        objj.fdata = event.target.files[0];
        // multipart.append("files", event.target.files[0]);
        setPageError(false);
        setError("");
      } else {
        setError("File size exceeds the limit");
        setPageError(true);
      }
    }
  };

  const handleFileChange = (event, objj) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const fileSize = selectedFile.size;
      const maxSizeInKb = Number(objj.uivalid.max_length);
      if (+(fileSize / 1024).toFixed(2) <= maxSizeInKb) {
        const selectedFile = event.target.files[0];
        let uarr = "images/" + selectedFile.name;
        const imageURL = URL.createObjectURL(selectedFile);
        let tmp = [];
        if (imgObj) {
          tmp = imgObj;
        }
        tmp.push(uarr);
        setImgObj([...tmp]);
        let frecord = record;
        frecord[objj.index].value = imageURL;
        frecord[objj.index].localimg = true;
        setRecord([...frecord]);
        formChangefn(
          imageURL,
          objj.index,
          objj.name,
          objj.type,
          objj.id,
          false
        );
        multipart.set("path", null);
        objj.fdata = event.target.files[0];
        setPageError(false);
        setError("");
      } else {
        setError("File size exceeds the limit");
        setPageError(true);
      }
    }
  };

  const handlePdfFileChange = (event, objj) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const fileSize = selectedFile.size;
      const fileName = selectedFile.name;

      const maxSizeInKb = Number(objj.uivalid.max_length);
      if (+(fileSize / 1024).toFixed(2) <= maxSizeInKb) {
        const selectedFile = event.target.files[0];
        let frecord = record;
        frecord[objj.index].value = fileName;
        setRecord([...frecord]);
        formChangefn(
          fileName,
          objj.index,
          objj.name,
          objj.type,
          objj.id,
          false
        );
        multipart.set("path", null);
        objj.fdata = selectedFile;
        setPageError(false);
        setError("");
      } else {
        setError("File size exceeds the limit");
        setPageError(true);
      }
    }
  };

  const pdfDownload = (json) => {
    navigation("/invoice", { state: { data: json } });
  };

  const uploadImage = (rcd, ind, path, compress) => {
    return new Promise((resolve, reject) => {
      multipart.set("userId", userDetails.id);
      multipart.set("path", path);
      multipart.set("compress", compress ? true : false);
      multipart.append("files", rcd.fdata);
      if (multipart.has("files")) {
        axios
          .post(imageloca + `upload/images`, multipart, {
            headers: {
              Authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then(
            (res) => {
              let resp = res.data;
              if (resp !== "") {
                var imgs = resp;
                multipart.delete("files");
                resolve(imgs);
              } else {
                reject("Directory not found");
              }
            },
            (error) => {
              reject(error);
            }
          );
      }
    });
  };
  const uploadPdf = (rcd, path) => {
    return new Promise((resolve, reject) => {
      multipart.set("path", path);
      multipart.append("file", rcd.fdata);
      if (multipart.has("file")) {
        axios
          .post(imageloca + `upload-pdf`, multipart, {
            headers: {
              "market-application": appname,
            },
          })
          .then(
            (res) => {
              let resp = res.data;
              if (resp !== "error") {
                var imgs = resp;
                multipart.delete("file");
                resolve(imgs);
              } else {
                reject("Directory not found");
              }
            },
            (error) => {
              reject(error);
            }
          );
      }
    });
  };

  const uploadVideo = (rcd, ind, path) => {
    return new Promise((resolve, reject) => {
      multipart.set("userId", userDetails.id);
      multipart.set("path", path);
      multipart.append("files", rcd.fdata);
      if (multipart.has("files")) {
        axios
          .post(imageloca + `upload/videos/`, multipart, {
            headers: {
              Authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then(
            (res) => {
              let resp = res.data;
              var imgs = "";
              if (path) {
                imgs = "images/" + path + "/" + resp[0];
              } else {
                imgs = "images/" + resp[0];
              }
              // rcd.value = imgs;
              // rcdRef.current = imgs;
              multipart.delete("files");
              resolve(imgs);
              // return imgs;
            },
            (error) => {
              reject(error);
            }
          );
      }
    });
  };

  const deleteImage = (objj) => {
    objj.localimg = true;
    formChangefn("", objj.index, objj.name, objj.type, objj.id, false);
    multipart.delete("files");
  };

  useEffect(() => {
    if (objdata) {
      let ar = [];
      ar.push(formData);
      formChangefn(
        ar,
        objdata.index,
        objdata.name,
        objdata.type,
        objdata.id,
        false
      );
    }
  }, [formData, objdata]);

  const initialJsonData = {
    properties: [
      {
        name: "size",
        choice: [{ value: "" }],
      },
      { name: "color", choice: [{ value: "" }] },
    ],
  };

  const [jsonData, setJsonData] = useState(initialJsonData);

  const handleInputChange = (propertyIndex, valueIndex, newValue) => {
    setJsonData((prevData) => ({
      ...prevData,
      properties: prevData.properties.map((prop, index) => {
        if (index === propertyIndex) {
          const updatedChoice = [...prop.choice];
          updatedChoice[valueIndex].value = newValue;
          return { ...prop, choice: updatedChoice };
        }
        return prop;
      }),
    }));
  };

  const handleAddChoice = (propertyIndex) => {
    setJsonData((prevData) => ({
      ...prevData,
      properties: prevData.properties.map((prop, index) => {
        if (index === propertyIndex) {
          return {
            ...prop,
            choice: [...prop.choice, { value: "" }],
          };
        }
        return prop;
      }),
    }));
  };

  const openModal = (value) => {
    setButtonValue(value);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setButtonValue("");
  };

  const handleButtonClick = (value) => {
    if (value === "Delete") {
      openModal(value);
    } else {
      callbtn(value);
    }
  };

  const handleModalYes = () => {
    callbtn(buttonValue);
    closeModal();
  };

  const handleModalNo = () => {
    closeModal();
  };

  try {
    if (!loading) {
      return (
        <div
          id={"form" + tableName.current}
          className="pagesetup"
          style={{ width: "100%" }}
        >
          {submitLoading && (
            <div
              style={{ height: overlayH, width: "75%" }}
              className="loading-overlay d-flex align-items-center justify-content-center"
            >
              <div className="loading-ack">
                <h3 ref={loadtext} className="loading-msg">
                  {subLoadAck}
                </h3>
              </div>
            </div>
          )}
          <Modal show={modal} onHide={() => setModal(!modal)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm {btnNamee}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setModal(!modal)}
                disabled={isClick}
                style={{ backgroundColor: isClick ? "gray" : "" }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => callbtn(btnNamee)}
                disabled={isClick}
                style={{ backgroundColor: isClick ? "gray" : "" }}
              >
                {btnNamee}
              </Button>
            </Modal.Footer>
          </Modal>
          {showContextMenu && labelContext.length > 0 && (
            <ContextMenu
              menuX={menuX}
              menuY={menuY}
              contextMenu={labelContext}
              menuFn={menuFn}
              conditionFn={labelContext[0].cid === cid}
            />
          )}
          {showContext && contextMenu.length > 0 && (
            <ContextMenu
              menuX={menuX}
              menuY={menuY}
              contextMenu={contextMenu}
              menuFn={menuFn}
              excluded_menu={
                rty.current === "new"
                  ? ["open_in_new_tab", "import", "export", "record_insert"]
                  : ["open_in_new_tab", "import", "export"]
              }
              conditionFn={true}
            />
          )}
          <div
            className="btndiv"
            onContextMenu={(e) => {
              e.preventDefault();
              // m_rid.current = obj.id;
              if (isSub.current) {
                let parent = document
                  .getElementById(form_id)
                  ?.getBoundingClientRect();
                setContext(
                  e.button,
                  e.nativeEvent.clientX - parent.left,
                  e.nativeEvent.clientY - parent.top
                );
              } else {
                setContext(e.button, e.nativeEvent.pageX, e.nativeEvent.pageY);
              }
            }}
          >
            {isMobile ? (
              <div className="row fr">
                <div
                  onTouchStart={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      // m_rid.current = obj.id;
                      setContext(2, e.touches[0].pageX, e.touches[0].pageY);
                    }, 100);
                  }}
                  className="col-md tab_head"
                  style={{ alignSelf: "center" }}
                >
                  {tablabel}
                </div>

                <div
                  className="col d-flex "
                  style={{ alignSelf: "center", textAlign: "start" }}
                >
                  <span className="align-self-center">
                    <i
                      disabled={isClick}
                      className=" backic fa fa-arrow-left bck_btt_mrg"
                      onClick={() => {
                        if (!isClick) {
                          navigation(-1);
                        }
                      }}
                    ></i>
                  </span>
                  <Dropdown className="col-sm">
                    <span style={{ width: "30px" }}>
                      <Dropdown.Toggle
                        className="text-white "
                        variant="Dark"
                        id="dropdown-basic"
                      >
                        <i className="fa fa-wpforms"></i>
                      </Dropdown.Toggle>
                    </span>
                    <Dropdown.Menu>
                      {viewList?.length > 0 &&
                        viewList.map((obj, ind) => (
                          <Dropdown.Item
                            onClick={() => {
                              changeView(ind);
                            }}
                            key={ind}
                            className="d-flex justify-content-between fw-medium"
                            href="#"
                          >
                            <span>{obj.name}</span>
                            {obj.selected === "true" && (
                              <span>
                                <i
                                  style={{ color: "green" }}
                                  className="fa fa-check"
                                ></i>
                              </span>
                            )}
                          </Dropdown.Item>
                        ))}
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          if (tableName.current !== "view_rule") {
                            navigation({
                              pathname: user + "/form",
                              search: createSearchParams({
                                tableName: "view_rule",
                                rty: "new",
                              }).toString(),
                            });
                          } else {
                            toast.info("You are already on view_rule page");
                          }
                        }}
                        className="d-flex justify-content-between fw-medium"
                        href="#"
                      >
                        <span>Create New View</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="col" style={{ alignSelf: "center" }}>
                  <div className="btndivin my-1">
                    {button.map((obj, oo_i) => {
                      if (
                        obj.name === "make_current" &&
                        tableName.current === "updatejson" &&
                        selectedJson
                      ) {
                        return;
                      } else {
                        return (
                          <button
                            className=" insrtbtn2 btn btn-primary btn-sm mx-1"
                            key={oo_i}
                            onClick={(e) => handleClick(obj)}
                            disabled={isClick}
                            style={{ backgroundColor: isClick ? "gray" : "" }}
                          >
                            {obj.value}
                          </button>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row fr">
                <div
                  className="col-md-3 d-flex "
                  style={{ alignSelf: "center", textAlign: "start" }}
                >
                  <span className="align-self-center">
                    <i
                      disabled={isClick}
                      className=" backic fa fa-arrow-left bck_btt_mrg"
                      onClick={() => {
                        if (!isClick) {
                          navigation(-1);
                        }
                      }}
                    ></i>
                  </span>
                  <Dropdown className="col-sm">
                    <span style={{ width: "30px" }}>
                      <Dropdown.Toggle
                        className="text-white "
                        variant="Dark"
                        id="dropdown-basic"
                      >
                        <i className="fa fa-wpforms"></i>
                      </Dropdown.Toggle>
                    </span>
                    <Dropdown.Menu>
                      {viewList?.length > 0 &&
                        viewList.map((obj, ind) => (
                          <Dropdown.Item
                            onClick={() => {
                              changeView(ind);
                            }}
                            key={ind}
                            className="d-flex justify-content-between fw-medium"
                            href="#"
                          >
                            <span>{obj.name}</span>
                            {obj.selected === "true" && (
                              <span>
                                <i
                                  style={{ color: "green" }}
                                  className="fa fa-check"
                                ></i>
                              </span>
                            )}
                          </Dropdown.Item>
                        ))}
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          if (tableName.current !== "view_rule") {
                            navigation({
                              pathname: user + "/form",
                              search: createSearchParams({
                                tableName: "view_rule",
                                rty: "new",
                              }).toString(),
                            });
                          } else {
                            toast.info("You are already on view_rule page");
                          }
                        }}
                        className="d-flex justify-content-between fw-medium"
                        href="#"
                      >
                        <span>Create New View</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/* <div className="col-md-3"></div> */}
                <div
                  onTouchStart={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      // m_rid.current = obj.id;
                      setContext(2, e.touches[0].pageX, e.touches[0].pageY);
                    }, 100);
                  }}
                  className="col-md tab_head"
                  style={{ alignSelf: "center" }}
                >
                  {tablabel}
                </div>
                <div className="col-md-3" style={{ alignSelf: "center" }}>
                  <div className="btndivin my-1">
                    {button.map((obj, oo_i) => {
                      if (
                        obj.name === "make_current" &&
                        tableName.current === "updatejson" &&
                        selectedJson
                      ) {
                        return;
                      } else if (obj.ui_type === "form_button") {
                        return (
                          <button
                            className=" insrtbtn2 btn btn-primary btn-sm mx-1"
                            key={oo_i}
                            onClick={(e) => handleClick(obj)}
                            disabled={isClick}
                            style={{ backgroundColor: isClick ? "gray" : "" }}
                          >
                            {obj.value}
                          </button>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className=" maincompo " style={{ height: formHeight }}>
            {page_error === true && (
              <div
                className="alert alert-danger"
                role="alert"
                style={{
                  padding: "0.2rem 0.2rem",
                  marginBottom: "0px",
                }}
              >
                {error}
              </div>
            )}
            {page_message === true && (
              <div
                className="alert alert-success"
                role="alert"
                style={{
                  padding: "0.2rem 0.2rem",
                  marginBottom: "0px",
                }}
              >
                {message}
              </div>
            )}
            {record.length === 0 && <div>Dont have response...</div>}
            {record.length > 0 && (
              <div style={{ textAlign: "start", position: "relative" }}>
                <div>
                  {rcdView.map((obj, index) => (
                    <div key={index}>
                      {obj.split === "false" &&
                        obj.uivalid.visible === "true" && (
                          <span key={index}>
                            <FormInnerCompo
                              userDetails={userDetails}
                              mscList={mscList}
                              setMSC={setMSC}
                              obj={obj}
                              m_rid={m_rid}
                              setContextMenu={setContextMenu}
                              formChangefn={formChangefn}
                              validationfn={validationfn}
                              passwordReadOnly={passwordReadOnly}
                              tableName={tableName.current}
                              selection={selection}
                              setShowSuggestion={setShowSuggestion}
                              getCaretPos={getCaretPos}
                              setY={setY}
                              setX={setX}
                              recordSuggestion={recordSuggestion}
                              userRecordSuggestion={userRecordSuggestion}
                              setSearchrcd={setSearchrcd}
                              searchModule={searchModule}
                              afterCellEdit={afterCellEdit}
                              x={x}
                              y={y}
                              showSuggestion={showSuggestion}
                              searchrcd={searchrcd}
                              ListGroup={ListGroup}
                              listRef={listRef}
                              onSelection={onSelection}
                              clickRefrence={clickRefrence}
                              getSingleInfo={getSingleInfo}
                              refrecord={refrecord}
                              showContainer={showContainer}
                              setRefRcd={setRefRcd}
                              showlist={showlist}
                              col_mn={col_mn}
                              callfilter={callfilter}
                              setFiltArray={setFiltArray}
                              timeline={timeline}
                              calltimeline={calltimeline}
                              callScript={callScript}
                              reScript={reScript}
                              JoditEditor={JoditEditor}
                              editor={editor}
                              choice_mn={choice_mn}
                              handleFileChange={handleFileChange}
                              handleFileChangeForVideo={
                                handleFileChangeForVideo
                              }
                              uploadImage={uploadImage}
                              uploadVideo={uploadVideo}
                              setImgObj={setImgObj}
                              imgObj={imgObj}
                              deleteImage={deleteImage}
                              multipart={multipart}
                              launchChoose={launchChoose}
                              formData={formData}
                              setFormData={setFormData}
                              objdata={objdata}
                              setObjData={setObjData}
                              jsonData={jsonData}
                              initialJsonData={initialJsonData}
                              setJsonData={setJsonData}
                              handleInputChange={handleInputChange}
                              handleAddChoice={handleAddChoice}
                              keyValueJson={keyValueJson}
                              setKeyValueJson={setKeyValueJson}
                              groupkeyValue={groupkeyValue}
                              setGroupKeyValue={setGroupKeyValue}
                              sendProps={sendProps}
                              setSendProps={setSendProps}
                              imageloca={imageloca}
                              verifyError={verifyError}
                              current_Col_Types={current_Col_Types}
                              col_depend={column_depend}
                              dcFiltVal={dcFiltVal}
                              deftimeline={deftimeline}
                              setRecord={setRecord}
                              isClick={isClick}
                              page_error={page_error}
                              handlePdfFileChange={handlePdfFileChange}
                              record={record}
                              isSub={isSub}
                              form_id={form_id}
                            ></FormInnerCompo>
                          </span>
                        )}
                      {obj.split === "true" && (
                        <div className={isMobile ? "" : "row"}>
                          <div className="col">
                            {obj.rcd.map((obj_i, ind_i) => (
                              <div key={ind_i}>
                                {obj_i.formView.position === "left" &&
                                  obj_i.uivalid.visible === "true" && (
                                    <span>
                                      <FormInnerCompo
                                        col_depend={column_depend}
                                        obj={obj_i}
                                        m_rid={m_rid}
                                        setContextMenu={setContextMenu}
                                        formChangefn={formChangefn}
                                        setMSC={setMSC}
                                        validationfn={validationfn}
                                        passwordReadOnly={passwordReadOnly}
                                        tableName={tableName.current}
                                        selection={selection}
                                        setShowSuggestion={setShowSuggestion}
                                        getCaretPos={getCaretPos}
                                        setY={setY}
                                        setX={setX}
                                        recordSuggestion={recordSuggestion}
                                        userRecordSuggestion={
                                          userRecordSuggestion
                                        }
                                        setSearchrcd={setSearchrcd}
                                        searchModule={searchModule}
                                        afterCellEdit={afterCellEdit}
                                        x={x}
                                        y={y}
                                        showSuggestion={showSuggestion}
                                        searchrcd={searchrcd}
                                        ListGroup={ListGroup}
                                        listRef={listRef}
                                        onSelection={onSelection}
                                        clickRefrence={clickRefrence}
                                        getSingleInfo={getSingleInfo}
                                        refrecord={refrecord}
                                        showContainer={showContainer}
                                        setRefRcd={setRefRcd}
                                        showlist={showlist}
                                        col_mn={col_mn}
                                        callfilter={callfilter}
                                        setFiltArray={setFiltArray}
                                        timeline={timeline}
                                        calltimeline={calltimeline}
                                        callScript={callScript}
                                        reScript={reScript}
                                        JoditEditor={JoditEditor}
                                        editor={editor}
                                        choice_mn={choice_mn}
                                        keyValueJson={keyValueJson}
                                        setKeyValueJson={setKeyValueJson}
                                        groupkeyValue={groupkeyValue}
                                        setGroupKeyValue={setGroupKeyValue}
                                        deleteImage={deleteImage}
                                        launchChoose={launchChoose}
                                        handleFileChange={handleFileChange}
                                        handleFileChangeForVideo={
                                          handleFileChangeForVideo
                                        }
                                        verifyError={verifyError}
                                        current_Col_Types={current_Col_Types}
                                        dcFiltVal={dcFiltVal}
                                        deftimeline={deftimeline}
                                        setRecord={setRecord}
                                        isClick={isClick}
                                        page_error={page_error}
                                        handlePdfFileChange={
                                          handlePdfFileChange
                                        }
                                        record={record}
                                        isSub={isSub}
                                        form_id={form_id}
                                      ></FormInnerCompo>
                                    </span>
                                  )}
                              </div>
                            ))}
                          </div>
                          <div className="col">
                            {obj.rcd.map((obj_i, ind_i) => (
                              <div key={ind_i}>
                                {obj_i.formView.position === "right" &&
                                  obj_i.uivalid.visible === "true" && (
                                    <span key={ind_i}>
                                      <FormInnerCompo
                                        obj={obj_i}
                                        m_rid={m_rid}
                                        col_depend={column_depend}
                                        setContextMenu={setContextMenu}
                                        formChangefn={formChangefn}
                                        setMSC={setMSC}
                                        validationfn={validationfn}
                                        passwordReadOnly={passwordReadOnly}
                                        tableName={tableName.current}
                                        selection={selection}
                                        setShowSuggestion={setShowSuggestion}
                                        getCaretPos={getCaretPos}
                                        setY={setY}
                                        setX={setX}
                                        recordSuggestion={recordSuggestion}
                                        userRecordSuggestion={
                                          userRecordSuggestion
                                        }
                                        setSearchrcd={setSearchrcd}
                                        searchModule={searchModule}
                                        afterCellEdit={afterCellEdit}
                                        x={x}
                                        y={y}
                                        showSuggestion={showSuggestion}
                                        searchrcd={searchrcd}
                                        ListGroup={ListGroup}
                                        listRef={listRef}
                                        onSelection={onSelection}
                                        clickRefrence={clickRefrence}
                                        getSingleInfo={getSingleInfo}
                                        refrecord={refrecord}
                                        showContainer={showContainer}
                                        setRefRcd={setRefRcd}
                                        showlist={showlist}
                                        col_mn={col_mn}
                                        callfilter={callfilter}
                                        setFiltArray={setFiltArray}
                                        timeline={timeline}
                                        calltimeline={calltimeline}
                                        callScript={callScript}
                                        reScript={reScript}
                                        JoditEditor={JoditEditor}
                                        editor={editor}
                                        choice_mn={choice_mn}
                                        keyValueJson={keyValueJson}
                                        setKeyValueJson={setKeyValueJson}
                                        groupkeyValue={groupkeyValue}
                                        setGroupKeyValue={setGroupKeyValue}
                                        deleteImage={deleteImage}
                                        launchChoose={launchChoose}
                                        handleFileChange={handleFileChange}
                                        handleFileChangeForVideo={
                                          handleFileChangeForVideo
                                        }
                                        verifyError={verifyError}
                                        current_Col_Types={current_Col_Types}
                                        dcFiltVal={dcFiltVal}
                                        deftimeline={deftimeline}
                                        setRecord={setRecord}
                                        isClick={isClick}
                                        page_error={page_error}
                                        handlePdfFileChange={
                                          handlePdfFileChange
                                        }
                                        record={record}
                                        isSub={isSub}
                                        form_id={form_id}
                                      ></FormInnerCompo>
                                    </span>
                                  )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  {tableName.current === "updatejson" &&
                    rty.current === "record" && (
                      <div className="form-check fmcheck ">
                        <h3 className="text-center underline">
                          Shows Update Json Selected or not
                        </h3>
                        <input
                          type="checkbox"
                          className="checkpadd"
                          checked={selectedJson}
                          disabled={true}
                        ></input>
                        <span className="field_hd">Selected</span>
                      </div>
                    )}
                </div>
                <div className="form-group">
                  {tableName.current === "local_user" && rty === "new" && (
                    <button
                      className=" insrtbtn2 btn btn-primary btn-sm"
                      onClick={(e) => {
                        generatePassword();
                      }}
                      disabled={isClick}
                      style={{ backgroundColor: isClick ? "gray" : "" }}
                    >
                      Generate Password
                    </button>
                  )}

                  {button.map((obj, oo_i) => {
                    if (
                      obj.name === "make_current" &&
                      tableName.current === "updatejson" &&
                      selectedJson
                    ) {
                      return;
                    }
                    if (obj.ui_type === "form_button") {
                      return (
                        <button
                          className=" insrtbtn2 btn btn-primary btn-sm mx-1 "
                          key={oo_i}
                          onClick={(e) => handleClick(obj)}
                          // onClick={(e) => callbtn(obj.value)}

                          disabled={isClick}
                          style={{ backgroundColor: isClick ? "gray" : "" }}
                        >
                          {obj.value}
                        </button>
                      );
                    }
                  })}
                </div>
                {button.map(
                  (obj, oo_i) =>
                    obj.ui_type === "form_link" && (
                      <>
                        <Link
                          // href=""
                          onClick={(e) => handleClick(obj)}
                        >
                          {obj.value}
                        </Link>
                        <br></br>
                      </>
                    )
                )}
                {downRcdView.map((obj, index) => (
                  <div key={index}>
                    {obj.uivalid.visible === "true" && (
                      <div>
                        {activity.map((obj, ind) => (
                          <div key={ind}>
                            <div
                              className="card mt-2"
                              /* style="width: 18rem;" */ style={{
                                width: "60rem",
                                marginLeft: "200px",
                              }}
                            >
                              <div className="card-body ">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex ">
                                    <i
                                      className="fa fa-user-circle-o me-2"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Profile"
                                    ></i>

                                    <b>
                                      <h6>{obj.noteBy}</h6>
                                    </b>
                                  </div>
                                  <div className="d-flex ">
                                    <h6 className="me-4">{obj.columnName}</h6>
                                    <h6>{obj.update}</h6>
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <h6>{obj.content}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}

                <div style={{ position: "absolute", width: "100%" }}>
                  <div>
                    {tabRel === true &&
                      tabRelation !== undefined &&
                      Object.keys(tabRelation).length !== 0 &&
                      rty.current === "record" && (
                        <div>
                          {tabRelation.relation.length > 0 ? (
                            <div className="mt-4">
                              <Tab.Container
                                id="left-tabs-example"
                                defaultActiveKey={
                                  tabRelation.relation[0].formRecordList[1]
                                    .table.label
                                }
                              >
                                <Nav variant="pills" className="flex-column">
                                  <div className="rel_flex rw">
                                    {tabRelation.relation.map(
                                      (nama, tab_ia) => (
                                        <div
                                          md={2}
                                          key={tab_ia}
                                          className="nopad"
                                        >
                                          {isMobile ? (
                                            <Nav.Item
                                              className="cur"
                                              color="black"
                                            >
                                              <Nav.Link
                                                className=""
                                                eventKey={
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              >
                                                {nama.formRecordList[3].page
                                                  .record_count !== "0"
                                                  ? nama.formRecordList[1].table
                                                      .label +
                                                    " (" +
                                                    nama.formRecordList[3].page
                                                      .record_count +
                                                    ")"
                                                  : nama.formRecordList[1].table
                                                      .label}
                                              </Nav.Link>
                                            </Nav.Item>
                                          ) : (
                                            <Nav.Item
                                              className="cur"
                                              color="black"
                                            >
                                              <Nav.Link
                                                eventKey={
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              >
                                                {nama.formRecordList[3].page
                                                  .record_count !== "0"
                                                  ? nama.formRecordList[1].table
                                                      .label +
                                                    " (" +
                                                    nama.formRecordList[3].page
                                                      .record_count +
                                                    ")"
                                                  : nama.formRecordList[1].table
                                                      .label}
                                              </Nav.Link>
                                            </Nav.Item>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Nav>

                                <Tab.Content>
                                  {tabRelation.relation.map((nam, tab_i) => (
                                    <Tab.Pane
                                      key={tab_i}
                                      eventKey={
                                        nam.formRecordList[1].table.label
                                      }
                                    >
                                      <RelationList
                                        all_ref_cols={all_ref_cols}
                                        recordList={nam}
                                        tableName={
                                          nam.formRecordList[1].table.name
                                        }
                                        i_d={i_d.current}
                                        prevTab={tableName.current}
                                        rd={record}
                                      ></RelationList>
                                    </Tab.Pane>
                                  ))}
                                </Tab.Content>
                              </Tab.Container>
                            </div>
                          ) : null}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <Modal show={showModelList} onHide={cancelModelList} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Personalized List Column</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mod-ht">
              <ModelList
                setRef={setRef}
                columnid={columnId}
                onCancel={cancelModelList}
                previousFilter={ref_filter}
                previousTimeLine={""}
                tableName={objLabel}
                objName={objName}
                col_rcds={record}
                setshowModelList={setshowModelList}
                isKV={isKV}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={cancelModelList}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Block navigation modal  */}
          <Modal backdrop show={blocker.state === "blocked"}>
            <Modal.Body className="pb-0">
              <h5 className="text-secondary">
                Your unsaved changes will be lost! Still leave?
              </h5>
            </Modal.Body>
            <Modal.Footer className="p-2 border-top-0">
              <Button
                size="sm"
                variant="danger"
                onClick={() => {
                  setShouldBlock(false);
                  blocker.proceed();
                }}
              >
                Yes
              </Button>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => blocker.reset()}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {labelData && <InvoiceLabel data={labelData} />}
          {showModelInfo === true && (
            <DragModel
              setShowModelInfo={setShowModelInfo}
              rcdView={infoRcdView}
              showModelInfo={showModelInfo}
              closeRef={closeRef}
              rid={rid}
              m_rid={m_rid}
              tableName={tableName}
              infoRcdDetail={infoRcdDetail}
              selection={selection}
              setShowSuggestion={setShowSuggestion}
              getCaretPos={getCaretPos}
              setY={setY}
              setX={setX}
              recordSuggestion={recordSuggestion}
              userRecordSuggestion={userRecordSuggestion}
              setSearchrcd={setSearchrcd}
              searchModule={searchModule}
              afterCellEdit={afterCellEdit}
              x={x}
              y={y}
              showSuggestion={showSuggestion}
              searchrcd={searchrcd}
              ListGroup={ListGroup}
              getSingleInfo={getSingleInfo}
              refrecord={refrecord}
              showContainer={showContainer}
              setRefRcd={setRefRcd}
              showlist={showlist}
              col_mn={col_mn}
              callfilter={callfilter}
              column_depend={column_depend}
              dcFiltVal={dcFiltVal}
              setFiltArray={setFiltArray}
              timeline={timeline}
              calltimeline={calltimeline}
              callScript={callScript}
              reScript={reScript}
              deleteImage={deleteImage}
              handleFileChange={handleFileChange}
              setImgObj={setImgObj}
              imgObj={imgObj}
              launchChoose={launchChoose}
              JoditEditor={JoditEditor}
              editor={editor}
              choice_mn={choice_mn}
              choice_ref_mn={choice_ref_mn}
              verifyError={verifyError}
              multiSv={multiSv}
              add={add}
              deftimeline={deftimeline}
              isMobile={isMobile}
              filtRefArray={filtRefArray}
              setFiltRefArray={setFiltRefArray}
              setGroupKeyValue={setGroupKeyValue}
            ></DragModel>
          )}
        </div>
      );
    } else {
      return <WorkInProgress />;
    }
  } catch (error) {
    let err = { message: error };
    navigation("/error", { state: { err: error } });
  }
};

export default FormComponent;

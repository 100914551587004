import React, { useState, useContext } from "react";
import axios from "axios";
import "../css/Authentication.css";
import { useNavigate } from "react-router-dom";
import { MarketContext } from "../Context.jsx";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import AlertCompo from "../small_compo/AlertCompo.jsx";

const LoginForm = () => {
  const {
    setToken,
    setAppName,
    getUserDetails,
    setAdminVerified,
    loca,
    appname,
  } = useContext(MarketContext);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [pageError, setPageError] = useState(false);
  const [application, setApplication] = useState("");
  const [error, setError] = useState("");
  const [alertType, setAlertType] = useState("");
  const [flag, setFlag] = useState(true);
  const [showpassword, setShowPassword] = useState(
    "fa fa-eye-slash hidepassword"
  );

  const callUpdateCompo = () => {
    navigate("/ForgetPassword");
  };
  const appNameCall = (tkn) => {
    console.log(tkn, appname);

    axios
      .get(loca + "/marketplace/get/appname", {
        headers: {
          Authorization: "Bearer " + tkn,
          "market-application": appname,
        },
      })
      .then((res) => {
        console.log(res.data);
        setAppName(res.data);
        getUserDetails(tkn, true, res.data);
      })
      .catch((error) => {
        // navigate("/error")
        setError("Something went wrong");
        setAlertType("danger");
        setPageError(true);
        setLoading(false);
      });
  };

  const logincall = () => {
    setLoading(true);
    setError("");
    setAlertType("");
    setPageError(false);
    axios
      .post(
        loca + "/authenticate",
        {
          username: username,
          password: password,
          application: application,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "market-application": appname,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.authenticated === true) {
            var token = res.data.token;
            if (res.data.passwordReset) {
              localStorage.setItem("marketAdminToken", token);
              setToken(token);
              callNewPassword(password, username);
              appNameCall(token);
            } else {
              localStorage.setItem("marketAdminToken", token);
              appNameCall(token);
              setToken(token);
            }
          } else {
            setError(res.data.error);
            setAlertType("danger");
            setPageError(true);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        // navigate("/error")
        setError("Please check your username and password ");
        setAlertType("danger");
        setPageError(true);
        setLoading(false);
      });
  };

  const callNewPassword = (op, un) => {
    navigate("/setNewPassword", {
      state: { op: password, un: username, app: application },
    });
  };

  const showRegister = (type) => {
    navigate("/signup", { state: { type: type } });
  };
  const newapp = () => {
    navigate("/NewApplication");
  };

  const hideshowpassword = () => {
    if (!flag) {
      setShowPassword("fa fa-eye-slash hidepassword");
      setFlag(!flag);
    } else {
      setShowPassword("fa fa-eye hidepassword");
      setFlag(!flag);
    }
  };

  return (
    <div className="parent-div">
      <Card className="bg-auth-1 box-shadoww card-width-manual">
        <CardHeader className="border-0">
          <h4 className="text-center mb-0 mt-2">Sign In</h4>
          <hr />
          {pageError && <AlertCompo type={alertType} msg={error} />}
        </CardHeader>

        <CardBody className="pt-0 pb-0 text-center">
          <div className="mt-2">
            <i
              className="fa fa-user"
              style={{
                border: "2.5px solid white",
                height: "4.5rem",
                width: "4.5rem",
                fontSize: "62px",
                textAlign: "center",
                borderRadius: "39px",
              }}
            ></i>
          </div>
          <div className="mt-4">
            <div className="usericpadd input-group form-group pb-2">
              <input
                type="username"
                name="username"
                placeholder="username"
                className=" inpptt "
                onChange={(username) => {
                  setLoading(false);
                  setUsername(username.target.value);
                }}
              ></input>
            </div>
            <div className=" usericpadd input-group form-group pb-2 inp-passwordd">
              <input
                type={flag ? "password" : "text"}
                name="password"
                placeholder="password"
                className=" inpptt "
                maxLength={10}
                onChange={(password) => {
                  setLoading(false);
                  setPassword(password.target.value);
                }}
              ></input>
              <div className="input-group-prepend inp-pass-eye">
                <i className={showpassword} onClick={hideshowpassword}></i>
              </div>
            </div>
            <div className=" usericpadd input-group form-group pb-2">
              <input
                type="text"
                name="app"
                placeholder="application"
                className=" inpptt "
                onChange={(e) => {
                  setLoading(false);
                  setApplication(e.target.value);
                }}
              ></input>
            </div>
          </div>

          <div className="mt-2 mb-3">
            {!loading ? (
              <button className="btn btn-warning btn-width" onClick={logincall}>
                Login
              </button>
            ) : (
              <button className="btn btn-warning" type="button" disabled>
                <span className="spinner-grow spinner-grow-sm me-1"></span>
                Logging In...
              </button>
            )}
          </div>
          <hr className="my-2" />
        </CardBody>

        <CardFooter className="border-0 pt-0 pb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justifyContentCenter">
              <span
                className="underline-none hvr-yellow cursor-pointer"
                onClick={newapp}
              >
                Create new application
              </span>
            </div>
          </div>
          <hr className="my-2" />
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justifyContentCenter">
              <span
                className="underline-none hvr-yellow cursor-pointer"
                onClick={callUpdateCompo}
              >
                Forgot Password?
              </span>
            </div>
          </div>
          <div className="text-start bbr d-flex flex-column">
            <span>Don't have an account?</span>
            <div className="d-flex justify-content-between align-items-start text-sml">
              <span
                className="text-warning underline hvr-yellow cursor-pointer underline"
                onClick={() => showRegister("client")}
              >
                Sign Up for client
              </span>
              <span
                className="text-warning underline hvr-yellow cursor-pointer underline"
                onClick={() => showRegister("user")}
              >
                Sign Up for user
              </span>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default LoginForm;
